import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Page from "../../utils/Page";
import "./Home.scss";
import { BASE_URL } from '../../utils/constants';
import Header from "../../components/Header/Header";
import ThumbLoader from "../../components/ImageLoader/ThumbLoader";
import HomepageBanner from "../../components/HomepageBanner/HomepageBanner";
import ProductsList from "../ProductsList/ProductsList";
import CategoryGrid from "../../components/CategoryGrid/CategoryGrid";
import PopularNowGrid from "../../components/PopularNowGrid/PopularNowGrid";


const Home = (props) => {
  const {data} = props;

  return (
    <Page
      id="home"
    >
      <HomepageBanner data={data} />
      
      <PopularNowGrid data={data} />

      <CategoryGrid data={data} />

      <ProductsList products={data.products} data={data} id="home"  />
    </Page>
  );
};

export default Home;





