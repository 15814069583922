import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../utils/Page";
import { BASE_URL } from '../utils/constants';
import "./NotFound.scss";
import SpinnerRegular from "../components/Spinner/SpinnerRegular";

const Loading = (props) => {

  useEffect(() => {
    window.scroll(0, 0);
  }, [])


  const title = props.title ?? 'Loading ...';
  // const msg = props.msg ?? 'Looks like this page is missing. Check the url and try again.';

  return (
    <Page
      id="loading"
    >
      <div className="not-found loading">
              <span>Loading... </span> <SpinnerRegular />

              {/* <h2>{msg}</h2> */}
          {/* <div className="error-header__faux-btn">
            <a className="fake-btn" href={BASE_URL}>Go to home page</a>
          </div> */}
      </div>
    </Page>
  );
};

export default Loading;





