import React, { Component, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Page from "../utils/Page";
import { SendQueries } from "../api/SendQueries";
import SpinnerRegular from "../components/Spinner/SpinnerRegular";
import { customLog } from "../utils/helpers";

const InitialData = {
  name: "",
  email: "",
  orderNo: "",
  message: "",
  captcha: ""
};
const InitialValidData = { valid: false, msg: '', status: 'ready to submit' }

class HelpForm extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      isValid: InitialValidData,
      data: InitialData
    }
    this.captcha = "";
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  onCaptchaChange = (captcha) => {
    customLog("Captcha value: " + captcha);
    this.captcha = captcha;
  }

  validateInputs() {
    const { email, name, message, orderNo } = this.state.data;

    let isValid = { valid: true, email, name, message, orderNo, status: 'is submitting' };

    if (email === "" || name === "" || message === "") {
      isValid.valid = false;
      isValid.msg = "Fill in the required fields.";
    }

    var emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;

    if ((email != "") && (!(emailfilter.test(email)))) {
      isValid.valid = false;
      isValid.msg = "Invalid email address.";
    }

    if (this.captcha === "") {
      isValid.valid = false;
      isValid.msg = "Validate with catpcha first.";
    }

    if (!isValid.valid) {
      isValid = { ...isValid, status: 'ready to submit' }
    }
    this.setState({ isValid });

    return isValid;
  }


  hidShowErrorMsg = (obj) => {
    const errorDiv = document.getElementById("error");
    if (obj) {
      if (obj.show) {
        errorDiv.style.padding = '8px 5px';
        errorDiv.innerHTML = obj.msg;
      }
    } else {
      errorDiv.style.padding = '0';
      errorDiv.innerHTML = '';
    }
  }



  sendQuery = async(e) => {
    e.preventDefault();
    const { isValid } = this.state;

    const validated = this.validateInputs();
    if (!validated.valid) {
      return;
    }

    const payload = {
      name: validated.name,
      email: validated.email,
      orderNo: validated.orderNo,
      message: validated.message,
      captcha: this.captcha
    };

    try {
      const data = await SendQueries(payload);

      this.setState({ data: InitialData, isValid: { ...isValid, status: 'submitted' } }, () => {
        this.captcha = "";
        setTimeout(() => {
          this.setState({ isValid: InitialValidData });
        }, 2000)
      })

    } catch (err) {
      
      this.setState({ isValid: { ...isValid, status: 'ready to submit', msg: "Something went wrong. Sorry your message cannot be send at this time. Please try again later." } });
      
      customLog(err)
    }
  }


  onInputChange = (e) => {
    const id = e.target.id;
    const val = e.target.value;
    const { data, isValid } = this.state;
    const changedData = id === 'name' ? { ...data, name: val } :
      id === 'email' ? { ...data, email: val } :
        id === 'orderNo' ? { ...data, orderNo: val } : { ...data, message: val };
    this.setState({ data: changedData, isValid: { ...isValid, msg: '' } })
  }

  onInputClick = () => {
    const { isValid } = this.state;
    this.setState({ isValid: { ...isValid, msg: '' } })
  }


  render() {
    const { isValid, data } = this.state;
    const { name, email, orderNo, message } = data;

    return (
      <div className="help-page">
        <div className="rte">
          <h1>Help center</h1>
          <p>If you have a question submit a request using the form below. </p>
          <form>
            <div className="form-group">
              <label><span className="required">*</span>Name</label>
              <input id="name" type="text" value={name} onChange={this.onInputChange} onClick={this.onInputClick} />
            </div>

            <div className="form-group">
              <label><span className="required">*</span>Email</label>
              <input id="email" type="text" value={email} onChange={this.onInputChange} onClick={this.onInputClick} />
            </div>

            <div className="form-group">
              <label>Order Number</label>
              <input id="orderNo" type="text" value={orderNo} onChange={this.onInputChange} onClick={this.onInputClick} />
            </div>

            <div className="form-group">
              <label><span className="required">*</span>Request information</label>
              <textarea id="message" rows="4" cols="50" value={message} onChange={this.onInputChange} onClick={this.onInputClick} />
            </div>

            <div style={{ margin: '10px 0' }}>

              <ReCAPTCHA
                sitekey="6LfouO4iAAAAAGRmt5hD3h63R9rI5QpPjHQ3t4fq"
                onChange={this.onCaptchaChange}
              />
            </div>

            <button className="buy-btn" onClick={this.sendQuery}>
              {isValid.status === 'is submitting' &&
                <><span>Processing... </span> <SpinnerRegular /></>
              }
              {isValid.status === 'ready to submit' &&
                <span>Submit</span>
              }
              {isValid.status === 'submitted' &&
                <span>Inquiry sent</span>
              }
            </button>
          </form>

          <p id="error" style={{ padding: isValid.msg ? '8px 5px' : '0' }}>{isValid.msg}</p>
        </div>
      </div>
    )
  }
}


const Help = (props) => {
  return (
    <Page
      id="help"
    >
      <HelpForm />
    </Page>
  );
};

export default Help;



