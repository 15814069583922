import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams, Redirect, Route } from "react-router-dom";
import Page from "../../utils/Page";
import { slugify } from "../../utils/helpers";
import { BASE_URL } from '../../utils/constants';
import Header from "../../components/Header/Header";
import ThumbLoader from "../../components/ImageLoader/ThumbLoader";
import HomepageBanner from "../../components/HomepageBanner/HomepageBanner";
import ProductsList from "../ProductsList/ProductsList";
// import TopBanner from "../../components/TopBanner/TopBanner";
import NotFound from "../NotFound";


const ProductsInCategory = (props) => {

  const params = useParams();
  const {data} = props;
  const [listItems, setListItems] = useState([]);

  function getCategoryItems() {
    const items = data.products.filter((item) => {
      if (item.categories) {
        const fil = item.categories.filter(sub => sub.id === params.category)
        if (typeof fil !== 'undefined') {
          if (fil.length > 0) {
            return item;
          }
        }
      }
    })
    return items;
  }

  function getTotalForTheCategory() {
    const total = data.categories.filter(d => d.id === params.category)[0].Total || 0
    return total;
  }



  useEffect(() => {
    window.scroll(0, 0); 
  }, [])


  const products = getCategoryItems()

  
  if (!products || products.length === 0) return (<NotFound title="Empty Items" msg="There are'nt any items in this category." />)


  return (
    <Page
      id="categories"
    >
      {/* <TopBanner data={data} /> */}
      <ProductsList products={products} data={data} category={params.category} />
    </Page>
  );
};

export default ProductsInCategory;





