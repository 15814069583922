import React, { Component, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AddUpdateProduct from "./AddUpdateProduct/AddUpdateProduct";
import ProcessOrders from "./ProcessOrders/ProcessOrders";
import SpinnerRegular from "../../components/Spinner/SpinnerRegular";
import { PHP_TO_GS_END_POINT } from "../../utils/constants";
import Page from "../../utils/Page";
import "./Admin.scss";
import { customLog } from "../../utils/helpers";


class Admin extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      action: "product",
      maintenanceMode: false,
      mStatus: '',
      pStatus: 'Publish',
      loaded: false
    }
  }

  componentDidMount = async () => {
    this.getMaintenanceMode();
  }


  getMaintenanceMode = async () => {
    let data = { type: 'siteStatus' };
    const response = await fetch(PHP_TO_GS_END_POINT, {
      method: 'post',
      body: JSON.stringify(data),
      redirect: "follow",
      headers: {
        "Content-Type": "text/plain"
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      //throw new Error(message);
      return 'not found';
    }
    const res = await response.json();
    const dataObj = JSON.parse(res.data);
    document.getElementById("maintenanceCheckbox").style.display = 'block';;
    document.getElementById("maintenanceCheckbox").checked = dataObj.maintenanceMode;
    document.getElementById("loader-spinner").style.display = 'none';

    //this.setState({ loaded: true });
  }



  setMaintenanceMode = async (e) => {
    const { maintenanceMode } = this.state;

    this.setState({ mStatus: 'setting' });

    let data = { type: 'maintenance', maintenanceMode };

    const response = await fetch(PHP_TO_GS_END_POINT, {
      method: 'post',
      body: JSON.stringify(data),
      redirect: "follow",
      headers: {
        "Content-Type": "text/plain"
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      //throw new Error(message);
      return 'not found';
    }
    const res = await response.json();
    this.setState({ mStatus: '' });
  }



  publishSitesAtGoogleSheet = async () => {

    this.setState({ pStatus: 'publishing' })

    let data = { type: 'publishLive' };
    const response = await fetch(PHP_TO_GS_END_POINT, {
      method: 'post',
      body: JSON.stringify(data),
      redirect: "follow",
      headers: {
        "Content-Type": "text/plain"
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      customLog('something went wrong publishing sites.  ' + message);
    }
    const res = await response.json();
    this.setState({ pStatus: 'Publish' })
  }



  render() {

    const { action, maintenanceMode, mStatus, pStatus, loaded } = this.state;

    customLog(action)

    return (
      <Page id="admin" >
        <div className="admin">
          <div className="admin-content">

            <div className="admin-content__item select">
              <div className="select-block">
                <h3>Select an action: </h3>
                <select onChange={(e) => this.setState({ action: e.target.value })}  >
                  <option value="product" >Add / Update</option>
                  <option value="orders" >Process orders</option>
                </select>
              </div>
            </div>

            <div className="admin-content__item maintenance">
              <div className="maintenance-block">
                <label>Maintenance Mode: </label>

                <span id="loader-spinner">
                  <SpinnerRegular />
                </span>

                <input id="maintenanceCheckbox" type='checkbox' onChange={(e) => this.setState({ mStatus: 'Confirm', maintenanceMode: e.target.checked })} />

              </div>

              <div className="submit-d">
                {(mStatus === 'Confirm' || mStatus === 'setting') &&
                  <button className="confirm-mm-btn" onClick={this.setMaintenanceMode}>
                    {mStatus == 'setting' && <span><SpinnerRegular color="white" /></span>}
                    {mStatus == 'Confirm' && <span>Confirm</span>}
                  </button>
                }
              </div>
            </div>

            <div className="admin-content__item publish">
              <div className="publish-block">
                <button className="confirm-p-btn" onClick={this.publishSitesAtGoogleSheet}>
                  {pStatus == 'publishing' && <span>Publishing ... <SpinnerRegular color="white" /></span>}
                  {pStatus == 'Publish' && <span>Publish Live</span>}
                </button>
              </div>
            </div>

          </div>


          {action === 'product' &&
            <AddUpdateProduct />
          }

          {action === 'orders' &&
            <ProcessOrders />
          }
        </div>
      </Page>
    )
  }
}

export default Admin;



