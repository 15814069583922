

// import Utf8 from 'crypto-js/enc-utf8';
// import AES from 'crypto-js/aes';
// const SACHO = 'sacho';

import { upload } from "@testing-library/user-event/dist/upload";

// const decrypted = AES.decrypt(test, SACHO);
// const dd = decrypted.toString(Utf8);
// const res = JSON.parse(dd);
// customLog(res.name)


export function isInView(ele, _offset, isFooter) {
    const eleBB = ele.getBoundingClientRect();
    const offset = _offset ? offset : 0;
    if (isFooter) {
        return eleBB.top <= ((window.innerHeight || document.documentElement.clientHeight))
    } else {
        return eleBB.top >= 0 && eleBB.bottom <= ((window.innerHeight || document.documentElement.clientHeight) - offset)
    }
}


export const isStaging = window.location.href.includes("stage."); // || window.location.href.includes("localhost")

export function getQueryString(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return decodeURI(pair[1]);
        }
    }
    return false;
}


export function addTrailingZeros(num, totalLength) {
    return String(num).padEnd(totalLength, '0');
}



export async function downloadCsv(url) {
    try {
        const target = url; //file
        const res = await fetch(target, {
            method: 'get',
            headers: {
                'content-type': 'text/csv;charset=UTF-8'
            }
        });

        if (res.status === 200) {
            let data = await res.text();
            data = data.trim();
            const json = parseCSV(data)
            return json;

        } else {
            customLog(`Error code ${res.status}`);
        }
    } catch (err) {
        customLog(err)
    }
}


export function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function parseCSV(data) {
    // create empty array
    const csvData = [];

    // this will return each line as an individual String
    const lines = data.split("\n");

    // loop through the lines and return an array of individual   
    // Strings within the line that are separated by a comma

    for (let i = 0; i < lines.length; i++) {
        csvData[i] = lines[i].split(",");
    }

    let newData = [];
    csvData.forEach((d) => {
        const na = d[0].replace("\r", "");
        const obj = { label: na, value: na }
        newData.push(obj)
    })

    // return an array of arrays 2D array
    // e.g [ [1,2,3], [3,4,5], [6,7,8] ]
    return newData;
};



// country currency update


export const updatePriceWithCountryCurrency = async (item) => {
    return new Promise((resolve, reject) => {
        let myInterval = window.setInterval(() => {

            if (window.goondrookConfig.userCountry === "Australia") {
                customLog("--------")
                window.clearInterval(myInterval);
                resolve({ price: item.price, currency: "$" })
            }
            else if (window.goondrookConfig.userCountry && window.goondrookConfig.forex) {
                customLog('userCountry and forex has been loaded')
                window.clearInterval(myInterval);

                let _price = item.price;

                const forex = window.goondrookConfig.forex;

                let userCountry = window.goondrookConfig.userCountry;

                const currency = userCountry === 'Nepal' ? 'NPR' : '$';

                let priceDetail = { price: _price, currency: '$' };

                if (userCountry === 'Nepal') {

                    let nprEx = forex.filter(d => d.currency === 'npr')[0];

                    let ruppessTotal = _price * nprEx.sell;

                    if (item.nprDiscount) {
                        const discount = +item.nprDiscount;
                        ruppessTotal = ruppessTotal - (ruppessTotal * (discount / 100));
                    }
                    priceDetail = { price: ruppessTotal, currency }
                }

                if (userCountry.toLowerCase().includes('america') || userCountry.toLowerCase().includes('states')) {
                    const usDollarTotal = forex.filter(d => d.currency === 'usd')[0]
                    priceDetail = { price: _price * usDollarTotal.sell, currency }
                }

                resolve(priceDetail);

            } else {

                customLog('waiting for userCountry and forex')
            }
        }, 1000)
    })
}


export const customLog = (message) => {
    if (process.env.NODE_ENV === 'development' || window.location.href.includes("stage")) {
        if (typeof message === 'object') {
            // console.trace(JSON.stringify(message));
          } else {
            console.trace(message);
          }
    }
  };
  


export const PriceFormat = (val, addDollarSign) => {
    // console.log(val)
    if(val){
        let pr = formatToTwoDecimals(val)
        return addDollarSign ? "$" + pr : pr;
    }

    // const options = {
    //     style: 'currency',
    //     currency: 'USD',
    //     // These options are needed to round to whole numbers if that's what you want.
    //     //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //     //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    //   }

    // const f = new Intl.NumberFormat('en-US', addDollarSign? options :{});
    // const p = f.format(pr);
    // return p;
}

export const unslugify = (slug) => slug.replace(/\-/g, " ")
    .replace(/\w\S*/g,
        (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    );

export function slugify(string) {
    return string
        .toString()
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^\w\-]+/g, "")
        .replace(/\-\-+/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "");
}


export function estimatedDate(days) {
    const theDate = new Date();
    const est = new Date(theDate.getTime() + +days * 24 * 60 * 60 * 1000);

    const m = est.toLocaleString('default', { month: 'short' });
    let d = est.toLocaleString('default', { day: '2-digit' });

    d = d.charAt(0) === '0' ? d.substring(1) : d;
    return m + " " + d;
}


export function updateTotalItemsCountInHeader(updatedCartItems) {
    let totalItemCount = 0;
    let totalAmount = 0;
    updatedCartItems.forEach((d) => {
        totalItemCount = totalItemCount + d.total;
        totalAmount = totalAmount + (d.price * d.total);
    })
    if (document.getElementById("cart-item-count")) {
        document.getElementById("cart-item-count").innerHTML = totalItemCount;
    }

    return totalAmount;
}


/**
 * 
 */
export const isDev = window.location.hostname.includes('localhost') || window.location.hostname.includes('stage.goondrook') ? true : false;


export function ParseFloat(str, val) {
    str = str.toString();
    str = str.slice(0, (str.indexOf(".")) + val + 1);

    let num = Number(str);

    return (Math.round(num * 100) / 100).toFixed(2);
}


export function formatToTwoDecimals(num) {

     // Convert the number to a string with two decimal places
    let formattedNum = num.toFixed(2);

    // If the formatted number ends with ".00" (no decimal part), remove it
    if (formattedNum.endsWith('.00')) {
        formattedNum = formattedNum.slice(0, -3);
    }

    // If the formatted number is a whole number, add ".00"
    if (formattedNum.indexOf('.') === -1) {
        formattedNum += '.00';
    }
    // return 5.1
    return formattedNum;
}



/*================ ID Generation ================*/


//Generates a random(ish) ID;
export function iDGenerator() {

    const length = 15;
    let timestamp = new Date().getTime();

    var getRandomInt = function (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    //
    timestamp = timestamp.toString();
    var parts = timestamp.split('').reverse();
    var id = '';

    for (var i = 0; i < length; i++) {
        var index = getRandomInt(0, parts.length - 1);
        id += parts[index];
    }

    return id;
    //}

}

/**
 *  takes a timestamp in TZ time format (e.g. "2023-04-08T16:30:00Z") and returns a formatted date string:
 * @param {*} timestamp  in TZ format
 * @returns 
 */

export function formatTZDate(timestamp) {
    const dt = new Date(timestamp);
    const year = dt.getUTCFullYear();
    const month = ('0' + (dt.getUTCMonth() + 1)).slice(-2);
    const day = ('0' + dt.getUTCDate()).slice(-2);
    const hours = ('0' + dt.getUTCHours()).slice(-2);
    const minutes = ('0' + dt.getUTCMinutes()).slice(-2);
    const seconds = ('0' + dt.getUTCSeconds()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}



export function getElapsedTime(startTime) {
    const now = new Date();
    const start = new Date(startTime);
    const elapsed = now.getTime() - start.getTime();
    const elapsedSeconds = Math.floor(elapsed / 1000);
    if (elapsedSeconds < 60) {
        return `${elapsedSeconds} seconds`;
    }
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    if (elapsedMinutes < 60) {
        return `${elapsedMinutes} minutes`;
    }
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    if (elapsedHours < 24) {
        return `${elapsedHours} hours`;
    }

    const elapsedDays = Math.floor(elapsedHours / 24);
    if (elapsedDays < 7) {
        const day = elapsedDays === 1 ? 'day' : 'days';
        return `${elapsedDays} ${day}`;
    }

    const elapsedWeeks = Math.floor(elapsedDays / 7);
    const week = elapsedWeeks === 1 ? 'week' : 'weeks';
    return `${elapsedWeeks} ${week}`;
}

export function getEmailPattern(email) {
    const atIndex = email.indexOf('@');
    const dotIndex = email.indexOf('.');
    const username = email.substring(0, atIndex);
    const domain = email.substring(atIndex + 1, dotIndex);
  
    return `${username.charAt(0)}***@${domain.charAt(0)}***`;
  }

export const validateEmailString = (email) =>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

{/* <div class="checkPassword-lowerletter passwordCheck-valid-customizable">
            <span aria-hidden="true" class="check-lowerletter">✓</span>
            <span class="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
        </div> */}

export function validatePassword(password) {
    // Define regular expressions for each condition
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/;

    let checks=[
        {valid:true, error: 'Password must contain a lower case letter'},
        {valid:true, error: 'Password must contain an upper case letter'},
        {valid:true, error: 'Password must contain a number'},
        {valid:true, error: 'Password must contain at least 8 characters'},
        {valid:true, error: 'Password must contain a special character or a space'},
        {valid:true, error: 'Password must not contain leading or trailing spaces'}
    ]
  
    // Check if the password satisfies each condition
    if (!lowercaseRegex.test(password)) {
        checks[0].valid = false
    }
  
    if (!uppercaseRegex.test(password)) {
        checks[1].valid = false
    }
  
    if (!numberRegex.test(password)) {
        checks[2].valid = false
    }
  
    if (password.length < 8) {
        checks[3].valid = false
    }
  
    if (!specialCharacterRegex.test(password)) {
        checks[4].valid = false
    }
  
    if (password.trim() !== password) {
        checks[5].valid = false
    }

    let valid = true;

    checks.forEach((key)=>{
        if(!key.valid) valid = false;
      });

    // If all conditions are met, return a success message
    return {checks,valid}
  }
  

  export function isImageUrlOrColorCode(input) {
    // Regular expressions for image URLs and color codes
    const imageUrlRegex = /\.(jpeg|jpg|webp)$/i;
    const colorCodeRegex = /^#([0-9a-f]{3}){1,2}$/i;
  
    // Check if input matches either pattern
    if(imageUrlRegex.test(input)) return 'image'
    if(colorCodeRegex.test(input)) return 'color'

    return ''
  }
  

  //check if a name is a color name

  export function isColorName(name) {
    // List of known color names
    const colorNames = [
        "AliceBlue", "AntiqueWhite", "Aqua", "Aquamarine", "Azure", "Beige", "Bisque", "Black", "BlanchedAlmond",
        "Blue", "BlueViolet", "Brown", "BurlyWood", "CadetBlue", "Chartreuse", "Chocolate", "Coral", "CornflowerBlue",
        "Cornsilk", "Crimson", "Cyan", "DarkBlue", "DarkCyan", "DarkGoldenRod", "DarkGray", "DarkGreen", "DarkKhaki",
        "DarkMagenta", "DarkOliveGreen", "DarkOrange", "DarkOrchid", "DarkRed", "DarkSalmon", "DarkSeaGreen", "DarkSlateBlue",
        "DarkSlateGray", "DarkTurquoise", "DarkViolet", "DeepPink", "DeepSkyBlue", "DimGray", "DodgerBlue", "FireBrick",
        "FloralWhite", "ForestGreen", "Fuchsia", "Gainsboro", "GhostWhite", "Gold", "GoldenRod", "Gray", "Green", "GreenYellow",
        "HoneyDew", "HotPink", "IndianRed", "Indigo", "Ivory", "Khaki", "Lavender", "LavenderBlush", "LawnGreen", "LemonChiffon",
        "LightBlue", "LightCoral", "LightCyan", "LightGoldenRodYellow", "LightGray", "LightGreen", "LightPink", "LightSalmon",
        "LightSeaGreen", "LightSkyBlue", "LightSlateGray", "LightSteelBlue", "LightYellow", "Lime", "LimeGreen", "Linen",
        "Magenta", "Maroon", "MediumAquaMarine", "MediumBlue", "MediumOrchid", "MediumPurple", "MediumSeaGreen", "MediumSlateBlue",
        "MediumSpringGreen", "MediumTurquoise", "MediumVioletRed", "MidnightBlue", "MintCream", "MistyRose", "Moccasin",
        "NavajoWhite", "Navy", "OldLace", "Olive", "OliveDrab", "Orange", "OrangeRed", "Orchid", "PaleGoldenRod", "PaleGreen",
        "PaleTurquoise", "PaleVioletRed", "PapayaWhip", "PeachPuff", "Peru", "Pink", "Plum", "PowderBlue", "Purple", "RebeccaPurple",
        "Red", "RosyBrown", "RoyalBlue", "SaddleBrown", "Salmon", "SandyBrown", "SeaGreen", "SeaShell", "Sienna", "Silver", "SkyBlue",
        "SlateBlue", "SlateGray", "Snow", "SpringGreen", "SteelBlue", "Tan", "Teal", "Thistle", "Tomato", "Turquoise", "Violet",
        "Wheat", "White", "WhiteSmoke", "Yellow", "YellowGreen"
    ];

    // Check if the name exists in the array
    return colorNames.includes(name);
}

export function toTitleCase(str) {
    return str.replace(/\b\w/g, function(match) {
        return match.toUpperCase();
    });
}