import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import Page from '../../utils/Page';
// import { CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useAuth } from './AuthContext';
import awsExports from './aws-exports';
import SpinnerRegular from '../Spinner/SpinnerRegular';
import { validatePassword, validateEmailString } from '../../utils/helpers';
import "./Authentication.scss";



const initialState = {
  email: '',
  password: '',
  confirmationCode: '',
  status: 'idle',
  formError: '',
  confirmationCodeSent: false,
  passChecks: undefined
};


const SignUpForm = () => {

  const [state, setState] = useState(initialState);
  const { signup, isLoggedIn, confirmCode } = useAuth();
  const { passChecks, email, password, status, formError, confirmationCodeSent, confirmationCode } = state;


  const handleSignUp = async (e) => {

    e.preventDefault();

    if (!validateEmailString(email)) {
      setState({ ...state, formError: 'Email is not valid' })
      return;
    }
    if (!passChecks || !passChecks.valid) {
      setState({ ...state, formError: 'Invalid password.' })
      return;
    }

    try {

      setState({ ...state, status: 'processing' })

      const response = await signup(email, password);

      setState({...state,confirmationCodeSent:true}); 

    } catch (error) {
      console.error('Error:', error);
      setState({ ...state, status: 'idle', formError: error.message || 'An error occurred while processing your request.' })
    }
  };

  const handleConfirmCode = (confirmationCode) => {
    try {
      setState({ ...state, status: 'processing' })
      const response = confirmCode(email, confirmationCode)

    } catch (error) {
      console.error('Error:', error);
      setState({ ...state, status: 'idle', formError: error.message || 'An error occurred while processing your request.' })
    }
  };


  const onPasswordChange = (e) => {
    const pass = e.target.value;
    const passChecks = validatePassword(pass)
    setState({ ...state, passChecks, password: pass })
  }


  return (
    <Page id="signup">
      <div className="login" >
        <form>

          {!confirmationCodeSent && (
            <div className="form-group gr-row">
              <p>Sign up with a new account</p>
              <div className="form-item">
                <label>Email</label>
                <input type="text" placeholder="Email" value={email} onChange={(e) => setState({ ...state, formError: '', email: e.target.value })} />
              </div>
              <div className="form-item">
                <label>Password</label>
                <input type="password" placeholder="Password" value={password} onChange={onPasswordChange} />
              </div>
            </div>
          )}

          {formError &&
            <div className="form-group gr-row" style={{ paddingTop: '0' }}>
              <div className="form-item">
                <p className="error">{formError}</p>
              </div>
            </div>
          }

          <div className="form-group gr-row" style={{ paddingTop: '0' }}>

            {!confirmationCodeSent && (
              <div className="form-item">
                <button className="login-btn" onClick={handleSignUp}>
                  {status === 'processing' &&
                    <><span>Processing... </span> <SpinnerRegular /></>
                  }
                  {status === 'idle' &&
                    <span>Sign Up</span>
                  }
                </button>

                <div className="sign-btn" >
                  Already have an account <Link to="/login" >Log in</Link>
                </div>

              </div>
            )}

            {confirmationCodeSent && (
              <div className="form-item">
                <p style={{ marginTop: '10px' }} >Confirmation code sent to your email. Please check your inbox.</p>
                <input type="text" placeholder="Confirmation Code" onChange={(e) => setState({ ...state, formError: '', confirmationCode: e.target.value })} style={{ marginBottom: '10px' }} />
              
                <button className="login-btn" onClick={() => handleConfirmCode(confirmationCode)}>
                  {status === 'processing' &&
                    <><span>Processing... </span> <SpinnerRegular /></>
                  }
                  {status === 'idle' &&
                    <span>Confirm Code</span>
                  }
                </button>

              </div>
            )}

          </div>

          {!confirmationCodeSent && 
          <ul className="pass-check">
            {passChecks &&
              passChecks.checks.map((item, i) => {
                return (
                  <li key={i} className={item.valid ? 'valid' : 'invalid'} ><span>{item.valid ? '✓' : '✖'}</span> {item.error}</li>
                )
              })
            }
          </ul>
          }

        </form>
      </div >
    </Page>
  );
};

export default SignUpForm;
