import { iDGenerator } from "./helpers";
import LoaderGifImg  from "../components/ImageLoader/loader.gif";

export const LoaderGif  = LoaderGifImg;

export const IS_DEVELOPING = true;
export const SITE_NAME = "Goondrook.com";
export const SITE_INFO_EMAIL = "info@goondrook.com";
export const LOCAL_URL = "http://localhost:3210"; 
export const PROD_URL = "https://www.goondrook.com";
export const STAGE_URL = "https://stage.goondrook.com";
export const BASE_URL = process.env.NODE_ENV === 'production' ? PROD_URL : LOCAL_URL ;
export const HOST_NAME = process.env.NODE_ENV === 'production' ? window.location.hostname : 'stage.goondrook.com';
export const LOCAL_SERVER = 'http://localhost/70_goondrook/goondrook-server/src';
export const PLACEHOLDER_PRODUCT_IMAGE = `${BASE_URL}/assets/images/placeholder-product-thumb.png`;

export const GOONDROOK_API_END_POINT = "https://api.goondrook.com";
export const DEVELOPERS = ['richardlama@gmail.com'];

// --- Form Inguiry---

// export const inquiry_live = "https://lamainteractives.com/goondrook/src/inquiries/inquiries.php";
// export const inquiry_dev = "http://localhost/70_goondrook/goondrook-server/src/inquiries/inquiries.php";
// export const  INQUIRY_SERVER = process.env.NODE_ENV === 'production' ? inquiry_live : inquiry_dev ;

export const PENDING_ORDERS_API = "https://lamainteractives.com/goondrook/src/products/process-orders.php";
export const PHP_UPLOAD_MEDIA_END_POINT = "https://lamainteractives.com/goondrook/src/media-uploads/upload-media.php";
export const PHP_TO_GS_END_POINT = "https://lamainteractives.com/goondrook/src/products/process-item.php";

export const PHP_OPENAI = "https://lamainteractives.com/goondrook/src/openai/openai.php";


// -- Ali express endpoint ----
export const PHP_ALI_SUBMIT_END_POINT = 'https://lamainteractives.com/goondrook/src/scrape-site/get-data.php';

// -- Amazon endpoint ----
export const PHP_AMAZON_SUBMIT_END_POINT = process.env.NODE_ENV === 'production' ? 'https://lamainteractives.com/goondrook/src/scrape-site/get-data-amazon.php' : LOCAL_SERVER + '/scrape-site/get-data-amazon.php';


export const HOMEPAGE_PRODUCTS_JSON = "/data/products.json?v=" + Math.random();

export const ALL_OTHER_PRODUTCS_JSON = "/data/otherProducts.json?v=" + Math.random();

export const FOREX_JSON = "/data/forex.json";

// Set a backup forex if in case api breaks
export const BACKUP_FOREX_JSON = [
    {
        currency: "npr",
        sell: 89.84
    },
    {
        currency: "usd",
        sell: 0.6953
    }
];

export const DEBUG_USER_COUNTRY = "";

export const ITEM_SCRAPE_ERROR = 'Something went wrong, item url is invalid or item is no longer available!. Try one more time.';

export const InitialData = [ 
    {
        items: [
            {
                id: 'productName',
                label: 'Product Name',
                value: '',
                type: 'text',
                required: true
            },
            {
                id: 'id',
                label: 'Product Id',
                value: iDGenerator(),
                type: 'text',
                required: true,
                width: 400
            }
        ]
    },
    {
        items: [
            {
                id: 'sourceUrl',
                label: 'Product Source Url (ex. Alixpress url )',
                value: '',
                type: 'text',
                required: true
            },
            {
                id: 'slug',
                label: '',
                value: '',
                type: 'hidden',
                required: false
            },
            {
                id: 'auto_altered',
                label: '',
                value: '',
                type: 'hidden',
                required: false
            },
            {
                id: 'not available',
                label: '',
                value: '',
                type: 'hidden',
                required: false
            }
        ]
    },
    {
        items: [
            {
                id: 'stock',
                label: 'Stock total',
                value: '',
                type: 'number',
                required: true
            },
            {
                id: 'actualPrice',
                label: 'Actual Price',
                value: '',
                type: 'number',
                step: 0.1,
                required: true
            },
            {
                id: 'profitMargin',
                label: 'Profit Margin %',
                value: 75,
                type: 'number',
                required: true
            },
            {
                id: 'nprDiscount',
                label: 'Nepali discount %',
                value: '',
                type: 'number',
                step: 0.1,
                required: false
            },
            {
                id: 'price',
                label: 'Selling Price',
                value: '',
                type: 'display',
                required: false
            },
            {
                id: 'rrp',
                label: '',
                value: '',
                type: 'hidden',
                required: false
            }
        ]
    },
    {
        items: [
            {
                id: 'standard cost',
                label: 'Standard Shipping',
                value: '',
                type: 'number',
                required: false,
                width: 120
            },
            {
                id: 'standard estimated days',
                label: 'Standard Shipping Estimate Days',
                value: '',
                type: 'number',
                required: false,
                width: 200
            }
        ]
    },
    {
        items: [
            {
                id: 'express cost',
                label: 'Express Shipping',
                value: '',
                type: 'number',
                required: false,
                width: 120
            },
            {
                id: 'express estimated days',
                label: 'Express Shipping Estimate days',
                value: '',
                type: 'number',
                required: false,
                width: 200
            }
        ]
    },
    {
        items: [
            {
                id: 'category1',
                label: 'Category 1',
                value: '',
                type: 'category',
                required: true,
            },
            {
                id: 'category2',
                label: 'Category 2',
                value: '',
                type: 'category',
                required: false,
            },
            {
                id: 'category3',
                label: 'Category 3',
                value: '',
                type: 'category',
                required: false,
            },
            {
                id: 'category4',
                label: 'Category 4',
                value: '',
                type: 'category',
                required: false,
            }
        ]
    },
    {
        items: [
            {
                id: 'tags',
                label: 'Tag/s (Use comma to add upto 4 tags)',
                value: '',
                type: 'text',
                required: true,
            }
        ]
    },
    {
        items: [
            {
                id: 'variants',
                label: 'Variants (Max 10, only supports one variant for now.)',
                value: '',
                type: 'variant',
                required: false,
                variants: [
                ]
            }
        ]
    },
    {
        items: [
            {
                id: 'images',
                label: 'Product Image Urls',
                value: '',
                type: 'images',
                required: true,
                images: [],
                customNames: []
            }
        ]
    },
    {
        items: [
            {
                id: 'video',
                label: 'Product Video Url',
                value: '',
                type: 'text',
                required: false,

            }
        ]
    },
    {
        items: [
            {
                id: 'inStagingOnly',
                label: '',
                value: false,
                type: 'hidden',
                required: false
            },
            {
                id: 'homepageList',
                label: 'List in homepage',
                value: false,
                type: 'checkbox',
                required: false
            },
            {
                id: 'topBanner',
                label: 'Include in top banner',
                value: false,
                type: 'checkbox',
                required: false
            },
            {
                id: 'popular now',
                label: 'Include in popular now banner',
                value: false,
                type: 'checkbox',
                required: false
            },
            {
                id: 'onlyForNepal',
                label: 'Only for Nepali',
                value: false,
                type: 'checkbox',
                required: false
            }
        ]
    },
    {
        items: [
            {
                id: 'description',
                label: 'Plain short description',
                value: '',
                type: 'textarea',
                required: true
            }
        ]
    },
    {
        items: [
            {
                id: 'descriptionHtmlSort',
                label: 'Html short description',
                value: '',
                type: 'textarea',
                required: false
            }
        ]
    },
    {
        items: [
            {
                id: 'descriptionHtmlLong',
                label: 'Html long description',
                value: '',
                type: 'textarea',
                required: false
            }
        ]

    },
    {
        items: [
            {
                id: 'seoTitle',
                label: 'SEO Title',
                value: '',
                type: 'text',
                required: true
            }
        ]
    },
    {
        items: [
            {
                id: 'seoDescription',
                label: 'SEO Description',
                value: '',
                type: 'text',
                required: true
            }
        ]
    }

]
