import { useEffect, Component } from "react";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
// import { AMOUNT, STYLE, NegativeTestingData } from './Constants';
import { isDev, customLog } from "../../utils/helpers";
import LoaderGif from "../ImageLoader/loader.gif"
import "./PayWithPaypal.scss";


// Shorten item name to be 120 or less characters. Paypal has a max char length for item names 
function shortenItemName(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'object') {
            shortenItemName(obj[key]);
        } else if (key === 'name' && typeof obj[key] === 'string') {
            obj[key] = obj[key].substring(0, 120);
        }
    }
}



const PayWithPaypal = ({ paypalPurchaseUnits, data, currency, showSpinner, onPaymentApproved, onPaymentFailed }) => {

    // usePayPalScriptReducer can be used only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    const CURRENCY = data.config.currency;

    paypalPurchaseUnits.forEach(item => {
        shortenItemName(item);
    });

    // useEffect(() => {
    //     dispatch({
    //         type: "resetOptions",
    //         value: {
    //             ...options,
    //             currency: CURRENCY,
    //         },
    //     });
    // }, [currency, showSpinner]);

    return (
        <div style={{ width: "100%", minHeight: "200px" }}>
            {(showSpinner && isPending) && <div className="spinner" />}

            <PayPalButtons
                // style={STYLE}
                disabled={false}
                fundingSource={undefined} // options could be {['paypal', 'card']}
                onInit={(data, actions) => {
                    actions.disable();
                }}

                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: paypalPurchaseUnits
                        })
                        .then((orderId) => {
                            return orderId;
                        });
                }}

                // onCancel={(data, actions) => {
                //     customLog('---- on cancel -----');
                //     actions.disable();
                // }}

                onApprove={function (data, actions) {

                    customLog("---- on paypal approve ----");

                    return actions.order.capture().then(function (res) {

                        //throw new Error('force the onApprove callback to fail');

                        if (res.status === 'COMPLETED' || res.status === 'APPROVED') {
                            onPaymentApproved(res)
                        } else {

                            // if (res.error === 'INSTRUMENT_DECLINED'){
                            //    // return actions.restart();
                            // }

                            onPaymentFailed(res)
                            actions.disable();

                            // opens paypal window with the error message
                            //return actions.restart();
                        }
                        actions.disable();
                    });
                }}
                onError={function (err) {
                    onPaymentFailed(err)
                }}
            />


            <div className="load-wrapper-pp">
                <img alt="loader" src={LoaderGif} className="loader-img" />
            </div>

        </div>
    );

}

export default PayWithPaypal;

