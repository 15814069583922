import React, { Component, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Select, { components } from "react-select";
import { Link, useParams} from "react-router-dom";
import { BASE_URL } from "../../utils/constants";
import "./Header.scss";

class HeaderPlain extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {

    }
  }


  render() {

    return (

      <section className="section-header">
        <div className="header" style={{backgroundColor:"000"}} >

          <Link to="/" >
            <img className="logo" src={`${BASE_URL}/assets/images/logo.png`} />
          </Link>

        </div>

      </section> 

    )
  }
}

export default withRouter(HeaderPlain);
