import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Loading from '../../pages/Loading';

const AuthenticatedRoute = ({ component: Component, path, ...rest }) => {
  const { isLoggedIn, userGroup, isAuthLoading } = useAuth();
  const [loading, setLoading] = useState(true);

  if (isAuthLoading) {
    // If authentication context is still loading, do not render anything
    return <Loading />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          path === '/account' || path === '/checkout' || userGroup === 'Admin' ? (
            <Component {...props} />
          ) : (
            <Redirect to={props.location.state?.from || '/'} />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location.pathname }
            }}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
