import React, { createContext, useContext, useEffect, useState } from 'react';

import Utf8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';
import { BASE_URL, HOMEPAGE_PRODUCTS_JSON, DEBUG_USER_COUNTRY, ALL_OTHER_PRODUTCS_JSON, FOREX_JSON, BACKUP_FOREX_JSON } from './constants';
import { customLog, getQueryString } from './helpers';
import { GetCountry } from '../api/GetCountry';
import { DispatchErrors } from '../api/DispatchErrors';

const SACHO = 'sacho';
const initialDisplayed = 19;

export const SiteDataContext = createContext();
// Refresh Rate is how often you want to refresh the page 
// bassed off the user inactivity. 
var refresh_rate = 10; //<-- In seconds, change to your needs
var last_user_action = 0;
var has_focus = false;
var lost_focus_count = 0;
// If the user loses focus on the browser to many times 
// we want to refresh anyway even if they are typing. 
// This is so we don't get the browser locked into 
// a state where the refresh never happens.    
var focus_margin = 10;

// Reset the Timer on users last action
function reset() {
  last_user_action = 0;
  //customLog("Reset");
}

function windowHasFocus() {
  has_focus = true;
}

function windowLostFocus() {
  has_focus = false;
  lost_focus_count++;
  // customLog(lost_focus_count + " <~ Lost Focus");
}



export const SiteDataProvider = ({ children }) => {

  // const [memberDetails, setMemberDetail] = useState(null);
  const [goondrookData, setGoondrookData] = useState(undefined);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [otherProductsReady, setOtherProductsReady] = useState(false);
  const [totalItemsDisplaying, setTotalItemsDisplaying] = useState(initialDisplayed);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedVariant, setSelectedVariant] = useState(undefined);
  const [allCartItems, setAllCartItems] = useState(undefined);


  useEffect(() => {

    const PrepareInitialData = async () => {
      try {

        let wData = window.goondrookConfig;

        let isInMaintenanceMode = false;
        if(wData.config['Maintenance Mode']){
          isInMaintenanceMode = true;
          if(window.location.href.includes("/admin")){
            isInMaintenanceMode = false;
          }
          if(window.location.hostname.trim() !== 'www.goondrook.com'){
            isInMaintenanceMode = false;
          }
        }

        const configs = {
          currency: 'AUD',
          currencySign: 'AU$',
          userCountry: 'Australia',
          maintenanceMode: isInMaintenanceMode,
          total: wData.config['total'],
          numberOfItemsInALot: wData.config['Number of items in a lot'],
          totalDisplayed: initialDisplayed,
          otherProductsReady: false,
        }

        wData.siteInfo = wData.siteInfo[0]
        wData.config = configs;

        const decrypted = AES.decrypt(wData.products, SACHO);
        const dd = decrypted.toString(Utf8);
        const prods = JSON.parse(dd);
        wData.products = prods;

        const cartItemsFromLocalStorage = JSON.parse(window.localStorage.getItem('cartitems')) || [];
        wData.allCartItems = cartItemsFromLocalStorage;

        const newData = await GetUsersCountryAndCheckForex(wData)
        // console.log(newData)
        validateLocalStorageItems(newData);
        updateData(newData);
        setAllCartItems(cartItemsFromLocalStorage)
        setIsInitialDataLoaded(true)

      } catch (error) {
        throw error
      }
    }

    PrepareInitialData()

    const handleOnResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleOnResize);

  }, []);


  useEffect(() => {
    const executeAdditionalActions = async () => {
      const otherData = await LoadOtherDataInBg(false, goondrookData);
      updateData(otherData);
      setOtherProductsReady(true)
    }
    if (isInitialDataLoaded) {
      executeAdditionalActions()
    }
  }, [isInitialDataLoaded]);



  const LoadOtherDataInBg = async (reloading, dt) => {
    let parentData = !dt ? goondrookData : dt;
    const url = ALL_OTHER_PRODUTCS_JSON;

    return new Promise(resolve => setTimeout(() => {

      fetch(url).then(response => {
        return response.json();
      }).then(data => {

        const decrypted = AES.decrypt(data.products, SACHO);
        const dd = decrypted.toString(Utf8);
        const prods = JSON.parse(dd);

        if (reloading) {
          //customLog('reloading')
          const newProducts = alterItemsPriceBasedOnUsersCountry(prods);

          // remove nepal only items if the country is not Nepal
          if (parentData.config.userCountry.toLowerCase() !== 'nepal') {
            const productsForNepalOnly = newProducts.filter((d) => !d.onlyForNepal);
            parentData.products = productsForNepalOnly;
          } else {
            parentData.products = newProducts;
          }

        } else {
          // remove existing item in homepage from the new products loaded
          const homepageProdIds = parentData.products.map((io) => io.id);
          var allItemsExceptHomepageItems = prods.filter(e => homepageProdIds.indexOf(e.id) === -1);
          let newProducts = alterItemsPriceBasedOnUsersCountry(allItemsExceptHomepageItems);

          // remove nepal only items if the country is not Nepal
          if (parentData.config.userCountry.toLowerCase() !== 'nepal') {
            newProducts = newProducts.filter((d) => !d.onlyForNepal);
          }
          // spread allItemsExceptHomepageItems into the parentData.products
          parentData.products = [...parentData.products, ...newProducts];

          // customLog(parentData.products)
          // re validate ls items
          validateLocalStorageItems(parentData);

          ReloadDataInBackground();
        }

        parentData.config.otherProductsReady = true;
        return (resolve(parentData));

      }).catch(err => {
        // Do something for an error here
        customLog(err)
      });

    }, 3000));
  }


  const ReloadDataInBackground = () => {
    setTimeout(() => {
      LoadOtherDataInBg(true);
    }, 60000); //every 5 min
  }


  /**
 * -- update local storage cart items with the latest items details ----
 */
  const validateLocalStorageItems = (data) => {
    let lsItems = JSON.parse(window.localStorage.getItem('cartitems')) || [];

    if (data.products) {
      const products = data.products;
      lsItems.forEach(lsItem => {
        products.forEach(product => {
          if (lsItem.id === product.id) {
            lsItem = product;
          }
        });
      });
      window.localStorage.setItem('cartitems', JSON.stringify(lsItems));
    }
  }


  const GetUsersCountryAndCheckForex = async (dt) => {
    let parentData = dt;
    let userCountryViaQueryString = DEBUG_USER_COUNTRY || "";
    if (getQueryString('country')) {
      userCountryViaQueryString = getQueryString('country')
    }

    try {
      const usersCountry = await GetCountry();
      parentData.config.currency = 'AUD';
      parentData.config.currencySign = 'AU$ ';

      const userCountry = userCountryViaQueryString || usersCountry;
      parentData.config.userCountry = userCountry;

      // remove nepal only items if the country is not Nepal

      if (userCountry.toLowerCase() !== 'nepal') {
        const currentProducts = parentData.products
        const productsForNepalOnly = currentProducts.filter((d) => !d.onlyForNepal);
        parentData.products = productsForNepalOnly;
        // console.log(window.goondrookConfig.products)
      }


      if (parentData.config.userCountry.toLowerCase() !== 'australia') {

        const dataWithForex = await LoadForexData(parentData);

        const newProducts = await alterItemsPriceBasedOnUsersCountry(dataWithForex.products, dt);

        const productsForNepaliOnly = dt.products.filter((d) => d.onlyForNepal);

        parentData.products = newProducts;

        parentData.config.priceReady = true;
      } else {
        parentData.config.priceReady = true;
      }

      return parentData;

    } catch (error) {

      throw new Error("@ GetUsersCountryAndCheckForex function, file: Datacontext.js, line 260. " + error);

    }
  }


  const alterItemsPriceBasedOnUsersCountry = (products, dt) => {
    const forex = goondrookData ? goondrookData.forex : dt.forex;
      products.forEach((item) => {
        const newItem = alterSingleItemPriceBasedOnUsersCountry(item, forex, dt);
        item = newItem;
      })
    return products;
  }


  const alterSingleItemPriceBasedOnUsersCountry = (item, _forex, dt) => {

    let parentData = goondrookData ? goondrookData : dt;
    let forex = _forex ? _forex : goondrookData.config.forex;

    if (typeof item === 'string') return;
    let _price = item.price;

    let userCountry = parentData.config.userCountry.toLowerCase();

    const currency = userCountry === 'nepal' ? 'NPR' : '$';
    item.foreignCurrency = currency;
    item.foreignAmount = null;

    parentData.config.currency = userCountry === 'australia' ? 'AUD' : 'USD';
    parentData.config.currencySign = userCountry !== 'australia' ? 'US$' : 'AU$';

    if (userCountry !== "australia") {
      let usDollarTotal = forex.filter(d => d.currency === 'usd')[0]
      usDollarTotal.sell = +usDollarTotal.sell;
      item.price = _price * usDollarTotal.sell.toFixed(2);
      item.rrp = item.rrp * usDollarTotal.sell.toFixed(2);
    }

    if (userCountry === 'nepal') {

      let nprEx = forex.filter(d => d.currency === 'npr')[0];
      let usDollarTotalB = forex.filter(d => d.currency === 'usd')[0]

      // customLog("usDollarTotalB.sell:  ", _price * usDollarTotalB.sell)
      let ruppessTotal = (_price * usDollarTotalB.sell) * nprEx.sell;  //+usDollarTotalB.sell;

      // --- Apply discount ---
      if (item.nprDiscount) {
        const discount = +item.nprDiscount;
        ruppessTotal = ruppessTotal - (ruppessTotal * (discount / 100));

        // add Discount on the USD price

        usDollarTotalB.sell = +usDollarTotalB.sell;
        const usdPrice = _price * usDollarTotalB.sell;
        item.price = (usdPrice - (usdPrice * (discount / 100))).toFixed(2);
      }
      item.foreignAmount = ruppessTotal.toFixed(2);
    }

    return item;
  }


  /** Load foreign exchange data. If api fails we will use BACKUP_FOREX_JSON. */
  const LoadForexData = async (dt) => {

    let parentData = dt;
    const url = FOREX_JSON;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        const message = `@ LoadForexData function, invalid response from server, status: ${response.status}`;
        DispatchErrors(JSON.stringify({msg:message}, 'forex error'))
        parentData.forex = BACKUP_FOREX_JSON;
        return parentData
        // customLog(message);
        // throw new Error(message);
      }

      const data = await response.json();
      parentData.forex = data;
      return parentData;

    } catch (error) {
      customLog(error);
      const message = "@ LoadForexData function, file: Datacontext, line 130. " + error;
      DispatchErrors(JSON.stringify({msg:message}, 'forex error'))
      parentData.forex = BACKUP_FOREX_JSON;
      return parentData
      //throw new Error("@ LoadForexData function, file: DataProcessing.js, line 130. " + error);
    }
  }

  const setAllCartItemsFromLs = () => {
    const cartItemsFromLocalStorage = JSON.parse(window.localStorage.getItem('cartitems')) || [];
    let wData = goondrookData
    wData.allCartItems = cartItemsFromLocalStorage;
    updateData(wData)
    setAllCartItems(cartItemsFromLocalStorage);
    return wData.allCartItems
  }


  const setTotalDisplayed = () => {
    let wData = goondrookData
    wData.config.totalDisplayed = wData.config.totalDisplayed + initialDisplayed;
    updateData(wData)
    setTotalItemsDisplaying(wData.config.totalDisplayed)
  }

  // Function to update data
  const updateData = (v) => {
    setGoondrookData(v);
  };

  const updateSelectedVariant = (v) => {
    setSelectedVariant(v);
  };

  const updateAllCartItems = (newCartItems) => {
    // Update cartItems in local storage
    window.localStorage.setItem('cartitems', JSON.stringify(newCartItems));
    setAllCartItems(newCartItems);
  };



  return (
    <SiteDataContext.Provider value={{ goondrookData, selectedVariant, allCartItems, otherProductsReady, screenWidth, isInitialDataLoaded, setAllCartItemsFromLs, totalDisplayed: totalItemsDisplaying, setTotalDisplayed, updateData, updateSelectedVariant, setSelectedVariant, updateAllCartItems, alterSingleItemPriceBasedOnUsersCountry }}>
      {!isInitialDataLoaded ? <div>Loading...</div> : children}
    </SiteDataContext.Provider>
  );

};



export const useGoondrookSiteData = () => {
  return useContext(SiteDataContext);
};

