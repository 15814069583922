import React, { Component, useEffect, useState } from 'react';
import Papa from 'papaparse';
import { withRouter, Link, Redirect } from "react-router-dom";
import Page from '../../../utils/Page';
import { LoaderGif } from '../../../utils/constants';
import "./ViewOrders.scss";

const csvUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQEzL8sh5ZtF6jeCbhVl8UFu9PP7EGx5Cjb_tGgvS8Xn0TEB7v2DVJBNp6U8UC0cBAB9eR2y7pA4ubr/pub?gid=1436466858&single=true&output=csv";

/*
Stage:
"https://docs.google.com/spreadsheets/d/e/2PACX-1vRdOXC0k_0vjhaXMPogTxxv-2LLJn8CR59qyu8EB913JKI0Xtx-uRE-mza816kQoZnJVINu5BI66DSX/pub?gid=1436466858&single=true&output=csv"
*/

const orderDetailsApi = "https://script.google.com/macros/s/AKfycbz3nX1jYWc-VF9ARIQ6xIhyGcNldk1kRgj0QROTpzWqqELvkOmBJZFa62-OIiyxiYM/exec";//  "https://script.google.com/macros/s/AKfycbwxpwVbN_iqj2RPMXVptlDhVp90nNoXtJ2dSukjPTs/dev" ;// dev mode

const ViewOrders = (props) => {

  const [data, setData] = useState(undefined);

  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await fetch(csvUrl, {
          method: 'get',
          headers: {
            'content-type': 'text/csv;charset=UTF-8'
          }
        });

        if (res.status === 200) {
          let data = await res.text();
          const parsedData = Papa.parse(data, {
            header: true // Treat the first row as headers
          });
          setData(parsedData.data);

        } else {
          console.log(res.status);
        }
      } catch (err) {
        console.log(err);
      }
    }

    const getOrderDetail = async () => {
      const reqData = { orderId: "asdfasdfasdf" }
      try {

        // Make a preflight request with OPTIONS method to check CORS headers
        fetch(orderDetailsApi, {
          method: 'OPTIONS',
          headers: {
            'Access-Control-Request-Method': 'POST',
            'Access-Control-Request-Headers': 'content-type'
          }
        })
          .then(response => {
            // If preflight request succeeds, make the actual POST request
            if (response.ok) {
              return fetch(orderDetailsApi, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqData)
              });
            } else {
              throw new Error('Preflight request failed');
            }
          })
          .then(response => response.json())
          .then(data => {
            // Handle response data
            console.log(data)
          })
          .catch(error => {
            console.log("xxxxxxxxxxxx")
            console.log(error)
          });
      } catch (err) {
        console.log(err);
      }
    }



    loadData()
    // getOrderDetail()
    window.scroll(0, 0)

  }, []);


  return (
    <Page
      id="view-orders"
    >

      {!data &&
        <div className="loader">
          <img src={LoaderGif} />
        </div>
      }
      {data &&
        <div className="view-orders">

          <div>
            {/* Table for the first section */}
            <h3>Pending</h3>
            <table className="first-section">
              <thead>
                <tr>
                  {['Pending Order Id', 'Order Date', 'Order Hours Elapsed'].map(header => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    {['Pending Order Id', 'Order Date', 'Order Hours Elapsed'].map((header, index) => {

                      let className = '';
                      if (header === 'Order Hours Elapsed') {
                        const value = row[header];
                        const [hours, minutes] = value.split(':').map(Number);
                        const totalHours = hours + (minutes / 60);
                        if (totalHours > 5) {
                          className = 'blinking-cell-green';
                        } else if (value.includes('day')) {
                          className = 'blinking-cell-red';
                        }
                      }
                                            
                      return <td key={index} className={className}  >{row[header]}</td>
                    })}
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Table for the second section */}
            <h3>Dispatched</h3>
            <table className="second-section">
              <thead>
                <tr>
                  {['Dispatched Order Id', 'Dispatched Date', 'Days Elapsed'].map(header => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    {['Dispatched Order Id', 'Dispatched Date', 'Days Elapsed'].map((header, index) => (
                      <td key={index}>{row[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>




          {/* <table>
            <thead>
              <tr>
                {Object.keys(data[0]).map(header => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  {Object.entries(row).map(([key, value], index) => {
                    let className = '';
                    let label = key; // Default label is the key
                    if (key === 'Order Hours Elapsed') {
                      const [hours, minutes] = value.split(':').map(Number);
                      const totalHours = hours + (minutes / 60);
                      if (totalHours > 5) {
                        className = 'blinking-cell-green';
                      } else if (value.includes('day')) {
                        className = 'blinking-cell-red';
                      }
                    }
                    // Customize labels as needed
                    if (key === 'Order Hours Elapsed') {
                      label = 'Order Hours Elapsed';
                    }
                    // Add data-label attribute with the label
                    return <td key={index} className={`${className} ${key === 'Dispatched Order Id' || key === 'Dispatched Date' || key === 'Days Elapsed' ? 'yellow-bg' : ''}`} data-label={label}>{value}</td>;
                  })}
                </tr>
              ))}
            </tbody>
          </table> */}


        </div>
      }


      {/* <div className="view-orders">
        <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRdOXC0k_0vjhaXMPogTxxv-2LLJn8CR59qyu8EB913JKI0Xtx-uRE-mza816kQoZnJVINu5BI66DSX/pubhtml?gid=1436466858&single=true" />
    </div> */}
    </Page>
  );
}


export default withRouter(ViewOrders);
