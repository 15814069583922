import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Page from "../utils/Page";
import { BASE_URL, SITE_NAME } from '../utils/constants';
import Header from "../components/Header/Header";


const MaintenanceMode = (props) => {

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  let isInMaintenanceMode = props.data.config.maintenanceMode;

  if(!isInMaintenanceMode) return (<Redirect to="/" />)

  return (
    <>
      <Header data={props.data} />
      <div className="product-page" style={{ minHeight: '60vh' }}>
        <div className="rte">
          <h1 style={{ textAlign: 'center' }}>Site Under Maintenance</h1>
          <p style={{ textAlign: 'center' }}>Goondrook is currently undergoing scheduled maintenance. We apologize for any inconvenience and appreciate your patience. Please check back later.</p>
        </div>
      </div>
    </>
  );
};

export default MaintenanceMode;
