import { HOST_NAME, GOONDROOK_API_END_POINT } from "../utils/constants";


/**
 * The GetItemDetails function fetches details of a specific product item using its productId.
 * @param productId - The `productId` parameter is used to specify the unique identifier of the product
 * for which you want to retrieve details. In the provided code snippet, the `GetItemDetails` function
 * takes this `productId` as an argument and fetches the details of the corresponding product from a
 * specified URL.
 * @returns The function `GetItemDetails` is returning the item details of a product with the specified
 * `productId`. If the response from the API call is successful (status code 200), it returns the item
 * details in JSON format. If there is an error in the response, it returns the string 'not found'.
 */

export const GetItemDetails = async (productId) => {

    try {
        const data = { hostName: HOST_NAME, productId: productId, type: "get-details" };   // params = {productId: '2in1-remote-control-transforming-car'}

        const response = await fetch(GOONDROOK_API_END_POINT + "/products", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        }
        const res = await response.json();
        return res.item;

    } catch (err) {
        throw new Error('Failed to fetch name. ' + err.message);
    }
}

