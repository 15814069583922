import { GOONDROOK_API_END_POINT } from "../utils/constants";


export const SendQueries = async (_payload) => {
  const payload = {..._payload, type:"inquiries"}
  try {
    const response = await fetch(GOONDROOK_API_END_POINT + "/products", {
      method: 'post',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    const res = await response.json();
    return res;

  } catch (err) {

    throw new Error('Failed to fetch name. '+ err.message);
  }
}

