import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import RenderHTML from "../utils/RenderHtml";
import Page from "../utils/Page";
import { BASE_URL, SITE_NAME } from '../utils/constants';


const AboutUs = (props) => {

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  const copyWithDomainReplaced = props.data.replaceAll('{SITE_NAME}', SITE_NAME)
  return (
    <Page
      id="about"
    >
      <div className="product-page">
        <div className="rte">
          <h1>About Us</h1>
          <h3>Welcome to {SITE_NAME} online shopping site!</h3>

          <RenderHTML HTML={copyWithDomainReplaced} />

        </div>
      </div>
    </Page>
  );
};

export default AboutUs;





