import React, { useState } from 'react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useAuth } from './AuthContext';
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import awsExports from './aws-exports';
import SpinnerRegular from '../Spinner/SpinnerRegular';
import { customLog, getEmailPattern, validateEmailString, validatePassword } from '../../utils/helpers';
import "./Authentication.scss";
import Page from '../../utils/Page';


let initialState = {
  email: '',
  password: '',
  newPassword: '',
  newPasswordRepeated: '',
  verificationCode: '',
  status: 'idle',
  formError: '',
  requestSubmitted: false,
  resetSuccess: false,
  passChecks: undefined

};


const ResetPassword = () => {

  const { logout, userEmail } = useAuth();
  initialState.email = userEmail ? userEmail : '';

  console.log(userEmail)

  const [state, setState] = useState(initialState);
  const location = useLocation();
  const head = location.state && location.state.head ? location.state.head : 'Forgot your password?';
  const history = useHistory();

  const userPool = new CognitoUserPool({
    UserPoolId: awsExports.aws_user_pools_id,
    ClientId: awsExports.aws_user_pools_web_client_id
  });

  const { passChecks, email, password, newPassword, newPasswordRepeated, verificationCode, status, formError, requestSubmitted, resetSuccess } = state;

  const resetFields = () => {
    setState(initialState)
  }

  const handleForgotPassword = () => {

    if (!validateEmailString(email)) {
      setState({ ...state, formError: 'Email is not valid' });
      return;
    }

    try {
      setState({ ...state, status: 'processing' })

      const userData = {
        Username: email,
        Pool: userPool
      };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: () => {
          // Handle success, e.g., show confirmation message to user
          customLog('Forgot password request was successful');
          setState({ ...state, status: 'idle', requestSubmitted: true })
        },
        onFailure: (error) => {
          // Handle failure, e.g., display error message to user
          console.error('Error requesting forgot password:', error);
          setState({ ...state, status: 'idle', formError: error.message || 'An error occurred while processing your request.' })
        }
      });
    } catch (error) {
      console.error('Error:', error);
      setState({ ...state, formError: error.message || 'An error occurred while processing your request.' })
    }
  };




  const handleResetPassword = (e) => {

    if (!passChecks.valid) {
      setState({ ...state, formError: "Invalid password." })
      return;
    }

    if (newPassword !== newPasswordRepeated) {
      setState({ ...state, formError: "Password did not match." })
      return;
    }

    const userData = {
      Username: email,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);

    setState({ ...state, status: 'processing' })

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        // Handle success, e.g., show confirmation message to user
        customLog('Password reset was successful');

        setState({ ...state, resetSuccess: true })
        // setrequestSubmitted(false);
        logout()
        history.push('/login')

        // Redirect the user to the sign-in page or any other page as needed
      },
      onFailure: (error) => {
        // Handle failure, e.g., display error message to user
        console.error('Error confirming password reset:', error);
        if (error.message === 'Invalid code provided, please request a code again.') {
          setState({ ...state, status: 'idle', requestSubmitted: false, formError: error.message || 'An error occurred while processing your request.' })
        } else {
          setState({ ...state, formError: error.message || 'An error occurred while processing your request.' })
        }
      }
    });
  };


  const onPasswordChange = (e) => {
    const pass = e.target.value;
    const passChecks = validatePassword(pass)
    setState({ ...state, formError: '', newPassword: pass, passChecks })
  }


  return (

    <Page id="resetpassword">
      <div className="login" >
        <form>
          <div className="form-group gr-row">

            {resetSuccess &&
              <>
                <h2>Password reset successfully</h2>
                <p>You have been signed out. Click the login button at the top to sign in again with your new password.</p>
              </>
            }

            {!requestSubmitted && !resetSuccess &&
              <>
                <h2>{head}</h2>
                <p>Enter or edit your Email below and we will send a message to reset your password.</p>
              </>
            }
            {requestSubmitted && !resetSuccess &&
              <p>We have sent a password reset verification code by email to {getEmailPattern(email)}. Enter it below to reset your password.</p>
            }

            <div className="form-item">

              {!requestSubmitted && !resetSuccess &&
                <>
                  <label>Email</label>
                  <input type="text" placeholder="Email" value={email} onChange={(e) => setState({ ...state, formError: '', email: e.target.value })} />
                </>
              }


              {requestSubmitted && !resetSuccess &&
                <>
                  <div className="form-item">
                    <label>Code</label>
                    <input type="text" placeholder="" value={verificationCode} onChange={(e) => setState({ ...state, formError: '', verificationCode: e.target.value })} />
                  </div>
                  <div className="form-item">
                    <label>New Password</label>
                    <input type="password" placeholder="" value={newPassword} onChange={onPasswordChange} />
                  </div>
                  <div className="form-item">
                    <label>Enter New Password Again</label>
                    <input type="password" placeholder="" value={newPasswordRepeated} onChange={(e) => setState({ ...state, formError: '', newPasswordRepeated: e.target.value })} />
                  </div>
                </>
              }

            </div>
          </div>

          {formError &&
            <div className="form-group gr-row" style={{ paddingTop: '0' }}>
              <div className="form-item">
                <p className="error">{formError}</p>
              </div>
            </div>
          }

          {!resetSuccess &&
            <div className="form-group gr-row" style={{ paddingTop: '0' }}>
              <div className="form-item">
                <button className="login-btn" onClick={(e) => {
                  e.preventDefault();
                  requestSubmitted ? handleResetPassword() : handleForgotPassword();
                }}>
                  {status === 'processing' &&
                    <><span>Processing... </span> <SpinnerRegular /></>
                  }
                  {status === 'idle' &&
                    <span>{requestSubmitted ? 'Change Password' : 'Reset Password'}</span>
                  }
                </button>
              </div>
            </div>
          }


          {requestSubmitted &&
            <ul className="pass-check">
              {passChecks &&
                passChecks.checks.map((item, i) => {
                  return (
                    <li key={i} className={item.valid ? 'valid' : 'invalid'} ><span>{item.valid ? '✓' : '✖'}</span> {item.error}</li>
                  )
                })
              }
            </ul>
          }

        </form>
      </div >
    </Page>
  )
};

export default ResetPassword;
