import React from "react";
import { withRouter, Redirect } from "react-router";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { BASE_URL, SITE_NAME } from "../utils/constants";
import Header from "../components/Header/Header";
import { useGoondrookSiteData } from "./dataContext";

const SITE_URL =
    process.env.NODE_ENV === "development"
        ? "http://localhost:3210"
        : BASE_URL;

const defaultTitle = `${SITE_NAME}: shop trending Gifts, Electronics, Apparel, Beauty & more`;
const defaultDescription = `${SITE_NAME}:  shop trending Gifts, Electronics, Computers, Clothing, Shoes, Toys, Books, DVDs, Sporting Goods, Beauty & more.`;
const defaultImage = '/logo-big.jpg';
const defaultSep = " | ";

const StyledDiv = styled.div`
&::before{
    content: '';    
    display:none;}
`;



const getPageStyle = (bgGradientColor, id) => {

    if (id === 'products') {
        return (styled.div`
        &::before{
            content: '';    
            position:absolute;
            top: 60px;
            width: 100%;
            height: 600px;
            display: block;
            background: rgb(3, 3, 3);
            background: -moz-linear-gradient(180deg, rgba(3, 3, 3, 1) 0%, ${bgGradientColor} 0%, rgba(255, 255, 255, 1) 100%);
            background: -webkit-linear-gradient(180deg, rgba(3, 3, 3, 1) 0%, ${bgGradientColor} 0%, rgba(255, 255, 255, 1) 100%);
            background: linear-gradient(180deg, rgba(3, 3, 3, 1) 0%, ${bgGradientColor} 0%, rgba(255, 255, 255, 1) 100%); }
        `)
    } else {
        return StyledDiv;
    }
}


const getMetaTags = ({ title, description, image, contentType, noCrawl }, pathname) => {
    const metaTitle = title
        ? (title + defaultSep + defaultTitle).substring(0, 60)
        : defaultTitle;
    const metaDescription = description
        ? description.substring(0, 155)
        : defaultDescription;
    const metaImage = image ? `${SITE_URL}${image}` : defaultImage;

    const metaTags = [
        { itemprop: "name", content: metaTitle },
        { itemprop: "description", content: metaDescription },
        { itemprop: "image", content: metaImage },
        { name: "description", content: metaDescription },
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: metaTitle },
        { name: "twitter:description", content: metaDescription },
        { name: "twitter:image:src", content: metaImage },
        { property: "og:title", content: metaTitle },
        { property: "og:type", content: contentType || "website" },
        { property: "og:url", content: SITE_URL + pathname },
        { property: "og:image", content: metaImage },
        { property: "og:description", content: metaDescription },
        { property: "og:site_name", content: defaultTitle }
    ];

    // if (noCrawl) {
    //     metaTags.push({ name: "robots", content: "noindex, nofollow" });
    //     metaTags.push({ name: "googlebot", content: "noindex" });
    // }

    return metaTags;
}


const HelmetData = (props) => {

    const { pathname, id, children, data } = props;

    const params = useParams();

    const itemWithSlug = data.products.filter(item => item.slug === params.productId)[0];

    const seoTitle = itemWithSlug ? SITE_NAME + ": " + data.products.filter(item => item.slug === params.productId)[0].seo.title : defaultTitle;
    const seoDescription = itemWithSlug ? SITE_NAME + ": " + data.products.filter(item => item.slug === params.productId)[0].seo.description : defaultDescription;

    let item = {
        description: defaultDescription,
        seo: {
            title: seoTitle,
            description: seoDescription
        }
    }

    if (id !== 'products') {
        const subItem = data.products.filter(item => item.id === params.productId)[0];
        if (subItem) {
            item = subItem;
        }
    }

    var rest = {
        title: seoTitle,
        description: seoDescription,
        image: defaultImage,//item.images ? "/assets/images/products/" + item.images.big[0] : defaultImage,
        contentType: "",
        noCrawl: true
    }

    const bgGradientColor = data.config["Homepage Background Gradient Color"] || "#daf6f4";
    const PageStyled = getPageStyle(bgGradientColor, id);


    return (
        <PageStyled id={id} >

            <Helmet
                htmlAttributes={{
                    lang: "en",
                    itemscope: undefined,
                    itemtype: `http://schema.org/${rest.schema || "WebPage"}`
                }}
                title={
                    seoTitle
                }
                link={[
                    {
                        rel: "canonical",
                        href: SITE_URL + pathname
                    }
                ]}

                meta={getMetaTags(rest, pathname)}
            />

            {children}

        </PageStyled>
    )
}


const Page = (props) => {
    const { goondrookData } = useGoondrookSiteData();
    const { children, id, location } = props;
    const adminPath = location.pathname === "/admin";

    if (goondrookData.config.maintenanceMode) {
        return (<Redirect to="/maintenance" />)
    }

    return (
        <>
            <Header data={goondrookData} />
            <HelmetData id={id} children={children} pathname={props.location.pathname} data={goondrookData} />
        </>

    )
}

export default withRouter(Page);


