import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Page from "../../utils/Page";
import { BASE_URL } from '../../utils/constants';
import Header from "../../components/Header/Header";
import ThumbLoader from "../../components/ImageLoader/ThumbLoader";
import HomepageBanner from "../../components/HomepageBanner/HomepageBanner";
import ProductsList from "../ProductsList/ProductsList";
import { customLog } from "../../utils/helpers";

const Products = (props) => {
  const{data} = props;
  customLog(data)
  return (
    <Page
      id="products"
    >

      <HomepageBanner data={data}/>

      <ProductsList data={data.products} />

    </Page>
  );
};

export default Products;





