import React, { useEffect } from "react";
import RenderHTML from "../utils/RenderHtml";
import { Link } from "react-router-dom";
import Page from "../utils/Page";
import { BASE_URL, SITE_NAME, SITE_INFO_EMAIL } from '../utils/constants';


const Privacy = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, [])
  const copyWithDomainReplaced = props.data.replaceAll('{SITE_NAME}', SITE_NAME)
  return (
    <Page
      id="privacy"
    >
      <div className="product-page">
        <RenderHTML HTML={copyWithDomainReplaced} />
      </div>
    </Page>
  );
};

export default Privacy;





