import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./ButtonWithLoader.scss";
import SpinnerRegular from '../Spinner/SpinnerRegular';


/**
 * -- PROPS ---
 * 
 * onBtnClick = function
 * label = string
 * addConfrimBtn = boolean
 * clearErrorMsg = function
 */


class ButtonWithLoader extends Component {

    constructor(props: Props) {
        super(props);
        this.state = {
            status: "ready",
            message: "",
            label: this.props.label,
            showConfirm: false,
            isConfirmed: false
        }
    }


    onBtnClick = async (e) => {

        if (this.state.status === 'processing') return;

        const { isConfirmed } = this.state;
        const { addConfirmBtn, clearErrorMsg } = this.props;

        if(clearErrorMsg){
            clearErrorMsg();
        }

        if (!addConfirmBtn) {
            this.setState({ status: 'processing' })
            const ret = await this.props.onBtnClick(e);
            this.setState({ status: "ready" })
        }

        if (addConfirmBtn && isConfirmed) {
            this.setState({ status: 'processing' })

            const ret = await this.props.onBtnClick(e);

            this.setState({ status: "ready", showConfirm: false, isConfirmed: false })
        }

        if (addConfirmBtn && !isConfirmed) {
            this.setState({ showConfirm: true })
        }

    }


    onConfirmedClicked = (e) => {
        this.setState({ showConfirm: false, isConfirmed: true }, () => {
            this.onBtnClick(e);
        })
    }



    render() {
        const { status, message, showConfirm } = this.state;
        const { uiText, label, customStyle } = this.props;
        return (
            <div className="btns-with-loader" style={customStyle}>
                {!showConfirm &&
                    <button className="bwl-btn" onClick={this.onBtnClick}>
                        {status === 'processing' && <span><SpinnerRegular color="white" size="16px" /></span>}
                        {status === 'ready' && <span>{label}</span>}
                    </button>
                }

                {showConfirm &&
                    <div className="confirm-btns">
                        <button className="submit-btn" onClick={(e) => {
                            this.setState({ showConfirm: false })
                        }}>
                            Cancel
                        </button>

                        <button className="confirm-btn" onClick={this.onConfirmedClicked} >Confirm</button>
                    </div>
                }

                <p>{message}</p>

            </div>
        )
    }
}

export default ButtonWithLoader;
