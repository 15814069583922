import React, {useEffect, useState } from 'react';
import { useGoondrookSiteData } from '../../utils/dataContext';
import { PriceFormat } from '../../utils/helpers';
import SpinnerRegular from '../../components/Spinner/SpinnerRegular';
import "./PriceRenderer.scss";


const PriceRenderer=(props)=>{
  const { goondrookData } = useGoondrookSiteData();

  const [priceReady, setPriceReady] = useState(goondrookData.config.priceReady || false);

  let priceInterval;

    useEffect(()=>{
      if (!goondrookData.config.priceReady) {
        priceInterval = setInterval(() => {
          checkForPriceReadyVariable()
        }, 1000)
      }
    },[goondrookData])

  const checkForPriceReadyVariable = () => {
    if (goondrookData.config.priceReady) {
      clearInterval(priceInterval)
      setPriceReady(true)
    }
  }

    const { item, type, randy } = props;

    if (!priceReady) return (
      <SpinnerRegular />
    )

    const isForeignCurrencyNpr = item.foreignCurrency ? item.foreignCurrency.toLowerCase() === 'npr' : false;


    let attrStyle = { display: type === "detail" ? 'inline-block' : 'inline-block', marginLeft: type === "detail" ? '10px' : '0' }

    if (type === "detail") {
      attrStyle = { display: 'inline-block' }
    }
    if (window.innerWidth > 550 && type === "list") {
      attrStyle = { display: 'inline-block' }
    }

    const topPriceDisplay = isForeignCurrencyNpr ? `${goondrookData.config.currencySign} ${PriceFormat(item.price)}` : `${item.foreignCurrency} " " ${PriceFormat(item.foreignAmount)}`;


    return (
      <>
        <div className={randy ? 'price' : 'g-price'}>

          {item.foreignAmount &&
            <>

              {type === "detail" &&
                <span className="foreign-price" style={{ background: isForeignCurrencyNpr ? '#b6b6b6' : '#d02323' }}>
                  {topPriceDisplay}
                </span>
              }

              <>
                <sup className="sup">Rs</sup>
                <span className="prc"> {PriceFormat(item.foreignAmount)}</span>
              </>

              {item.onlyForNepal &&
                <div>
                  <span className="off" style={{ margin: '0 0 0 0' }}>{((item.price / item.rrp) * 100).toFixed(2)}% off</span>
                </div>
              }

              {item.foreignAmount && isForeignCurrencyNpr && type === "detail" &&
                <span className="transaction-note"> Note: US Dollar is used for transaction  </span>
              }


              {item.onlyForNepal &&
                <div>
                  <span style={{ margin: '0 10px 0 0', fontSize: '12px', color: '#9a9a9a' }}>(Item only for Nepali)</span>
                </div>
              }

            </>
          }


          {!item.onlyForNepal &&
            <span style={{ display: 'block', padding: '5px 0 0 0' }}>

              {!isForeignCurrencyNpr &&
                <>
                  <sup className="sup">{goondrookData.config.currencySign}</sup>
                  <span className="prc"> {PriceFormat(item.price)}</span>
                </>
              }

              {item.rrp > 0 &&
                <>
                  <span className="attrs" style={attrStyle} >
                    {
                      !isForeignCurrencyNpr &&
                      <span className="rrp">{goondrookData.config.currencySign}{PriceFormat(item.rrp)}  </span>

                    }
                    <span className="off">{((item.price / item.rrp) * 100).toFixed(2)}% {`${isForeignCurrencyNpr ? 'off' : 'off'}`}</span>

                    {item.nprDiscount && isForeignCurrencyNpr && type === "detail" &&
                      <span className="foreign-discount"> +{item.nprDiscount}% Discount</span>
                    }

                  </span>
                </>
              }
            </span>
          }
        </div>

      </>
    )

}

export default PriceRenderer;