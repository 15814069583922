import React, {useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import "./PopularNowGrid.scss";
import { BASE_URL, PLACEHOLDER_PRODUCT_IMAGE } from '../../utils/constants';
import { PriceFormat, customLog } from '../../utils/helpers';
import { useGoondrookSiteData } from '../../utils/dataContext';
import ImageWithPlaceHolder from '../ImageWithPlaceHolder/ImageWithPlaceHolder';

const ANIMATION_SPEED = 4000;
let sliding = false;

const PopularNowGrid = (props) => {

    const { popularNow, products, title, config } = props.data;
    
    const {screenWidth, goondrookData} = useGoondrookSiteData();

    useEffect(() => {
        if(document.querySelector('#pop-grid a')){
            slideGrid("init");
        }
    }, [])


    const slideGrid = (id) => {
        const gridHolderWidth = document.getElementById("pop-holder").clientWidth;
        const gridContainer = document.getElementById("pop-grid");

        const totalGridWidth = gridContainer.clientWidth;

        const itemDiv = document.querySelector('#pop-grid a');

        const itemDivWidth = itemDiv.clientWidth + 20;

        const gridContLeft = +(gridContainer.style.left.replace("px", ""));

        const gridVisible = totalGridWidth + gridContLeft;

        if (id === "init") {
            gridContainer.style.left = '0px';
        }

        if (!sliding) {

            sliding = true;

            if (id === "next") {
                if (gridVisible > gridHolderWidth) {
                    const diff = gridVisible - gridHolderWidth;
                    const pos = diff < itemDivWidth ? gridContLeft - diff - 20 : gridContLeft - itemDivWidth;
                    gridContainer.style.left = pos + 'px';
                }
            };
            if (id === "previous") {
                if (gridContLeft < 0) {
                    const pos = -gridContLeft < itemDivWidth ? -gridContLeft - (20 * 2) : gridContLeft + itemDivWidth;
                    gridContainer.style.left = pos + 'px';
                }
            };

            setTimeout(() => {
                sliding = false;
            }, 400);
        }
    }


    let data = popularNow.map(item => {
        return goondrookData.products.filter(d => d.id === item)[0]
    })

    data.sort(() => Math.random() - 0.5);

    const pTitle = props.title || "Popular now";

    // customLog("currencySign is " + config.currencySign)

    return (
        <div className="popular-grid">
            <h1 className="title">{pTitle}</h1>

            <div className="previous-btn" onClick={() => slideGrid('previous')}>
                <svg width="24" height="24" aria-hidden="true" focusable="false">
                    <use xlinkHref="#icon-side-arrow" />
                </svg>
            </div>

            <div className="holder" id="pop-holder">
                <div className="grid-container" id="pop-grid">

                    {data &&
                        data.map((item, i) => {

                            if (!item) return null;

                            let img = `${BASE_URL}/assets/images/products/${item.images.small[0]}`;
            
                            const gridCol = item.Width === "100" ? "1/5" : item.Width === "75" ? "1/4" : item.Width === "50" ? "1/3" : "inherit";
                            const gridStyle = { gridColumn: screenWidth < 600 ? 'inherit' : gridCol }

                            return (

                                <a key={i} href="#" onClick={(e) => { e.preventDefault(); window.scroll(0, 0); props.history.push(`/products/${item.id}/${item.slug}`) }} className="grid_item grid_item-1" style={gridStyle}>
                                    <ImageWithPlaceHolder className="grid_image" src={img} alt={item.name} placeholderSrc={PLACEHOLDER_PRODUCT_IMAGE}  />
                                    {/* <img className="grid_image" src={img} alt={item.name} /> */}
                                    <p>{item.name}</p>
                                    <p className="price"><sup className="sup">{config.currencySign}</sup>{PriceFormat(item.price)}</p>
                                </a>
                            )
                        })
                    }
                </div>
            </div>

            <div className="next-btn" onClick={() => slideGrid('next')}>
                <svg width="24" height="24" aria-hidden="true" focusable="false">
                    <use xlinkHref="#icon-side-arrow" />
                </svg>
            </div>

        </div>
    )
}

export default withRouter(PopularNowGrid);

