import React, {useState, useEffect } from 'react';
import { Link, useParams, withRouter } from "react-router-dom";
import { BASE_URL } from "../../utils/constants";
import { randomInteger, PriceFormat } from "../../utils/helpers";
import "./TopBanner.scss";


const TopBanner = (props) => {

  const {data} =  props;
  const {products} =  data;

  const params = useParams();

  const selectedItem = products.filter(item => item.id === params.productId)[0];

  const itemsWithTopBannerEnabled = products.filter(item => item.topBanner && item.na === false);

  let itemsWithTopBannerWithoutSelected, adItem;

  if (selectedItem) {

    itemsWithTopBannerWithoutSelected = itemsWithTopBannerEnabled ? itemsWithTopBannerEnabled.filter(item => item.id !== selectedItem.id) : undefined;

    if (itemsWithTopBannerWithoutSelected.length === 1) {
      adItem = itemsWithTopBannerWithoutSelected[0];

    } else {
      const max = itemsWithTopBannerWithoutSelected.length - 1;
      adItem = itemsWithTopBannerWithoutSelected[randomInteger(0, max)];
    }

  } else {
    itemsWithTopBannerWithoutSelected = itemsWithTopBannerEnabled;
    const max = itemsWithTopBannerWithoutSelected.length - 1;
    adItem = itemsWithTopBannerWithoutSelected[randomInteger(0, max)];

  }

  if (!adItem) return null;

  return (
    <div className="top-banner">
      <Link className="container" to={`/products/${adItem.id}/${adItem.slug}`} >
        <img src={`${BASE_URL}/assets/images/products/${adItem.images.small[0]}`} />
        <div className="content">
          <p className="desc">{adItem.description}</p>
          <p className="price"><sup className="sup" style={{ fontSize: '12px' }}>$</sup>{PriceFormat(adItem.price)}</p>
          <button className="buy-btn" >Buy now</button>
        </div>

      </Link>
    </div>
  )
}

export default withRouter(TopBanner);
