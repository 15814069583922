// @flow
import React from 'react';
import SanitizedHTML from "react-sanitized";
import {BASE_URL} from "../../utils/constants";

type Props = {
  HTML: string 
};

const sortRelativeAnchorUrls=(str)=>{
  // create an element
  var temp = document.createElement('div');

  // set the content with the string
  temp.innerHTML = str;

  Array.from(temp.querySelectorAll('a')).forEach(function(ele) {
    const hrefVal = ele.getAttribute('href');
    if(!hrefVal.includes("http")){
      const newLink = BASE_URL+hrefVal;
      ele.setAttribute('href', newLink)
    }
  });
  return temp.innerHTML;
  }

const RenderHTML = (props:Props) => {
  const htmlStr = props.HTML.includes('<a') ? sortRelativeAnchorUrls(props.HTML) : props.HTML;
  return (
    <SanitizedHTML
      options={{
        allowedTags:["div","ul","em","li","a","p","img","span","strong","b","br","hr","sup","h1","h2","h3"],
        allowedAttributes:false
      }}
      html={htmlStr}
    />
  );
};

export default RenderHTML;


