import React, { useState, useRef, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { useGoondrookSiteData } from '../../utils/dataContext';
import Select, { components } from "react-select";
import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../../utils/constants";
import { updateTotalItemsCountInHeader, slugify } from "../../utils/helpers";
import { useAuth } from '../Authentication/AuthContext';
import awsExports from '../Authentication/aws-exports';
import "./Header.scss";
import magnifyIcon from "./magnify-icon.svg";

const SEARCHTXT = "I am looking for..."

const { Option } = components;

const IconOption = (props) => {
  return (
    <Option {...props}>
      <Link style={{ height: '100%' }} to={`/products/${props.data.id}/${props.data.slug}`}  >
        <p style={{ margin: 0, fontWeight: '700', fontSize: '13px' }}>
          {props.data.value}
          <span style={{ fontSize: '15px', color: '#d70606', fontWeight: '700' }}> ${props.data.price}</span>
        </p>
        {/* <span style={{ fontSize: '11px', fontStyle: 'italic', color: 'grey' }}>
        {props.data.description}
      </span> */}
      </Link>
    </Option>
  )
}

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={magnifyIcon} />
    </components.DropdownIndicator>
  );
};



const Header = (props) => {
  const { data } = props;
  const { setAllCartItemsFromLs } = useGoondrookSiteData();

  let gData = data.itemList;
  const loginButtonRef = useRef(null);

  // remove nepal only items if the country is not Nepal
  if (data.config.userCountry.toLowerCase() !== 'nepal') {
    gData = gData.filter((d) => !d.onlyNepal);
  }

  let initialSelectOptions = [];
  gData.forEach((element, i) => {
    const obj = { value: element.name, label: element.name, price: element.price, id: element.id, slug: slugify(element.name) };
    initialSelectOptions.push(obj)
  });

  const [selectedSite, setSelectedSite] = useState({ label: SEARCHTXT, value: -1 });
  const [selectOptions, setSelectOptions] = useState(initialSelectOptions)
  const [profileHovered, setProfileHovered] = useState(false)
  const [email, setUserEmail] = useState('')
  const { isLoggedIn, userEmail, logout, userGroup } = useAuth();



  useEffect(() => {
    // Function to handle the click event
    const handleClickOutside = (event) => {
      if (loginButtonRef.current && !loginButtonRef.current.contains(event.target)) {
        console.log("asdfsdf")
        setProfileHovered(false) 
        // If the clicked element is outside of the component, execute the callback function
        // onOutsideClick();
      }
    };

    // Attach event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

  }, [])



  useEffect(() => {
    const { pathname } = props.history.location;
    const allCartItems = setAllCartItemsFromLs()
    updateTotalItemsCountInHeader(allCartItems);
  }, [isLoggedIn])



  useEffect(() => {
    setUserEmail(userEmail)
  }, [userEmail])


  const onSelectChange = (selectedOption) => {
    setSelectedSite(selectedOption);
  }

  const { pathname } = props.history.location;

  const colourStyles = {
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#9fd5ee'
        : isSelected
          ? '#9fd5ee'
          : undefined,
      borderRadius: 0
    }),

    // dropdownIndicator: (provided) => ({
    //   ...provided,
    //   "svg": {
    //     fill:'red'
    //   }
    // })
  }

  const newSelectedSite = selectedSite;// { label: SEARCHTXT, value: -1 };   // pName ? { label: pName, value: pName } : selectedSite;

  let headerColor = "#000";
  if (data) {
    headerColor = data.config["Top Navigation Backround Color"] || headerColor;
  }

  const mobileSelectClass = pathname === "/" ? "select-site-dropdown mobile-only select-opt" : "select-site-dropdown mobile-only"

  const isMaintenance = pathname === "/maintenance";

  let username = email ? email.split('@')[0] : '';
  username = 'Hi ' + username.charAt(0).toUpperCase() + username.slice(1);

  const logoToUse = window.innerWidth < 500 ? `${BASE_URL}/assets/images/logo-mobile.png` : `${BASE_URL}/assets/images/logo.png`


  return (

    <section className="section-header">
      <div className="header" style={{ backgroundColor: headerColor }} >

        <Link to="/" >
          <img className="logo" src={logoToUse} />
        </Link>

        {!isMaintenance &&
          <Select
            className="select-site-dropdown desktop-only"
            //value={newSelectedSite}
            placeholder="Search"
            options={selectOptions}
            onChange={onSelectChange}
            components={{ Option: IconOption, DropdownIndicator }}
            styles={colourStyles}
          />
        }

        {!isMaintenance &&
          <div className="help-div desktop-only">
            <Link to="/help" className="help">? </Link>
          </div>
        }

        {!isMaintenance &&
        // onMouseOut={() => { setProfileHovered(false) }} 
          <div ref={loginButtonRef} className="login-btn" onClick={() => { setProfileHovered(true) }} >
            
            {/* <Link to={isLoggedIn ? "/account" : "/login"} className="help" >{isLoggedIn ? username : 'Login'}</Link> */}
            <a className="help" style={{textAlign:'right'}} >{isLoggedIn ? username : 'Login'}</a>

            {profileHovered && isLoggedIn &&
              <div className="signout-btn" >
                <Link to="/" className="help" onClick={logout} >Sign Out</Link>
                <Link to="/account" className="help" >Profile</Link>
                <div className="sign-underline"></div>
                {userGroup === 'Admin' &&
                  <>
                    <Link to="/admin" className="help" >Edit Items</Link>
                    <Link to="/vieworders" className="help" >View orders</Link>
                    <div className="sign-underline"></div>
                  </>
                }

              </div>
            }
          </div>
        }


        {!isMaintenance &&
          <Link to="/cart" className="snipcart-checkout" >
            <div className="snipcart-summary">
              <div className="c-shopping-cart" >
                <img src={`${BASE_URL}/assets/images/shopping-cart-icon.png`} />
                <span id="cart-item-count" className="snipcart-items-count">0</span>
              </div>
            </div>
          </Link>
        }

      </div>


      <div className="mobile-search">
        <Select
          className={mobileSelectClass}
          //value={newSelectedSite}
          placeholder="Search"
          options={selectOptions}
          onChange={onSelectChange}
          components={{ Option: IconOption }}
          styles={colourStyles}
        />

        <div className="help-div mobile-only">
          <Link to="/help" className="help">? </Link>
        </div>
      </div>


    </section>

  )
}


export default withRouter(Header);







