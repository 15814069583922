import React, { Component, useEffect, useState } from 'react';
import Select, { components } from "react-select";
import { Link, useParams, Redirect } from "react-router-dom";
import Page from "../../utils/Page";
import { customLog, updateTotalItemsCountInHeader } from "../../utils/helpers";
import { BASE_URL, PLACEHOLDER_PRODUCT_IMAGE } from '../../utils/constants';
import "./Cart.scss";
import RenderHTML from '../../utils/RenderHtml';
import TopBanner from '../../components/TopBanner/TopBanner';
import Header from '../../components/Header/Header';
import CardIcons from '../../components/CardIcons/CardIcons.js';
import { useGoondrookSiteData } from '../../utils/dataContext.js';
import ImageWithPlaceHolder from '../../components/ImageWithPlaceHolder/ImageWithPlaceHolder.js';


const Cart = (props) => {

  const [totalAmount, setTotalAmount] = useState(0);
  const { data } = props;
  const { goondrookData, allCartItems, updateAllCartItems } = useGoondrookSiteData();


  useEffect(() => {
    let totalAmount = 0;
    if (allCartItems && allCartItems.length > 0) {
      allCartItems.forEach(item => {
        totalAmount = totalAmount + (item.price * item.total)
      })
      updateAllCartItems(allCartItems)
      setTotalAmount(totalAmount)
    }
  }, [])


  const addRemoveItem = (index, action) => {

    const currentCartItems = allCartItems;

    let updatedCartItems = allCartItems;

    currentCartItems.forEach((d, i) => {
      if (i === index) {
        if (action === 'plus') {
          const count = updatedCartItems[i].total + 1;
          updatedCartItems[i].total = count;
        }
        if (action === 'minus') {
          const rcount = updatedCartItems[i].total === 0 ? 0 : updatedCartItems[i].total - 1;
          updatedCartItems[i].total = rcount;
          if (rcount === 0) {
            updatedCartItems.splice(i, 1)
          }
        }
        if (action === 'delete') {
          updatedCartItems.splice(index, 1)
        }
      }
    })


    updateAllCartItems(updatedCartItems);

    const totalAmount = updateTotalItemsCountInHeader(updatedCartItems);
    checkSumUpDuplicateItems("quantity", updatedCartItems, totalAmount)
  }



  const checkSumUpDuplicateItems = (type, allCartItems, totalAmount) => {
    // const { allCartItems } = this.state;

    if (type === "quantity") {
      updateAllCartItems(allCartItems)
      setTotalAmount(totalAmount)
    }
    if (type === "variant") {
      updateAllCartItems(allCartItems)
    }

  }



  const onVariantChange = (selectedOption, lastOption) => {
    let newCI = allCartItems;
    newCI.forEach((d, i) => {
      if (d.id === selectedOption.itemId && d.selectedVariant.name === lastOption.value) {

        d.selectedVariant.name = selectedOption.value;
        customLog(d.selectedVariant)
      }
    })
    updateAllCartItems(newCI);
    checkSumUpDuplicateItems("variant", newCI)
    // this.setState({ allCartItems })
  }


  const currencySign = window.goondrookConfig.config.currencySign;

  if (!allCartItems) return (<Page id="cart"><h3>Cart is empty</h3></Page>);

  if (allCartItems.length === 0) return (
    <Page id="cart">
      <div className="empty-cart">
        <h3>Cart is empty</h3>
        <Link className="cart-continue" to="/">
          <button>
            <span>
              <svg className="delete-icon" width="24" height="24" aria-hidden="true" focusable="false">
                <use xlinkHref="#icon-checkout-arrow" />
              </svg>
            </span>
            Back to store
          </button>
        </Link>
      </div>
    </Page>
  )


  return (

    <Page id="cart" data={data}>

      <div className="cart">
        <Link className="cart-continue" to="/">
          <span>
            <svg className="delete-icon" width="24" height="24" aria-hidden="true" focusable="false">
              <use xlinkHref="#icon-checkout-arrow" />
            </svg>
          </span>
          <span>Continue shopping</span>
        </Link>
        <div>
          <h3> Cart Summary</h3>
        </div>

        {allCartItems &&

          allCartItems.map((item, i) => {

            let variantOptions = [], seletedVarValue = {};
            if (item.selectedVariant) {
              item.variants.data.forEach((element, i) => {
                const obj = { value: element.name, label: element.name, itemId: item.id };
                variantOptions.push(obj)
              });
              seletedVarValue = { label: item.selectedVariant.name, value: item.selectedVariant.name, itemId: item.id };
            }

            return (
              <div className="cart-row" key={i}>
                <figure className="cart-row__media" >
                  <ImageWithPlaceHolder src={`${BASE_URL}/assets/images/products/${item.images.small[0]}`} alt={`${BASE_URL}/assets/images/products/${item.images.small[0]}`} placeholderSrc={PLACEHOLDER_PRODUCT_IMAGE} />
                </figure>
                <div className="cart-row__product">
                  <div className="cart-row__product__header">
                    <p>{item.name}</p>
                    <div>
                      <span className="svg-span" onClick={() => addRemoveItem(i, 'delete')}>
                        <svg className="delete-icon" width="24" height="24" aria-hidden="true" focusable="false">
                          <use xlinkHref="#icon-delete" />
                        </svg>
                      </span>
                    </div>

                  </div>
                  <div className="cart-row__product__content">

                    {variantOptions.length > 0 &&
                      <div className="cart-row__product__content__qantity">
                        <p>{item.variants.type}</p>
                        <p className="var-name">{item.selectedVariant.name}</p>
                        {/* <Select
                            value={seletedVarValue}
                            options={variantOptions}
                            onChange={(so) => onVariantChange(so, seletedVarValue)}
                          /> */}
                      </div>
                    }

                    <div className="cart-row__product__content__qantity">
                      <p>Quantity</p>
                      <div className="cart-row__product__content__qantity__increment">
                        <span onClick={() => addRemoveItem(i, 'minus')}>-</span >{item.total}<span onClick={() => addRemoveItem(i, 'plus')}>+</span>
                      </div>
                    </div>
                    <p className="item-total-price">{currencySign}{(item.price * item.total).toFixed(2)}</p>
                  </div>
                </div>
              </div>
            )
          })
        }

        <div className="cart-footer">
          <div></div>
          <div className="summary-fees">
            <div className="summary-fees__item">
              <span className="subtotal">Subtotal:</span>
              <span className="subtotal">{currencySign}{totalAmount.toFixed(2)}</span>
            </div>

            <div className="summary-fees__item">
              <span className="total">Total:</span>
              <span className="total">{currencySign}{totalAmount.toFixed(2)}</span>
            </div>

            <Link to="/checkout" >
              <button className="btn-checkout">
                <span>Checkout</span>
                <span>
                  <svg className="delete-icon" width="24" height="24" aria-hidden="true" focusable="false">
                    <use xlinkHref="#icon-checkout-arrow" />
                  </svg>
                </span>
              </button>
            </Link>

            <div className="security">
              <svg className="lock-icon" width="24" height="24" aria-hidden="true" focusable="false">
                <use xlinkHref="#icon-lock" />
              </svg>
              <span>
                <a href="#">Secured by Goondrook</a>
              </span>
            </div>

          </div>
        </div>

        <div className="cart-footer">
          <CardIcons />
        </div>


      </div>

    </Page>
  );


}

export default Cart;


