import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Footer from "./components/Footer/Footer";
import Routes from './Routes';
import SvgIcons from './components/SvgIcons';
import { AuthProvider } from './components/Authentication/AuthContext';
import { SiteDataProvider } from './utils/dataContext';


const App = (props: AppProps) => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    function redirectToHttpsWww() {
      const loc = window.location;
      if (loc.href.startsWith('http://goondrook.com') || loc.href.startsWith('https://goondrook.com')) {
        loc.href = 'https://www.goondrook.com' + loc.pathname + loc.search;
      }else{
        setReady(true)
      }
    }
    redirectToHttpsWww();
  }, []);



  if (ready) {
    return (
      <SiteDataProvider>
        <SvgIcons fastShipping editIcon closeBtn tickInCircle deleteIcon lockIcon checkoutArrow tick sideArrow americanExpress applePay mastercard paypal visa playBtnCircle />
        <ToastContainer />
        {/* <Header /> */}
        <AuthProvider>
          <Routes />
          <Footer />
        </AuthProvider>
      </SiteDataProvider>
    )
  }
}

export default App;
