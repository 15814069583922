import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import HeaderPlain from './components/Header/HeaderPlain';
import Welcome from "./pages/Welcome";
import Products from "./pages/Products/Products";
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductsDetails from "./pages/ProductsDetails/ProductsDetails";
import Footer from "./components/Footer/Footer";
import Privacy from "./pages/Privacy";
import AboutUs from "./pages/AboutUs";
import Terms from "./pages/Terms";
import NotFound from "./pages/NotFound";
import PayWithPaypal from "./components/PayWithPaypal/PayWithPaypal";
import Cart from "./pages/Cart/Cart";
import Checkout from "./pages/Checkout/Checkout";
import SignInForm from './components/Authentication/SignInForm';
import SignUpForm from './components/Authentication/SignUpForm';
import Home from "./pages/Home/Home";
import ProductsInCategory from "./pages/ProductsInCategory/ProductsInCategory";
import MaintenanceMode from "./pages/MaintenanceMode";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";
import Help from "./pages/Help";
import { isDev, isStaging } from './utils/helpers';
import AuthenticatedRoute from './components/Authentication/AuthenticatedRoute';
import Admin from './pages/Admin/Admin';
import UserAccount from './components/UserAccount/UserAccount';
import ResetPassword from './components/Authentication/ResetPassword';
import { useGoondrookSiteData } from './utils/dataContext';
import ViewOrders from './pages/Admin/ViewOrders/ViewOrders';

const Routes = ({ component: Component, requiredGroup, ...rest }) => {

  const { goondrookData, isInitialDataLoaded } = useGoondrookSiteData();

  if (!isInitialDataLoaded) return <div>Loading please wait...</div>;


  if (goondrookData.error) {
    return (
      <>
        <ToastContainer />
        <HeaderPlain />
        <div className="error-modal">
          <div className="error-modal__box">
            <p>Error occured</p>
            <p>Something went wrong while opening the site. This error has been reported. Please check back in few minutes. Sorry for the inconvenience.</p>
          </div>
        </div>
      </>
    )
  }


  return (

      <Switch>

        {/* --------  Authenticated Routes ------------ */}

        <AuthenticatedRoute exact path="/checkout" component={Checkout} />

        <AuthenticatedRoute exact path="/admin" component={Admin} requiredGroup="Admin"  />

        <AuthenticatedRoute exact path="/account" component={UserAccount} />


        <Route path="/resetpassword" exact>
          <ResetPassword />
        </Route>


        <Route path="/" exact >
          <Home data={goondrookData} />
        </Route>

        <Route path="/maintenance" exact>
            <MaintenanceMode data={goondrookData} />
        </Route>

        <Route path="/success" exact>
          <PaymentSuccess />
        </Route>

        <Route path="/help" exact>
          <Help />
        </Route>

        <Route path="/vieworders" exact>
          <ViewOrders />
        </Route>

        <Route path="/welcome" exact>
          <Products data={goondrookData} />
        </Route>
        <Route path="/category/:category" exact>
          <ProductsInCategory data={goondrookData} />
        </Route>
        {/* <Route path="/products" data={data}  exact>
          <Products />
        </Route> */}
        <Route path="/aboutus" exact>
          <AboutUs data={goondrookData.siteInfo['about us']} />
        </Route>
        <Route path="/privacy" exact>
          <Privacy data={goondrookData.siteInfo['privacy']} />
        </Route>
        <Route path="/terms" exact>
          <Terms data={goondrookData.siteInfo['privacy']} />
        </Route>
        <Route path="/products/:productId/:slug" exact >
          <ProductsDetails data={goondrookData} />
        </Route>

        <Route path="/paytest" exact>
          <PayWithPaypal />
        </Route>

        <Route path="/login" exact>
          <SignInForm />
        </Route>

        <Route path="/cart" exact>
          <Cart />
        </Route>

        <Route path="/signup" exact>
          <SignUpForm />
        </Route>


        <Route path="/notfound" exact>
          <NotFound />
        </Route>
        <Route component={NotFound} />
      </Switch>

  )
};
export default Routes;

