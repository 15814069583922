import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./HomepageBanner.scss";
import { BASE_URL } from '../../utils/constants';

const ANIMATION_SPEED = 4000;

class HomepageBanner extends Component {

    constructor(props: Props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth
        }
        this.onMouseIn = false;
        this.sliding = false;
    }

    componentDidMount() {
        this.animation = setTimeout(this.animateBanner, ANIMATION_SPEED);
        window.addEventListener('resize', this.handleResize)
    }

    handleResize = () => {
        clearTimeout(this.animation);
        this.setState({ windowWidth: window.innerWidth },()=>{
            const slidesContainer = document.getElementById("slides-container");
            slidesContainer.scrollLeft = 0;
            this.animation = setTimeout(this.animateBanner, ANIMATION_SPEED);
        })
    }

    componentWillUnmount() {
        clearTimeout(this.animation)
    }



    animateBanner = () => {

        const { data } = this.props

        if(!this.onMouseIn){

            const slidesContainer = document.getElementById("slides-container");
            const slidesContainerTotalWidth = data.homepageBanners.length * slidesContainer.clientWidth;

            const slide = document.querySelector(".slide");
            const prevButton = document.getElementById("slide-arrow-prev");
            const nextButton = document.getElementById("slide-arrow-next");

            const slideWidth = slide.clientWidth;

            if (slidesContainer.scrollLeft >= slidesContainerTotalWidth) {
                slidesContainer.style.scrollBehavior = 'auto';
                slidesContainer.scrollLeft = 0
                this.animation = setTimeout(this.animateBanner, ANIMATION_SPEED);
            } else {
                slidesContainer.style.scrollBehavior = 'smooth';
                slidesContainer.scrollLeft += slideWidth;
                this.animation = setTimeout(this.animateBanner, ANIMATION_SPEED);
            }
        }
    }



    onBtnsClick = (e) => {
        const id = e.currentTarget.id;
        const slidesContainer = document.getElementById("slides-container");
        const slide = document.querySelector(".slide");
        const prevButton = document.getElementById("slide-arrow-prev");
        const nextButton = document.getElementById("slide-arrow-next");

        if(!this.sliding){
            if (id === "slide-arrow-next") {
                const slideWidth = slide.clientWidth;
                this.sliding = true;
                slidesContainer.scrollLeft += slideWidth;
            };

            if (id === "slide-arrow-prev") {
                const slideWidth = slide.clientWidth;
                this.sliding = true;
                slidesContainer.scrollLeft -= slideWidth;
            };

            setTimeout(()=>{
                this.sliding = false;
            },1000);
        }

    }



    changeScroll(type) {
        this.onMouseIn = type === 'in' ;
        if(type === "in"){
            clearTimeout(this.animation);
        }else{
            this.animation = setTimeout(this.animateBanner, ANIMATION_SPEED);
        }
        const slidesContainer = document.getElementById("slides-container");
        let style = slidesContainer.style.overflowX;
        slidesContainer.style.overflowX = (style === 'hidden') ? 'auto' : 'hidden'
    }

    formatImage = (image) => {
        const imgTemp = `${BASE_URL}/assets/images/banners/${image}`;
        const splitted = image.split(".");
        const img = window.innerWidth < 710 ? `${BASE_URL}/assets/images/banners/${splitted[0]}-mobile.${splitted[1]}` : imgTemp;
        return img;
    }




    render() {

        const { data } = this.props

        const bannerData = data.homepageBanners.filter(d=> !d.disable);

        return (
            <div className="banner">

                <section className="slider-wrapper">
                    <button className="slide-arrow" id="slide-arrow-prev" onClick={this.onBtnsClick}>
                        <span>
                            <svg width="24" height="24" aria-hidden="true" focusable="false" style={{transform: 'rotate(180deg)'}}>
                                <use xlinkHref="#icon-side-arrow" />
                            </svg>
                        </span>
                    </button>
                    <button className="slide-arrow" id="slide-arrow-next" onClick={this.onBtnsClick}>
                        <span>
                            <svg width="24" height="24" aria-hidden="true" focusable="false">
                                <use xlinkHref="#icon-side-arrow" />
                            </svg>
                        </span>
                    </button>


                    <ul className="slides-container" id="slides-container" onMouseEnter={()=>this.changeScroll('in')} onMouseLeave={()=>this.changeScroll('out)')} >
                        
                        {bannerData &&

                            bannerData.map((item, i) => {

                                return (
                                    <li key={i} className="slide" >

                                        <Link to={item.link} >
                                            <img src={this.formatImage(item.image)} alt={item.title} />
                                        </Link>

                                    </li>
                                )

                            })
                        }

                        <li className="slide" >
                            <Link to={bannerData[0].link} >
                                <img src={this.formatImage(bannerData[0].image)} alt={bannerData[0].title} />
                            </Link>
                        </li>
                    </ul>
                </section>
            </div>
        )
    }
}

export default HomepageBanner;
