import React, { Component, useState, useEffect, Suspense } from 'react';
import "./Variants.scss";
import { BASE_URL } from "../../utils/constants";
import { isImageUrlOrColorCode, isColorName, toTitleCase } from '../../utils/helpers';
import { useGoondrookSiteData } from '../../utils/dataContext';


const borderActive = '#f09800'
const borderIdle = '#cccccc';


const ImageComponent = ({ index, name, src, mouseOver, mouseOut, onClick }) => {
  const [isError, setIsError] = useState(false);

  const handleImageError = () => {
    setIsError(true);
  };

  return (
    <>
      {isError ? (
        <div className="holder div-h" id={`vimg-${index}`} onMouseOver={(e) => mouseOver(e, index)} onMouseOut={(e) => mouseOut(e, index)} onClick={(e) => onClick(e.currentTarget, src, index)} >{toTitleCase(name)}</div>
      ) : (
        <img className="holder" alt={name} src={src} id={`vimg-${index}`} onMouseOver={(e) => mouseOver(e, index)} onMouseOut={(e) => mouseOut(e, index)} onClick={(e) => onClick(e.currentTarget, src, index)} onError={handleImageError} />
      )}
    </>
  );
};



const Variants = (props) => { 

  const { selectedVariant, updateSelectedVariant } = useGoondrookSiteData();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { id, data } = props;


  useEffect(() => {
    if (document.getElementById(`vimg-0`)) {
      document.getElementById(`vimg-0`).style.border = '2px solid ' + borderActive;
    }
    setWindowVariant({ id, type: data.type, name: data.data[0].name })

  }, [])


  const onVariantSelected = (ct, img, i) => {

    const { id, data, onVariantSelectedOnParent } = props;
    setWindowVariant({ id, type: data.type, name: data.data[i].name })

    resetAll();
    setSelectedIndex(i)

    ct.style.border = '2px solid ' + borderActive;

    onVariantSelectedOnParent({ display: "block", src: img })

    // const varintImgDiv = document.getElementById("variant-img");
    // varintImgDiv.style.display = "block";
    // varintImgDiv.setAttribute('src', img);
  }


  const onDivClicked = (ct, i) => {
    const { id, data } = props;
    setWindowVariant({ id, type: data.type, name: data.data[i].name })

    resetAll();
    setSelectedIndex(i)
    ct.style.border = '2px solid ' + borderActive;
  }


  const setWindowVariant = (obj) => {
    updateSelectedVariant(obj);
  }

  const resetAll = () => {
    data.data.forEach((item, k) => {
      document.getElementById(`vimg-${k}`).style.border = '2px solid ' + borderIdle;
    })
  }

  const mouseOver = (event, i) => {
    if (selectedIndex !== i) {
      event.target.style.borderColor = borderActive;
    }
  }
  const mouseOut = (event, i) => {
    if (selectedIndex !== i) {
      event.target.style.borderColor = borderIdle;
    }
  }

  return (

    <div className="variants">
      <p>{data.type}: {toTitleCase(data.data[selectedIndex].name)} </p>

      {data.data.map((vt, i) => {

        if (vt.img) {

          if (isImageUrlOrColorCode(vt.img) === 'image') {
            const img = `${BASE_URL}/assets/images/products/${vt.img}`;
            return (

              <ImageComponent key={i} index={i} src={img} name={vt.name} mouseOver={mouseOver} mouseOut={mouseOut} onClick={onVariantSelected} />

              // <img className="holder" key={i} src={img} id={`vimg-${i}`} onMouseOver={(e) => mouseOver(e, i)} onMouseOut={(e) => mouseOut(e, i)} onClick={(e) => onVariantSelected(e.currentTarget, img, i)} onError={(i) => handleImageError(i, vt.img)} />
            )
          }
          if (isImageUrlOrColorCode(vt.img) === 'color') {
            return (
              <div className="holder" key={i} id={`vimg-${i}`} style={{ backgroundColor: vt.img }} onMouseOver={(e) => mouseOver(e, i)} onMouseOut={(e) => mouseOut(e, i)} onClick={(e) => onVariantSelected(e.currentTarget, vt.img, i)} />
            )
          }

        } else {

          if (isColorName(vt.img)) {
            return (
              <div className="holder" key={i} id={`vimg-${i}`} style={{ backgroundColor: vt.img }} onMouseOver={(e) => mouseOver(e, i)} onMouseOut={(e) => mouseOut(e, i)} onClick={(e) => onVariantSelected(e.currentTarget, vt.img, i)} />
            )
          } else {
            return (
              <div className="holder div-h" key={i} id={`vimg-${i}`} onMouseOver={(e) => mouseOver(e, i)} onMouseOut={(e) => mouseOut(e, i)} onClick={(e) => onDivClicked(e.currentTarget, i)} >{toTitleCase(vt.name)}</div>
            )
          }
        }
      })
      }
    </div>

  )

}

export default Variants;
