import React, { Component, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Select, { components } from "react-select";
import { Link, useParams } from "react-router-dom";
import SunEditor from 'suneditor-react'; //https://github.com/mkhstar/suneditor-react
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import Page from "../../../utils/Page";
import { HOST_NAME, InitialData, PHP_ALI_SUBMIT_END_POINT, PHP_AMAZON_SUBMIT_END_POINT, PHP_UPLOAD_MEDIA_END_POINT, ITEM_SCRAPE_ERROR, PHP_TO_GS_END_POINT, PHP_OPENAI } from "../../../utils/constants";
import SpinnerRegular from "../../../components/Spinner/SpinnerRegular";
import { slugify, iDGenerator, customLog } from "../../../utils/helpers";
import RenderHTML from "../../../utils/RenderHtml";
import ButtonWithLoader from "../../../components/ButtonWithLoader/ButtonWithLoader";
import "./AddUpdateProduct.scss";
import { GetItemDetails } from "../../../api/GetItemDetails";
import { useGoondrookSiteData } from '../../../utils/dataContext.js'



const InitialValidData = { valid: false, msg: '' };


const { Option } = components;
const IconOption = (props) => {
  return (
    <Option {...props}>
      <p style={{ margin: 0, fontWeight: '700', fontSize: '13px' }}>
        {props.data.value}
        <span style={{ fontSize: '15px', color: '#d70606', fontWeight: '700' }}> ${props.data.price}</span>
      </p>
    </Option>
  )
}


export class ProductForm extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      isValid: InitialValidData,
      data: InitialData,
      submit: { status: 'submit', msg: 'Submit' },
      aiSubmit: 'submit',
      scrape: { status: 'submit', msg: '' },
      imageOnly: false,
      videoOnly: false,
      refId: iDGenerator(),
      confirmItemIdText: '',
      selectOptions: undefined,
      formAction: 'insert',
      seller: 'richardlama@gmail.com',
      showRightPanel: window.innerWidth < 1271 ? false : true
    }
    this.captcha = "";
  }


  componentDidMount() {
    window.scroll(0, 0);
    this.setSelectOptions()
    window.addEventListener('resize', this.handleResize)
  }


  handleResize = () => {
    if (window.innerWidth > 1217 && !this.state.showRightPanel) {
      this.setState({ showRightPanel: true })
    }
  }


  /**
   * 
   * @param {*} orders 
   * @returns all existing orders with item source url
   */
  async setSelectOptions() {
    const allProductsUrl = process.env.NODE_ENV === 'development' ? '/data/rawproducts.json' : 'https://' + HOST_NAME + '/data/rawproducts.json';
    const response = await fetch(allProductsUrl);

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      customLog('something went wrong publishing sites.  ' + message);
    }
    const res = await response.json();

    let selectOptions = [];

    const allItems = window.goondrookConfig.itemList;

    let itemWithSourceUrl = allItems
    allItems.forEach((item, k) => {
      res.data.forEach((d) => {
        if (item.id === d.id) {
          res.sourceUrls.forEach((src) => {
            if (src.id === d.id) {
              itemWithSourceUrl[k].url = src.url;
            }
          })
        }
      })
    })

    itemWithSourceUrl.forEach((element, i) => {
      const obj = { value: element.name, label: element.name, price: element.price, id: element.id, url: element.url };
      selectOptions.push(obj)
    });
    this.setState({ selectOptions });

  }



  deleteITem = async (id) => {

    let data = { type: 'delete', id };

    const response = await fetch(PHP_TO_GS_END_POINT, {
      method: 'post',
      body: JSON.stringify(data),
      redirect: "follow",
      headers: {
        "Content-Type": "text/plain"
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      //throw new Error(message);
      return 'not found';
    }
    const res = await response.json();

    /// Publishing sites at google sheet
    await this.publishSitesAtGoogleSheet();
    this.setState({ submit: { status: 'submit', msg: 'Form Submitted' } })
    customLog('... Done processing ...');
    customLog(res)
  }


  onCaptchaChange = (captcha) => {
    //customLog("Captcha value:", captcha);
    this.captcha = captcha;
  }


  validateInputs() {
    const { data, imageOnly, videoOnly, seller } = this.state;

    let isValid = { valid: true, msg: '', id: '' };

    let requiredErrorCount = 0;

    if (!seller) {
      isValid.valid = false;
      isValid.msg = `Seller's email required.`;
      this.highlightUnHighlightDivWithErrors('sellersEmail')
      return isValid;
    }

    data.forEach((group) => {

      group.items.forEach((item) => {

        if ((imageOnly || videoOnly) || (imageOnly && videoOnly)) {

          if (document.getElementById("itemIdToUploadAssetOnly").value === "") {
            isValid.valid = false;
            isValid.msg = `Item id is required. `;
            isValid.id = item.id;
          }

          else {

            if (item.type === 'images' && item.images.length === 0 && imageOnly) {

              isValid.valid = false;
              isValid.msg = `At least one image url required. `;
              isValid.id = item.type;
            }

            if (item.type === 'images' && item.images.length > 0 && imageOnly) {

              item.images.forEach((d, i) => {
                if (!d.includes("https://")) {
                  isValid.valid = false;
                  isValid.msg = `Invalid image url. Check the image url.`
                  isValid.id = item.type;
                }
                if (d === "") {
                  isValid.valid = false;
                  isValid.msg = `At least one image url required. `
                  isValid.id = item.type;
                }
              })
            }

            if (item.id === 'video' && item.value === "" && videoOnly) {
              isValid.valid = false;
              isValid.msg = `A video url required. `
              isValid.id = item.type;
            }

            if (item.id === 'video' && videoOnly) {
              if (!item.value.includes("https://") || !item.value.includes(".mp4")) {
                isValid.valid = false;
                isValid.msg = `Invalid video url. Check the image url.`
                isValid.id = item.type;
              }
              if (!item.value) {
                isValid.valid = false;
                isValid.msg = `Video url required.`
                isValid.id = item.type;
              }
            }

          }

        } else {
          if (item.required && !item.value && item.type !== 'images') {
            requiredErrorCount = requiredErrorCount + 1;
            isValid.valid = false;
            isValid.msg = `${item.label} cannot be empty. `
            isValid.id = item.id;
          }
          if (item.type === 'images' && item.images.length === 0) {
            isValid.valid = false;
            isValid.msg = `At least one image url required. `
            isValid.id = item.id;
          }
        }


      })
    })

    if (requiredErrorCount > 1) {
      isValid.msg = `Fill in all the required fields.`
    } else {

      if (!isValid.valid) {
        this.highlightUnHighlightDivWithErrors(isValid.id)
      }
    }

    //this.setState({ isValid })
    return isValid;
  }



  highlightUnHighlightDivWithErrors = (id, unHighlight) => {
    if (document.getElementById(id)) {
      if (unHighlight) {
        document.getElementById(id).classList.remove('highlight-on-error');

      } else {
        document.getElementById(id).classList.add('highlight-on-error');
      }
    }
  }



  hidShowErrorMsg = (obj) => {
    const errorDiv = document.getElementById("error");
    if (obj) {
      if (obj.show) {
        errorDiv.style.padding = '8px 5px';
        errorDiv.innerHTML = obj.msg;
      }
    } else {
      errorDiv.style.padding = '0';
      errorDiv.innerHTML = '';
    }
  }



  submitForm = async (action) => {

    const { data, imageOnly, videoOnly, refId, confirmItemIdText, submit, seller } = this.state;

    if (submit.status === 'submitting') return;

    const isValid = this.validateInputs();

    if (!isValid.valid) {
      this.setState({ isValid });
      return;
    }

    this.setState({ isValid: InitialValidData })

    // flatten data array
    let newData = {}, imagesToUpload = [];

    // condition to upload video or image only
    if ((videoOnly || imageOnly) || (videoOnly && imageOnly)) {

      if (videoOnly) {
        const res = await this.uploadVideos();

        if (res.msg === 'error') {
          this.setState({ isValid: { ...isValid, msg: 'Something went wrong while trying to upload video. Check your video url. Try again later.' }, submit: { status: 'submit', msg: 'Submit' } })
          return;
        }
      }

      if (imageOnly) {
        const res = await this.uploadImages();
        if (res.msg === 'error') {
          this.setState({ isValid: { ...isValid, msg: 'Something went wrong while trying to upload image. Check your image urls. Try again later.' }, submit: { status: 'submit', msg: 'Submit' } })
          return;
        }
      }

      await this.publishSitesAtGoogleSheet();
      this.setState({ submit: { status: 'submit', msg: 'Video and Images uploaded' }, confirmItemIdText: '', videoOnly: false, imageOnly: false });
      return;

    }


    data.forEach((group, i) => {

      group.items.forEach((item, i) => {

        newData[item.id] = item.value;

        if (item.type === 'images') {
          imagesToUpload = item.images;
          newData[item.id] = item.images
        }

        if (item.id === 'tags') {
          let tags = ["", "", "", ""];

          let dTags = [item.value];
          if (item.value.includes(",")) {
            dTags = item.value.split(",");
            dTags.forEach(d => {
              d.trim()
            })
          }
          for (let i = 0; i < 4; i++) {
            if (dTags[i]) {
              tags[i] = dTags[i];
            }
          }
          newData[item.id] = tags;
        }

        if (item.id === 'variants') {
          let flattenedVariants = this.flattenVariants();
          newData.variants = flattenedVariants;
        }
      })
    })

    // add seller to the data
    newData.sellersEmail = seller;

    //this.setState({ submit: { status: 'submitting', msg: 'Processing ...' } })

    // else do the regular form upload with images and video
    await this.uploadImages();
    await this.uploadVideos();

    customLog('Uploading form data ...')
    this.setState({ submit: { status: 'submitting', msg: 'Uploading form data ...' } })

    // Upload form Data
    newData.type = action;
    const response = await fetch(PHP_TO_GS_END_POINT, {
      method: 'post',
      body: JSON.stringify(newData),
      redirect: "follow",
      headers: {
        "Content-Type": "text/plain"
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      //throw new Error(message);
      return 'not found';
    }
    const res = await response.json();

    /// Start publishing sites at google sheet
    await this.publishSitesAtGoogleSheet();

    this.setState({ submit: { status: 'submit', msg: 'Form submitted' } })

    this.onRightPanelClicked(false)

    customLog('... Done processing ...');
    customLog(res)
  }



  publishSitesAtGoogleSheet = async () => {

    this.setState({ submit: { status: 'submitting', msg: 'Publishing site ...' } })

    let data = { type: 'publishStage' };
    const response = await fetch(PHP_TO_GS_END_POINT, {
      method: 'post',
      body: JSON.stringify(data),
      redirect: "follow",
      headers: {
        "Content-Type": "text/plain"
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      customLog('something went wrong publishing sites.  ' + message);
    }
    const res = await response.json();
    this.setState({ submit: { status: 'submit', msg: 'Site published' } })
  }



  uploadImages = async () => {

    try {
      const { data, imageOnly } = this.state;
      let imageData = [];
      let customNames = [];
      let postfix = "-postfix";

      const idIndex = data[0].items.findIndex(d => d.id === 'id');
      const refId = data[0].items[idIndex].value;

      data.forEach((d, i) => {
        //customLog(d)
        if (d.items[0].id === "images") {
          if (d.items[0].images.length > 0) {
            imageData = d.items[0].images;
            customNames = d.items[0].customNames;
          }
        }
      })

      let newData = [];

      //const idToUse = refId;// imageOnly ? document.getElementById("itemIdToUploadAssetOnly").value : refId;

      imageData.forEach((d, i) => {
        const name = customNames[i] ? slugify(customNames[i]) : refId + "-" + i;
        const obj = { name, url: d }
        newData.push(obj)
      })


      customLog('... Uploading images ...');
      this.setState({ submit: { status: 'submitting', msg: 'Uploading images ...' } })


      const imageListToUpload = { type: "image", data: newData, refId }
      /*
       --- Sample imageListToUpload ---
      {
          "type": "image",
          "data": [
              {
                  "name": "559999995915387-0",
                  "url": "https://mahalmart.com.au/cdn/shop/files/aama_ko_achar_hot_spicy_lapsi_pickle_mahal_mart.jpg"
              }
          ],
          "refId": "559999995915387"
      }
      */

      const response = await fetch(PHP_UPLOAD_MEDIA_END_POINT, {
        method: 'post',
        body: JSON.stringify(imageListToUpload),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        customLog(message)
        return { msg: 'error' }
      }
      let res = await response.json();

      console.log("-----res----")
      console.log(res)


      if (res.msg === "image uploaded") {
        customLog(res)
        return res;
      } else {
        customLog(res)
        return { msg: 'error' };
      }


    } catch (error) {
      console.log("---error uploading images '")
      console.log(error);
      return { msg: 'error' }

    }
  }


  uploadVideos = async () => {

    const { data, videoOnly } = this.state;
    let url = "";// "https://video.aliexpress-media.com/play/u/ae_sg_item/2208493074692/p/1/e/6/t/10301/342265894673.mp4"
    let postfix = "-postfix";

    //const productId = slugify(data[0].items[0].value);
    data.forEach((d, i) => {
      //customLog(d)
      if (d.items[0].id === "video") {
        url = d.items[0].value
      }
    })

    const idIndex = data[0].items.findIndex(d => d.id === 'id');
    const refId = data[0].items[idIndex].value;

    customLog('... Uploading video ...');
    this.setState({ submit: { status: 'submitting', msg: 'Uploading video ...' } })

    const videoData = { url: url, name: refId, folder: "assets/videos/products" }

    const payload = { type: "video", data: videoData, refId }


    console.log(payload)


    try {
      const response = await fetch(PHP_UPLOAD_MEDIA_END_POINT, {
        method: 'post',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        //throw new Error(message);
        return 'not found';
      }
      let res = await response.json();

      customLog(res.msg)
      //res = JSON.parse(res);
      return res;

    } catch (error) {
      customLog("---error uploading videos '")
      customLog(error);
      return { msg: 'error' }
    }
  }


  onSunEditorInputChange = (htmlStr, groupIndex, itemIndex, inputType) => {

    const { data, isValid } = this.state;

    let newData = data;
    const itemToUpdate = newData[groupIndex].items[itemIndex];
    itemToUpdate.value = htmlStr;
    this.setState({ data: newData })
  }



  onInputChange = (e, groupIndex, itemIndex, inputType) => {

    const id = e.target.id;

    this.highlightUnHighlightDivWithErrors(id, true);

    if (id === 'sellersEmail') {
      this.setState({ seller: e.target.value, isValid: { valid: false, msg: '' } })
      return;
    }


    const val = inputType === 'checkbox' ? e.target.checked : e.target.value;

    const { data, isValid } = this.state;

    let newData = data;
    const itemToUpdate = newData[groupIndex].items[itemIndex];
    itemToUpdate.value = itemToUpdate.type === 'number' ? +val : val;


    if (id === 'id') {
      const idIndex = newData[0].items.findIndex(d => d.id === 'id');
      newData[0].items[idIndex].value = val;
    }

    if (id === 'productName') {
      const slugIndex = newData[1].items.findIndex(d => d.id === 'slug');

      const slugedId = slugify(val);
      newData[1].items[slugIndex].value = slugedId;
    }

    if (id === 'actualPrice' || id === 'profitMargin') {

      const getProfitMargin = id === 'actualPrice' ? newData[groupIndex].items.filter(d => d.id === 'profitMargin')[0].value : val;
      const valToUse = id === 'profitMargin' ? newData[groupIndex].items.filter(d => d.id === 'actualPrice')[0].value : val;

      const priceIndex = newData[groupIndex].items.findIndex(d => d.id === 'price');
      let sellingPrice = (+valToUse + valToUse * getProfitMargin / 100).toFixed(2)
      sellingPrice = Math.round(sellingPrice * 100 / 5) * 5 / 100;
      newData[groupIndex].items[priceIndex].value = sellingPrice;

      //rrp
      const rrpIndex = newData[groupIndex].items.findIndex(d => d.id === 'rrp');
      const max = 90, min = 40;
      const randy = Math.floor(Math.random() * (max - min + 1) + min);
      let rrp = (sellingPrice + sellingPrice * randy / 100).toFixed(2);
      rrp = rrp = Math.round(rrp * 100 / 5) * 5 / 100;
      newData[groupIndex].items[rrpIndex].value = rrp;

    }
    this.setState({ data: newData, isValid: { ...isValid, msg: '' } })

  }


  onInputClick = () => {
    //customLog("Adsaf")
    this.setState({ submit: { status: 'submit', msg: 'Submit' } })
    const { isValid } = this.state;
    if (!isValid) {
      this.setState({ isValid: { ...isValid, msg: '' }, submit: { status: 'submit', msg: '' }, scrape: { status: 'submit', msg: '' } })
    }
  }


  addVariant = (e, groupIndex, itemIndex) => {
    e.preventDefault();
    const { data } = this.state;

    const defaultVar = {
      name: "Color",
      options: []
    }

    let newData = data;
    const itemToUpdate = newData[groupIndex].items[itemIndex];
    itemToUpdate.variants.push(defaultVar)
    // customLog("variants data")
    // customLog(newData)
    this.setState({ data: newData })
  }


  addImages = (e, groupIndex, itemIndex) => {
    e.preventDefault();
    const { data } = this.state;

    let newData = data;
    const itemToUpdate = newData[groupIndex].items[itemIndex];
    itemToUpdate.images.push("")
    this.setState({ data: newData })
  }


  onImageInputChange = (e, groupIndex, itemIndex, imageIndex, customName) => {
    const id = e.target.id;
    const val = e.target.value;
    let newData = this.state.data;

    const itemToUpdate = newData[groupIndex].items[itemIndex];

    if (customName) {
      itemToUpdate.customNames[imageIndex] = val;
    } else {
      itemToUpdate.images[imageIndex] = val;
    }

    this.setState({ data: newData })
  }


  deleteImages = (e, groupIndex, itemIndex) => {
    e.preventDefault();
    const { data } = this.state;
    let newData = data;
    const itemToUpdate = newData[groupIndex].items[itemIndex];
    itemToUpdate.images.splice(itemIndex, 1);
    this.setState({ data: newData })
  }


  deleteVariant = (e, groupIndex, itemIndex) => {
    e.preventDefault();
    const { data } = this.state;
    let newData = data;
    const itemToUpdate = newData[groupIndex].items[itemIndex];
    //customLog(itemToUpdate)
    itemToUpdate.variants.splice(itemIndex, 1);
    this.setState({ data: newData })
  }



  onVariantChange = (e, groupIndex, itemIndex, variantIndex) => {
    const id = e.target.id;
    const val = e.target.value;
    let newData = this.state.data;
    const itemToUpdate = newData[groupIndex].items[itemIndex];

    itemToUpdate.variants[variantIndex].name = val;

    customLog(itemToUpdate)

    this.setState({ data: newData })
  }


  addVariantItem = (e, groupIndex, itemIndex, optionIndex) => {
    e.preventDefault();
    const { data } = this.state;
    let newData = data;
    const itemToUpdate = newData[groupIndex].items[itemIndex];
    itemToUpdate.variants[optionIndex].options.push({ label: 'Red', value: 'red' });
    this.setState({ data: newData })
  }


  flattenVariants = () => {
    const { data } = this.state;
    let variants = "";

    console.log(data[0].items)
    const productId = data[1].items[2].value;

    data.forEach((d, i) => {
      //customLog(d)
      if (d.items[0].id === "variants") {
        if (d.items[0].variants.length > 0) {
          variants = d.items[0].variants;
        }
      }
    })

    if (variants) {
      // making the flattened array to look like variants in google sheet. 
      //// data.id, type	v1	img1	v2	img2	v3	img3	v4	img4	v5	img5	v6	img6	v7	img7	v8	img8

      let flattenedVariants = [productId];

      variants.forEach((variant, k) => {

        flattenedVariants.push(variant.name)

        const varOptions = variant.options.slice(0, 10); // limit to 10 items

        varOptions.forEach(option => {

          if (option.value) {
            flattenedVariants.push(option.value);
            if (option.img) {
              if (option.img.includes('.jpg') || option.img.includes('.png') || option.img.includes('.webp')) {
                flattenedVariants.push(option.img)
              } else {
                flattenedVariants.push("")
              }
            } else {
              flattenedVariants.push("")
            }
          }
        })
      })


      for (let i = 0; i < 18; i++) {
        if (!flattenedVariants[i]) {
          flattenedVariants[i] = "";
        }
      }
      // customLog(flattenedVariants)
      flattenedVariants = flattenedVariants.slice(0, 18); // limit to 10 items

      return flattenedVariants;
    }
  }


  deleteVariantItem = (e, groupIndex, itemIndex, variantIndex, optionIndex) => {
    e.preventDefault();
    const { data } = this.state;
    let newData = data;
    const itemToUpdate = newData[groupIndex].items[itemIndex];
    itemToUpdate.variants[variantIndex].options.splice(optionIndex, 1);//[optionIndex].options.push({ label: 'Red', value: 'red' });

    if (itemToUpdate.variants[variantIndex].options.length === 0) {
      itemToUpdate.variants.splice(itemIndex, 1);
    }

    this.setState({ data: newData })
  }


  onVariantItemChange = (e, groupIndex, itemIndex, variantIndex, optionIndex) => {
    const id = e.target.id;
    const val = e.target.value;
    let newData = this.state.data;
    const itemToUpdate = newData[groupIndex].items[itemIndex];
    if (id === 'varOptImg') {
      itemToUpdate.variants[variantIndex].options[optionIndex].img = val;
    }
    if (id === 'varOptVal') {
      itemToUpdate.variants[variantIndex].options[optionIndex].value = val;
    }
    itemToUpdate.variants[variantIndex].options[optionIndex].label = val;
    this.setState({ data: newData })
  }


  calculatePrice = (pr) => {
    const valToUse = pr;
    const profitMargin = 75;
    let sellingPrice = (+valToUse + valToUse * profitMargin / 100).toFixed(2)
    sellingPrice = Math.round(sellingPrice * 100 / 5) * 5 / 100;
    return sellingPrice;
  }

  calculateRrp = (pr) => {
    const valToUse = pr;
    const profitMargin = 75;
    let sellingPrice = (+valToUse + valToUse * profitMargin / 100).toFixed(2)
    sellingPrice = Math.round(sellingPrice * 100 / 5) * 5 / 100;

    const max = 90, min = 40;
    const randy = Math.floor(Math.random() * (max - min + 1) + min);
    let rrp = (sellingPrice + sellingPrice * randy / 100).toFixed(2);
    rrp = rrp = Math.round(rrp * 100 / 5) * 5 / 100;

    return rrp;
  }

  onSourceBtnClicked = async (e) => {
    e.preventDefault();

    if (this.state.scrape.status === 'submitting') return;

    const url = document.getElementById("ali-url").value;

    if (!url) {
      this.highlightUnHighlightDivWithErrors("ali-url");
      this.setState({ isValid: { msg: 'Item Source Url required.' } });
      return;
    }

    if (url) {

      this.setState({ scrape: { status: 'submitting', msg: 'Processing ...' } })

      const payload = { urls: [url], checkchanges: '' };

      const END_POINT = url.includes('amazon.com') ? PHP_AMAZON_SUBMIT_END_POINT : PHP_ALI_SUBMIT_END_POINT;

      try {
        const response = await fetch(END_POINT, {
          method: 'post',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          const message = `An error has occured: ${response.status}`;
          //throw new Error(message);
          this.setState({ scrape: { status: 'submit', msg: ITEM_SCRAPE_ERROR } })

          return 'not found';
        }
        const scrapeData = await response.json();

        customLog(scrapeData[0])

        let message = scrapeData.error ? scrapeData.error : 'Item data retrieved successfully.';

        if (scrapeData[0]) {
          let currentData = this.state.data;
          const sData = scrapeData[0];

          currentData.forEach((group) => {

            group.items.forEach((item) => {

              if (item.id === "productName") {
                item.value = sData.title;
              }

              if (item.id === "seoTitle") {
                item.value = sData.title;
              }

              if (item.id === "seoDescription") {
                item.value = sData.title;
              }

              if (item.id === 'not available') {
                item.value = "";  /// needs to fix
                item.type = "hidden"; // make the input visible
                item.label = ""
              }

              if (item.id === "slug") {
                item.value = slugify(sData.title)
              }

              if (item.id === "id") {
                item.value = iDGenerator();
              }
              if (item.id === "sourceUrl") {
                item.value = sData.url
              }
              if (item.id === "stock") {
                if (url.includes('amazon.com')) {
                  item.value = "";
                } else {
                  item.value = sData.variants.length > 0 ? sData.variants[0].availQuantity : "";
                }
              }
              if (item.id === "actualPrice") {
                if (url.includes('amazon.com')) {
                  item.value = "";
                  item.value = sData.price;
                } else {
                  item.value = sData.variants.length > 0 ? sData.variants[0].price : "";
                }
              }

              if (item.id === "rrp") {

                if (url.includes('amazon.com')) {
                  item.value = this.calculateRrp(+sData.price)
                } else {
                  if (sData.variants.length > 0) {
                    item.value = this.calculateRrp(+sData.variants[0].price)
                  } else {
                    item.value = ""
                  }
                }
              }

              if (item.id === "price") {
                if (url.includes('amazon.com')) {
                  item.value = this.calculatePrice(+sData.price);

                } else {
                  if (sData.variants.length > 0) {
                    item.value = this.calculatePrice(+sData.variants[0].price);;
                  } else {
                    item.value = "";
                  }
                }
              }
              if (item.id === "video") {
                item.value = sData.videoUrl;
              }
              if (item.id === "images") {
                item.images = sData.images
              }
              if (item.id === "descriptionHtmlLong") {
                item.value = sData.description
              }

              if (item.id === "variants") {
                const variantOptions = [];
                sData.variants.forEach(vrt => {
                  variantOptions.push({ label: vrt.propertyName, value: vrt.propertyName.toLowerCase() });
                })
                item.variants = [{ name: 'Color', options: variantOptions }]
              }
            })
          })
          customLog(currentData)
        }

        const dt = scrapeData[0];


        if (dt.variants) {
          if (dt.variants.length > 0) {
            message = 'Item data retrieved partially. Some features are not available. You may need to manually fill in the empty fields.'
          }
        }

        this.setState({ scrape: { status: 'submit', msg: message }, itemData: scrapeData })

      } catch (error) {
        this.setState({ scrape: { status: 'submit', msg: ITEM_SCRAPE_ERROR } })
        customLog(error)
      }
    }
  }


  onSelectChangeToUpdate = async (selectedOption) => {
    this.lookForItem(selectedOption)
  }



  getSellersEmail = async (id) => {
    this.setState({ submit: { status: 'submitting', msg: 'Processing...' } });

    let data = { type: 'sellers', id: id };

    const response = await fetch(PHP_TO_GS_END_POINT, {
      method: 'post',
      body: JSON.stringify(data),
      redirect: "follow",
      headers: {
        "Content-Type": "text/plain"
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      //throw new Error(message);
      return 'not found';
    }
    let res = await response.json();

    const ss = JSON.parse(res.data)
    this.setState({ submit: { status: 'submit', msg: '' } });
    return ss.sellersEmail;
  }




  lookForItem = async (option) => {
    const productId = option.id;
    const sourceUrl = option.url;

    document.getElementById("ali-url").value = sourceUrl;

    let product = window.goondrookConfig.products.filter(item => item.id === productId)[0];

    if (!product) {
      try {
        const {alterSingleItemPriceBasedOnUsersCountry } = useGoondrookSiteData();
        const productsData = await GetItemDetails(productId);
        product = alterSingleItemPriceBasedOnUsersCountry(productsData);
        console.log("xxxxxxxxx")
        console.log(product)
      } catch (err) {
        this.setState({ selectdItem: 'not found' });
        return;
      }
    }
    const sellersEmail = await this.getSellersEmail(productId)

    let newData = InitialData;
    let refId = "";

    newData.forEach((dt, i) => {

      dt.items.forEach((item, k) => {

        if (item.id === 'productName') item.value = product.name;
        if (item.id === 'sourceUrl') item.value = "Not required if updating";  /// needs to fix


        if (item.id === 'id') item.value = product.id;
        refId = product.id;

        if (item.id === 'slug') item.value = product.slug;
        if (item.id === 'auto_altered') item.value = ""; /// needs to fix
        if (item.id === 'not available') {
          item.value = "";  /// needs to fix
          item.type = "checkbox"; // make the input visible
          item.label = "Not available anymore?"
        }
        if (item.id === 'stock') item.value = product.stock;
        if (item.id === 'actualPrice') item.value = product['actual price'];
        if (item.id === 'profitMargin') item.value = product.profitMargin ? product.profitMargin : "75";
        if (item.id === 'nprDiscount') item.value = product.nprDiscount ? product.nprDiscount : "";
        if (item.id === 'price') item.value = product.price;
        if (item.id === 'rrp') item.value = product.rrp ? product.rrp : "";

        if (item.id === 'standard cost') item.value = product.shipping.standard.cost ? product.shipping.standard.cost : "";
        if (item.id === 'standard estimated days') item.value = product.shipping.standard.estimatedDays ? product.shipping.standard.estimatedDays : "";
        if (item.id === 'express cost') item.value = product.shipping.express.cost ? product.shipping.express.cost : "";
        if (item.id === 'express estimated days') item.value = product.shipping.express.estimatedDays ? product.shipping.express.estimatedDays : "";

        if (item.id === 'category1') item.value = product.categories[k] ? product.categories[k].name : "";
        if (item.id === 'category2') item.value = product.categories[k] ? product.categories[k].name : "";
        if (item.id === 'category3') item.value = product.categories[k] ? product.categories[k].name : "";
        if (item.id === 'category4') item.value = product.categories[k] ? product.categories[k].name : "";

        if (item.id === 'tags') item.value = product.tags ? product.tags : "";

        if (item.id === 'variants') {
          if (product.variants.hasOwnProperty('data')) {
            if (product.variants.data.length > 0) {
              let options = [];
              product.variants.data.forEach((opt, p) => {
                options.push({ label: opt.name[0].toUpperCase() + opt.name.slice(1), value: opt.name, img: opt.img })
              })
              item.variants = [{ name: product.variants.type, options }]
            }
          }
        }

        if (item.id === 'images') {
          if (product.images.big) {
            item.images = product.images.big;  /// needs to fix
          }
        }

        if (item.id === 'video') item.value = product.video ? product.video : "";

        if (item.id === 'homepageList') item.value = product.homepageList;
        if (item.id === 'topBanner') item.value = product.topBanner;
        if (item.id === 'popular now') item.value = product['popular now'];
        if (item.id === 'onlyForNepal') item.value = product.onlyForNepal;

        if (item.id === 'description') item.value = product.description ? product.description : "";
        if (item.id === 'descriptionHtmlSort') item.value = product.descriptionHtmlSort ? product.descriptionHtmlSort : "";
        if (item.id === 'descriptionHtmlLong') item.value = product.descriptionHtmlLong ? product.descriptionHtmlLong : "";

        if (item.id === 'seoTitle') item.value = product.seo.title ? product.seo.title : "";
        if (item.id === 'seoDescription') item.value = product.seo.description ? product.seo.description : "";

      })

    })
    const toHideRightPanel = window.innerWidth < 1218 ? false : true;
    this.setState({ data: newData, refId, formAction: 'update', seller: sellersEmail })
    // this.onRightPanelClicked(toHideRightPanel)

  }


  getDescriptionFromAi = async () => {
    const { isValid } = this.state;
    const itemName = document.getElementById("productName").value;

    if (!itemName) {
      this.setState({ isValid: { valid: false, msg: 'Product name is required' } }, () => {
        customLog(itemName)
        customLog(this.state.isValid)
      })
      return;
    }

    this.setState({ isValid: { msg: '' } })

    const desc = document.getElementById("description").value;
    if (desc) {
      customLog("It already has description. Do you want to replace?")
      this.setState({ isValid: { msg: 'Description already exists.' } })
      return;
    }

    const data = { type: 'describe', input: itemName };

    try {
      const response = await fetch(PHP_OPENAI, {
        method: 'post',
        body: JSON.stringify(data),
        redirect: "follow",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        //throw new Error(message);
        return 'not found';
      }
      let res = await response.json();

      res = JSON.parse(res);

      const description = res.choices[0].text;

      customLog(description)

      if (description) {
        let newData = this.state.data;
        newData.forEach((dt, i) => {
          dt.items.forEach((item, k) => {
            if (item.id === 'description') item.value = description.trim();
          })
        })
        this.setState({ data: newData });
      }
    } catch (error) {
      this.setState({ isValid: { msg: 'Something went wrong in the server side. Try again later.' } })
      // customLog(error);
      // return "sdf";
    }

  }


  //    //await this.simulateDelay(5000);
  async simulateDelay(ms) {
    await new Promise(resolve => setTimeout(resolve, ms));
  }


  onRightPanelClicked = (v) => {
    if (v) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    this.setState({ showRightPanel: window.innerWidth < 1217 ? v : true })
  }



  render() {
    const { isValid, data, submit, scrape, imageOnly, videoOnly, confirmItemIdText, selectOptions, aiSubmit, seller, showRightPanel } = this.state;
    const { productName, stock, actualPrice, profitMargin, nprDiscount, sourceUrl, homepageList, topBanner, popularNow, onlyForNepal } = data;

    let totalVariants = 0;
    data.forEach((d, i) => {
      if (d.items[0].id === "variants") {
        totalVariants = d.items[0].variants.length;
      }
    })

    const colourStyles = {
      option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        background: isFocused
          ? '#9fd5ee'
          : isSelected
            ? '#9fd5ee'
            : undefined,
        borderRadius: 0
      })
    }

    const showItemIdInput = imageOnly || videoOnly ? true : false;

    return (
      <div className="add-product-page">
        <div className="left-side">

          <h1>Add or update a product</h1>
          <p className="note">Note: Adding and updating item is initially applied to staging site. Once the item looks good in staging site hit 'Publish Live' to apply the changes to the Live site </p>

          <form onClick={this.onInputClick} className="ali-section" >
            <p>Scrape and get item details from Amazon or Alixpress site</p>
            <div className="form-group gr-row">
              <div className="form-item">
                <label>
                  Item Source Url
                </label>
                <input type="text" id="ali-url" placeholder="Amazon or Aliexpress Url" onClick={() => this.highlightUnHighlightDivWithErrors("ali-url", true)} />
              </div>
              <div className="form-item" style={{ width: '20%' }} >
                <label></label>
                <ButtonWithLoader onBtnClick={(e) => this.onSourceBtnClicked(e)} customStyle={{ maxWidth: '135px', height: '42px' }} label='Scrape' clearErrorMsg={() => this.setState({ isValid: { msg: '' } })} />

              </div>
            </div>

            {scrape.msg &&
              <div className="form-group gr-row" style={{ padding: '8px 0' }}>
                <div className="form-item" >
                  <p className="error">{scrape.msg}</p>
                </div>
              </div>
            }
          </form>

          <br></br>

          <form onClick={this.onInputClick}>

            <div className="form-group gr-row">
              <div id='sellersEmailDiv' className="form-item">
                <label>Sellers Email  <span className="required">*</span></label>
                <input id="sellersEmail" type='text' value={seller} onChange={(e) => this.onInputChange(e)} />
              </div>
            </div>


            {data.map((group, i) => {
              return (
                <div key={`g${i}`} className="form-group gr-row">
                  {
                    group.items.map((item, k) => {

                      if (item.type === 'hidden') return null;

                      return (
                        <div key={`i${k}`} id={`item-${item.id}`} className="form-item" >
                          <label style={{ width: item.type === 'images' ? '150px' : 'inherit' }}>
                            {item.required &&
                              <span className="required">*</span>
                            }
                            {item.label}

                            {
                              item.type === 'variant' && totalVariants === 0 &&
                              <button className="add-button" onClick={(e) => this.addVariant(e, i, k)}> + </button>
                            }

                            {
                              item.type === 'images' &&
                              <button className="add-button" onClick={(e) => this.addImages(e, i, k)}> + </button>
                            }
                          </label>

                          {item.type === 'category' &&
                            <select name="cats" id={item.id} onChange={(e) => this.onInputChange(e, i, k, item.type)} value={item.value} >
                              <option value="">Select ...</option>
                              {
                                window.goondrookConfig.categories.map((cat, r) => {
                                  return (
                                    <option key={r} value={cat.id} >{cat.Name}</option>

                                  )
                                })
                              }
                            </select>
                          }

                          {item.type === 'variant' &&
                            <div className="variant-groups">

                              {
                                item.variants.map((variantItem, v) => {

                                  return (
                                    <div key={v} className="variants">

                                      <div className="variant-name" >
                                        <input type="text" value={variantItem.name} onChange={(e) => this.onVariantChange(e, i, k, v)} placeholder="Variant Name" />
                                        <button className="add-button" onClick={(e) => this.addVariantItem(e, i, k, v)}> + </button>
                                        <button className="add-button" onClick={(e) => this.deleteVariant(e, i, k, v)}> - </button>
                                      </div>

                                      {
                                        variantItem.options.map((option, vt) => {
                                          return (

                                            <div key={vt} className="variant-item" >
                                              <input id="varOptVal" type="text" value={option.value || ''} onChange={(e) => this.onVariantItemChange(e, i, k, v, vt)} placeholder="Variant Name" />
                                              <input id="varOptImg" type="text" value={option.img || ''} onChange={(e) => this.onVariantItemChange(e, i, k, v, vt)} placeholder="Variant Image (file name with extension)" />
                                              {/* <input type="text" value={option.value} onChange={(e) => this.onVariantItemChange(e, i, k, v, vt)} placeholder="Variant Name" /> */}
                                              <button className="add-button" onClick={(e) => this.deleteVariantItem(e, i, k, v, vt)}> - </button>
                                            </div>

                                          )
                                        })
                                      }

                                    </div>
                                  )
                                }
                                )}
                            </div>
                          }

                          {item.type === 'images' &&
                            <div className="variant-groups">

                              {
                                item.images.map((imageItem, v) => {
                                  return (
                                    <div key={v} className="variants">

                                      <div className="variant-name" >
                                        <input placeholder="Image url" type="text" value={imageItem} onChange={(e) => this.onImageInputChange(e, i, k, v)} />

                                        {imageItem &&
                                          <>                                          <input type="text" placeholder="Custom image name" value={item.customNames[v] || ""} onChange={(e) => this.onImageInputChange(e, i, k, v, "custom name")} />

                                            <img src={imageItem}
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "assets/images/placeholder-image.png";
                                              }}
                                            />
                                          </>

                                        }
                                        <button className="add-button" onClick={(e) => this.deleteImages(e, i, k, v)}> - </button>
                                      </div>

                                    </div>
                                  )
                                }
                                )}
                            </div>
                          }

                          {/* {item.type === 'textarea' && item.id === 'descriptionShortLong' &&
                            <div className="html-description" >
                              <RenderHTML HTML={item.value} />
                            </div>
                          } */}

                          {/* {item.type === 'textarea' && item.id === 'descriptionHtmlLong' &&
                            <div className="html-description" > 
                              <RenderHTML HTML={item.value} />
                            </div>
                          } */}

                          {item.type === 'textarea' && item.id === "description" &&
                            <textarea id={item.id} type={item.type} value={item.value} onChange={(e) => this.onInputChange(e, i, k, item.type)} rows="8" cols="50" ></textarea>
                          }


                          {item.type === 'textarea' && (item.id === "descriptionHtmlLong" || item.id === "descriptionShortLong") &&
                            <SunEditor height="400px" style={{ width: '91vh' }} setContents={item.value} onChange={(e) => this.onSunEditorInputChange(e, i, k, item.type)} />
                          }


                          {item.type === 'text' &&
                            <input id={item.id} type={item.type} value={item.value} onChange={(e) => this.onInputChange(e, i, k, item.type)} />
                          }
                          {item.type === 'number' &&
                            <input id={item.id} type={item.type} value={item.value} step={item.step ? item.step : 1} onChange={(e) => this.onInputChange(e, i, k, item.type)} />
                          }
                          {item.type === 'checkbox' &&
                            <input type={item.type} id={item.id} checked={item.value || false} onChange={(e) => this.onInputChange(e, i, k, item.type)} />
                          }
                          {item.type === 'display' &&
                            <p className="form-input-display">{item.value}</p>
                          }

                        </div>
                      )
                    })
                  }
                </div>
              )
            }
            )}

          </form>
        </div>


        <div className="right-side" style={{ height: showRightPanel ? '101vh' : '0' }}>
          {/* onClick={() => this.setState({ isValid: { ...isValid, msg: '' } })}> */}
          {!showRightPanel &&
            <button className="right-panel-show-btn" onClick={() => this.onRightPanelClicked(true)} >Show Right Panel</button>
          }
          {showRightPanel &&
            <div className="content" >

              <div className="form-group gr-column">

                <div className="form-item side-panel">
                  <label>Upload Images Only</label>
                  <input type="checkbox" checked={imageOnly} onChange={(e) => this.setState({ imageOnly: e.target.checked })} />
                </div>

                <div className="form-item side-panel">
                  <label>Upload Video Only</label>
                  <input type="checkbox" checked={videoOnly} onChange={(e) => this.setState({ videoOnly: e.target.checked })} />
                </div>


                {showItemIdInput &&
                  <div className="form-item side-panel" style={{ padding: '10px 8px', background: 'pink' }}>
                    <label style={{ display: 'block' }}>Item Id (required to upload assets only)</label>
                    <input type="text" id="itemIdToUploadAssetOnly" placeholder="Item id here" style={{ minWidth: '200px' }} onChange={(e) => {
                      const idIndex = data[0].items.findIndex(d => d.id === 'id');
                      data[0].items[idIndex].value = e.target.value;
                      this.setState({ data })
                    }} />
                  </div>
                }

              </div>

              {/* ---------------- AI to get the descrition from the item name -------------------- */}
              <div className="form-group gr-column">
                <div className="form-item">

                  <ButtonWithLoader onBtnClick={this.getDescriptionFromAi} label='Get Description with AI' clearErrorMsg={() => this.setState({ isValid: { msg: '' } })} />

                  <p className="ai-usage"><sup>*</sup> Employ this feature with moderation.</p>

                </div>
              </div>


              {/* ---------------- Upate item -------------------- */}

              <div className="form-group gr-column">
                <div className="form-item update-div">
                  <label>Select an item to update</label>
                  <Select
                    className="select-site-dropdown-side-panel"
                    //value={newSelectedSite}
                    options={selectOptions}
                    onChange={this.onSelectChangeToUpdate}
                    components={{ Option: IconOption }}
                    styles={colourStyles}
                  />
                </div>
              </div>


              <div className="form-group gr-row">
                <div className="form-item">
                  <ButtonWithLoader onBtnClick={() => this.submitForm('insert')} label='Submit' addConfirmBtn={true} clearErrorMsg={() => this.setState({ isValid: { msg: '' } })} />
                </div>
                <div className="form-item">
                  <button className="right-panel-hide-btn" onClick={() => this.onRightPanelClicked(false)} >Hide</button>
                </div>

              </div>


              <div className="form-group gr-column" style={{ padding: '0 10px' }}>
                <div className="form-item" style={{ marginBottom: '10px' }}>
                  {submit.status == 'submitting' &&
                    <div>
                      <>
                        <span>{submit.msg}</span>
                        <SpinnerRegular />
                      </>
                    </div>
                  }
                  {submit.status === 'submit' && submit.msg !== "Submit" &&
                    <div>{submit.msg}</div>
                    // <div>Data uploaded succesfully</div>
                  }
                </div>
              </div>

              <p className="add-error" style={{ padding: isValid.msg ? '8px 12px' : '0' }}>{isValid.msg}</p>

            </div>
          }
        </div>
      </div>
    )
  }
}


const AddUpdateProduct = (props) => {
  return (
    <ProductForm />
  );
};

export default AddUpdateProduct;



