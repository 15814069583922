import React, { Component, useState, useEffect } from 'react';
import "./CardIcons.scss";


class CardIcons extends Component {

    constructor(props: Props) {
        super(props);
        this.state = {
            Snip: undefined
        }
    }

    render() {

        return (

            <div className="partner">

                <ul className="list-inline">

                    {/* <li>

                        <svg className="payment-icon" width="38" height="24" aria-hidden="true" focusable="false">
                            <use xlinkHref="#icon-american-express" />
                        </svg>

                    </li> */}

                    {/* <li>

                        <svg width="38" height="24" aria-hidden="true" focusable="false">
                            <use xlinkHref="#icon-apple-pay" />
                        </svg>

                    </li> */}


                    <li>
                        <svg width="38" height="24" aria-hidden="true" focusable="false">
                            <use xlinkHref="#icon-paypal" />
                        </svg>
                    </li>

                    <li>
                        <svg width="38" height="24" aria-hidden="true" focusable="false">
                            <use xlinkHref="#icon-mastercard" />
                        </svg>
                    </li>


                    <li>
                        <svg width="38" height="24" aria-hidden="true" focusable="false">
                            <use xlinkHref="#icon-visa" />
                        </svg>
                    </li>
                </ul>
                {/* <div><img src="https://static.afterpay.com/integration/product-page/logo-afterpay-colour.png"></div> */}

            </div>

        )
    }
}

export default CardIcons;
