import React, { useState, useEffect, useMemo } from 'react';
import { useGoondrookSiteData } from '../../utils/dataContext';
import SpinnerRegular from '../../components/Spinner/SpinnerRegular';
import "./ShowMoreButton.scss";


const ShowMoreButton = (props) => {
  const {totalDisplayed, totalItems, otherProductsReady } = props;
  const {setTotalDisplayed} = useGoondrookSiteData();

  const onMoreClicked = (e) => {
    e.preventDefault();
    setTotalDisplayed();
  }

  if (!otherProductsReady) return (
    <div className='grid-item loader-box' >
      Loading ... <SpinnerRegular />
    </div>
  )

  const showMoreBtn = totalDisplayed < totalItems ? true : false;

  if (!showMoreBtn) return null;

  return (
    <a href="#" className="grid-item more loader-box" onClick={onMoreClicked}>
      <div className="card"  >
        <div className="content" style={{ padding: '29px 0 25px 0' }}>
          <p id="moreTxt" className="p-name" style={{ textAlign: 'center' }}>More</p>
        </div>
      </div>
    </a>
  );
}

export default ShowMoreButton;