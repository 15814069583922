import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import SpinnerRegular from '../Spinner/SpinnerRegular';
import Page from '../../utils/Page';
import { customLog } from '../../utils/helpers';
import "./UserAccount.scss";
import { Button } from '@aws-amplify/ui-react';


const UserAccount = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('idle');

  return (

    <Page id="signin">
      <div className="user-account" >

        <form>
          <div className="form-group gr-row">
            <p>User account details</p>

            <div className="form-item">
              <p>Email: richardlama@gail.com</p>
            </div>
            <div className="hor-line" />
            <div className="form-item ua-item">
              <p>Password: ********</p>
              {/* <input type="text" placeholder="Email" value="dd" onChange={(e) =>console.log("dd")} /> */}

              <Button>
                <Link
                  to={{
                    pathname: '/resetpassword',
                    state: { head: 'Change your password' }
                  }}
                > Change</Link>
              </Button>

            </div>

          </div>

          {/* {error &&
            <div className="form-group gr-row" style={{ paddingTop: '0' }}>
              <div className="form-item">
                <p className="error">{error}</p>
              </div>
            </div>
          } */}


          <div className="form-group gr-row" style={{ paddingTop: '0' }}>

            <div className="form-item">

              {/* <button className="login-btn" onClick={()=>{customLog("login button clicked")}}>
              {status === 'processing' &&
                <><span>Processing... </span> <SpinnerRegular /></>
              }
              {status === 'idle' &&
                <span>Edit</span>
              }
            </button> */}

              {/* 
            <div className="sign-btn" >
              Need an account <Link to="/signup" >Sign up</Link>
            </div> */}
            </div>
          </div>


        </form>
      </div >
    </Page>
  )
};

export default UserAccount;
