import { DEVELOPERS, GOONDROOK_API_END_POINT } from "../utils/constants";


// Sends errors as email to developer via aws lambda function

export const DispatchErrors = async(jsonString, type) => {
    const emailErrorData = { error: jsonString, type: type, to: DEVELOPERS, subject: "Your order at Goondrook.com", message: '' }
    try {
        const res = await fetch(GOONDROOK_API_END_POINT+"/dispatch_errors", {
            method: 'post',
            body: JSON.stringify(emailErrorData),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (res.status === 200) {
            let data = await res.json();
            console.log(data)
        } else {
            console.log(res.status);
        }
    } catch (err) {
        console.log(err);
    }
}