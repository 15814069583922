import React, { Component, useEffect, useState } from 'react';
import { useParams, Redirect, Route, Link } from "react-router-dom";
import Page from "../../utils/Page.js";
import { BASE_URL, HOST_NAME } from '../../utils/constants.js';
import { GetItemDetails } from '../../api/GetItemDetails.js';
import "./ProductsDetails.scss";
import RenderHTML from '../../utils/RenderHtml/index.js';
// import TopBanner from '../../components/TopBanner/TopBanner.jsx';
import Header from '../../components/Header/Header.jsx';
import ImageLoader from '../../components/ImageLoader/ImageLoader.jsx';
import Variants from '../../components/Variants/Variants.jsx';
import { isInView, PriceFormat, estimatedDate, updateTotalItemsCountInHeader, updatePriceWithCountryCurrency, randomInteger, customLog } from '../../utils/helpers.js';
import CardIcons from '../../components/CardIcons/CardIcons.js';
import Cart from '../Cart/Cart.jsx';
import PopularNowGrid from '../../components/PopularNowGrid/PopularNowGrid.js';
import SpinnerRegular from '../../components/Spinner/SpinnerRegular.js';
import PriceRenderer from '../../components/PriceRenderer/PriceRenderer.jsx';
import { useGoondrookSiteData } from '../../utils/dataContext.js';



const BtnsGroup = (props) => {
  const { item, onAddToCardClicked, bigImages, id, cl } = props;
  return (

    <div className={cl} id={id}>

      <button className="snipcart-add-item" id="btn-add-to-cart"
        onClick={(e) => onAddToCardClicked(e, item)}
      >
        Add to cart
      </button>

      <Route render={({ history }) => (
        <button
          type='button'
          className="snipcart-add-item" id="btn-buy-now"
          onClick={() => { history.push('/cart') }}
        >
          View cart
        </button>
      )} />
    </div>
  )
}

const BreadCrumb = (props) => {
  //const cats = [{name: 'Gifts', id: 'gifts'},{name: 'Cleaning supplies', id: 'cleanisdf'},{name: 'digitial', id: 'ditial'},{name: 'bookd', id: 'book'}];
  const cats = props.selectdItem.categories;
  return (
    <div className="bread-crumb">
      {cats.length > 0 &&
        cats.map((cat, i) => {
          const dash = i > 0 ? "›" : "";
          return (
            <Link to={`/category/${cat.id}`} key={i}>
              {dash &&
                <span className="dash">{dash}</span>
              }
              {cat.name}
            </Link>
          )
        })
      }
    </div>
  )
}



/**  ---- Product Detail Component */

const ProductDetail = (props) => {

  const { selectedVariant, updateSelectedVariant} = useGoondrookSiteData();

  const { onBuyNowClicked, onAddToCardClicked, selectedImgIndex, showReadMore, imageClicked, onReadMoreClicked, closeVideo, selectdItem, goondrookData, updateVariant, setShowRecommendations } = props;

  const item = selectdItem;

  const bigImages = item.images.big;
  const smallImages = item.images.small;

  const variants = Object.keys(item.variants).length === 0 ? undefined : item.variants;

  const [variantImgObj, setVariantImgObj] = useState({ display: "none", src: 'init' });

  if (!variants) {
    setTimeout(() => {
      updateVariant(undefined);
    }, 500)
  }

  useEffect(()=>{
    setTimeout(()=>{
      setShowRecommendations()
    },2000)
  },[])

  let freeShipping = !item.shipping.standard.cost ? true : +item.shipping.standard.cost === 0 ? true : false;

  const videoSrc = item.video ? `${BASE_URL}/assets/videos/products/${item.id}.mp4` : '';

  const randNum = randomInteger(1, 20)
  const randy = (randNum % 2 === 0);


  return (
    <div className="product-detail"  >

      <div className="mobile-head" id="mobile-head">
        <h3>{item.name}</h3>
      </div>

      <div className="img-container">
        <ImageLoader selectedImgIndex={selectedImgIndex} bigImages={bigImages} smallImages={smallImages} itemName={item.seo.title} showVideoPlayBtn={item.video ? true : false} randy={randy} variantImgObj={variantImgObj} />
      </div>

      {item.video &&
        <div className="video-container" id="video-container" >
          <video id="product-video" src={videoSrc} />
          <div id="video-close-btn" className="video-close-btn" onClick={closeVideo}>
            <svg width="24" height="24" aria-hidden="true" focusable="false">
              <use xlinkHref="#icon-close-btn" />
            </svg>
          </div>
        </div>
      }


      <div className="content">
        <div className={randy ? 'desktop-head' : 'head-desktop'} >
          <h1>{item.name}</h1>
        </div>
        <PriceRenderer item={item} type="detail" randy={randy} />

        <hr />

        <p id="inStockdiv" className="in-stock" style={{ color: item.stock === 0 ? "#e20000" : "#042b5f", maxWidth: item.stock === 0 ? '108px' : '78px' }}>{item.stock === 0 ? "Out of stock" : "In stock"}</p>

        {variants &&
          <Variants data={variants} id={item.id} onVariantSelectedOnParent={(obj) => setVariantImgObj(obj)} />
        }


        {item.stock > 0 &&
          <div className="shipping-detail">
            <h3>{freeShipping ? 'FREE Shipping' : `Shipping: ${PriceFormat(item.shipping.standard.cost, true)}`}</h3>
            <p>Estimated delivery by {estimatedDate(item.shipping.standard.estimatedDays)}</p>

            <p style={{ display: item.shipping.express.cost ? 'block' : 'none' }}>
              <span className="fast-shipping">
                <svg width="24" height="24" aria-hidden="true" focusable="false">
                  <use xlinkHref="#icon-fast-shipping" />
                </svg>
              </span>
              <span className="fast-delivery-option">Fast delivery option {PriceFormat(item.shipping.express.cost, true)} </span>
              Estimated delivery by {estimatedDate(item.shipping.express.estimatedDays)}</p>
          </div>
        }


        {item.stock !== 0 &&
          <BtnsGroup item={item} bigImages={bigImages} onAddToCardClicked={onAddToCardClicked} id="btns-relative" cl="add-buy-btn-group" />
        }

        {item.stock !== 0 &&
          <BtnsGroup item={item} bigImages={bigImages} onAddToCardClicked={onAddToCardClicked} id="btns-fixed" cl="add-buy-btn-group-fixed" />
        }

        <p className="item-added-alert" id="added-to-cart-alert" >Item added to card</p>

        <h3 className="product-detail-head">Product Details</h3>

        <hr />


        <div id="descriptionDiv" className="description" style={{ maxHeight: '200px' }}>
          <div className="desc-content" id="desc-content">

            {item.descriptionHtmlSort &&
              <RenderHTML HTML={item.descriptionHtmlSort} />
            }
            {!item.descriptionHtmlSort &&
              <p>{item.description}</p>
            }
          </div>
          {showReadMore &&
            <p id="readMoreDiv" className="read-more" style={{
              backgroundImage: 'linear-gradient(to bottom, transparent, #fff)'
            }}>
              <a id="readMoreButton" href="#" className="button" onClick={onReadMoreClicked}>Read More</a>
            </p>
          }
        </div>

      </div>


      {item.descriptionHtmlLong &&
        <div className="content-desc">
          <div className="content">
            <h2>Additional details and specifications</h2>
            <RenderHTML HTML={item.descriptionHtmlLong} />
          </div>
        </div>
      }

    </div>
  );
};

/** Make use of route params, alternative use of functional useParams in class components. ProductsDetails class is wrapped with withParams component*/
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}




// ------------------------ M A I N   C O M P O N E N T -----------------------

const ProductsDetails = (props) => {

  const [showCheckoutBtn, setShowCheckoutBtn] = useState(false);
  const [selectdItem, setSelectdItem] = useState('');
  const [showReadMore, setShowReadMore] = useState(false);
  const [showRecommendations, setShowRecommendations] = useState(false);
  const { data } = props;
  const { goondrookData, selectedVariant, allCartItems, updateSelectedVariant, setSelectedVariant, updateAllCartItems, screenWidth, alterSingleItemPriceBasedOnUsersCountry } = useGoondrookSiteData();

  useEffect(() => {
    lookForItem();
  }, [])


  useEffect(() => {
    if (document.getElementById("desc-content") && selectdItem !== '' && selectdItem !== 'not found') {

      const h = document.getElementById("desc-content").getBoundingClientRect().height;
      setShowReadMore(h > 200 ? true : false)

      window.scroll(0, 0)

      if (window.innerWidth < 600) {
        onWindowScrolled();
        window.addEventListener("scroll", onWindowScrolled)
      }
    }
  }, [selectdItem])


  let prevProductID = '';
  useEffect(() => {
    if (props.params.productId !== prevProductID) {
      lookForItem();
      prevProductID = props.params.productId
    }
  }, [props.params.productId])



  const lookForItem = async () => {
    const productId = props.params.productId;
    let item = data.products.filter(item => item.id === productId)[0];

    if (!item) {
      try {
        const pItem =  await GetItemDetails(productId)
        item = alterSingleItemPriceBasedOnUsersCountry(pItem);
      } catch (err) {
        setSelectdItem('not found');  
        return;
      }
    }
    setSelectdItem(item);
  }



  const onWindowScrolled = () => {
    if (document.getElementById("btns-relative")) {
      const btnsRelative = document.getElementById("btns-relative");
      const btnsFixed = document.getElementById("btns-fixed");

      const classname = "add-buy-btn-group-fixed";
      const btnsRelativeInView = isInView(btnsRelative)

      if (!btnsRelativeInView) {
        btnsFixed.style.display = 'flex';
      } else {
        btnsFixed.style.display = 'none';
      }
    }
  }


  const onReadMoreClicked = (e) => {

    e.preventDefault();

    const val = e.target.innerHTML;

    document.getElementById("descriptionDiv").style.maxHeight = val === 'Read More' ? '999px' : '200px';
    document.getElementById("descriptionDiv").style.paddingBottom = val === 'Read More' ? '40px' : '0';
    document.getElementById("readMoreDiv").style.backgroundImage = val === 'Read More' ? 'none' : 'linear-gradient(to bottom, transparent, #fff)';
    document.getElementById("readMoreButton").innerHTML = val === 'Read More' ? 'Make Less' : 'Read More';

  }



  const processCartItems = (item) => {

    let cartItems = goondrookData.allCartItems ? goondrookData.allCartItems : [];

    let newCartItems = cartItems, newItem = {};

    // check if item already exist
    let itemExists = [];

    if (selectedVariant) {

      itemExists = cartItems.filter(d => {
        if (!d.variants && d.variants === {})
          console.log(d)
        return d.id === item.id && d.selectedVariant.name === selectedVariant.name
      });
    } else {
      itemExists = cartItems.filter(d => d.id === item.id);
    }

    if (itemExists.length === 0) {
      newItem = { ...item, total: 1, selectedVariant: selectedVariant };
      newCartItems = [...cartItems, newItem]
    } else {
      newCartItems.forEach(d => {
        if (d.id === item.id) {
          if (selectedVariant) {
            if (d.selectedVariant.name === selectedVariant.name) {
              d.total = itemExists[0].total + 1;
            }
          } else {
            d.total = itemExists[0].total + 1;
          }
        }
      })
    }

    updateAllCartItems(newCartItems);
    updateTotalItemsCountInHeader(newCartItems);

    // Update cartItems in local storage
    window.localStorage.setItem('cartitems', JSON.stringify(newCartItems));
  }



  const onAddToCardClicked = (e, item) => {

    processCartItems(item);

    if (e.target.id === 'btn-add-to-cart') {

      const addedToCartAlert = document.getElementById("added-to-cart-alert");
      addedToCartAlert.style.opacity = 1;

      setTimeout(() => {
        addedToCartAlert.style.opacity = 0;
      }, 2000)
    } else {
      setShowCheckoutBtn(true)

      setTimeout(() => {
        const currentUrl = window.location.href + "/cart";
        window.location = currentUrl;

      }, 1000)
    }
  }



  const checkForCartItemCount = () => {
    const ct = +document.getElementById("c-cart-count").innerHTML;
    //customLog(ct)
    if (ct > 0) {
      setShowCheckoutBtn(true)
    } else {
      setShowCheckoutBtn(false)
    }
  }


  const onBuyNowClicked = () => {
    const currentUrl = window.location.href + "#/checkout";
    window.location = currentUrl;
    //customLog(currentUrl)
  }

  const closeVideo = () => {
    let video = document.getElementById("product-video");
    let btn = document.getElementById("video-close-btn");
    video.pause()
    btn.style.display = 'none';
    video.style.display = "none";
  }

  const updateVariant = (v) => {
    updateSelectedVariant(v)
  }


  if (selectdItem === '') {
    return (
      <div className="pd-loader">
        <SpinnerRegular />
      </div>
    )
  }

  if (selectdItem === 'not found' || !selectdItem) return (<Redirect to="/notfound" />);


  return (
    <Page id="product-details" data={data}>

      {/* <TopBanner data={data}/> */}
      <BreadCrumb selectdItem={selectdItem} />

      <ProductDetail showReadMore={showReadMore} onReadMoreClicked={onReadMoreClicked} onAddToCardClicked={onAddToCardClicked} updateVariant={updateVariant} onBuyNowClicked={onBuyNowClicked} closeVideo={closeVideo} selectdItem={selectdItem} goondrookData={data} setShowRecommendations={()=>setShowRecommendations(true)} />

      {data.config && showRecommendations &&
        <PopularNowGrid data={data} title="Recommended for you" />
      }

    </Page>
  );


}

export default withParams(ProductsDetails);