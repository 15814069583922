import React, { useEffect, useState } from 'react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useAuth } from './AuthContext';
import { Link, useParams, useHistory, useLocation, Redirect } from "react-router-dom";
import awsExports from './aws-exports';
import { validateEmailString } from '../../utils/helpers';
import SpinnerRegular from '../Spinner/SpinnerRegular';
import "./Authentication.scss";
import Page from '../../utils/Page';


const initialState = {
  email: '',
  password: '',
  status: 'idle',
  formError: ''
};

const poolData = {
  UserPoolId: awsExports.aws_user_pools_id,
  ClientId: awsExports.aws_user_pools_web_client_id
};
const userPool = new CognitoUserPool(poolData);


const SignInForm = () => {

  const [state, setState] = useState(initialState);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const history = useHistory();
  const { login, isLoggedIn, userEmail, userGroup } = useAuth();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  const { email, password, status, formError } = state;


  const handleSignIn = async (e) => {

    e.preventDefault();

    if (!validateEmailString(email)) {
      setState({ ...state, formError: 'Email is not valid' });
      return;
    }
    if (!password) {
      setState({ ...state, formError: 'Password is required' });
      return;
    }

    try {
      setState({ ...state, status: 'processing' });
      const response = await login(email, password)
      setRedirectToReferrer(true);

    } catch (error) {
      console.error('Error:', error);
      setState({ ...state, formError: error.message || 'An error occurred during sign in.' });
    }
  };


  if (redirectToReferrer || isLoggedIn) {
    // Store the previously requested route in local storage
    const redirectRoute = localStorage.getItem('redirectRoute', from.pathname);
    // Redirect to the previously requested route if login is successful
    return <Redirect to={from} />;
  }


  return (

    <Page id="signin">
      <div className="login" >

        <form>
          <div className="form-group gr-row">
            <p>Sign in with your email and password</p>

            <div className="form-item">
              <label>Email</label>
              <input type="text" placeholder="Email" value={email} onChange={(e) => setState({ ...state, formError: '', email: e.target.value })} />
            </div>
            <div className="form-item">
              <label>Password</label>
              <input type="password" placeholder="Password" value={password} onChange={(e) => setState({ ...state, formError: '', password: e.target.value })} />
              <div className="fp-btn" >
                <Link to="/resetpassword" >Forgot your password?</Link>
              </div>
            </div>

          </div>

          {formError &&
            <div className="form-group gr-row" style={{ paddingTop: '0' }}>
              <div className="form-item">
                <p className="error">{formError}</p>
              </div>
            </div>
          }

          <div className="form-group gr-row" style={{ paddingTop: '0' }}>

            <div className="form-item">
              <button className="login-btn" onClick={handleSignIn}>
                {status === 'processing' &&
                  <><span>Processing... </span> <SpinnerRegular /></>
                }
                {status === 'idle' &&
                  <span>Login</span>
                }
              </button>

              <div className="sign-btn" >
                Need an account <Link to="/signup" >Sign up</Link>
              </div>
            </div>
          </div>

        </form>
      </div >
    </Page>

  )
};

export default SignInForm;
