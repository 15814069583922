import React, { Component, useEffect, useState } from 'react';
import { Link, useParams, Redirect, withRouter } from "react-router-dom";
import Select, { components } from "react-select";
import axios from 'axios';
import Page from "../../utils/Page";
import { BASE_URL, VALIDATE_CART_ITEMS_URL, HOST_NAME} from '../../utils/constants';
import { addTrailingZeros, downloadCsv, estimatedDate, isDev, formatToTwoDecimals, customLog } from '../../utils/helpers';
import { initialProviderOptions } from '../../components/PayWithPaypal/Constants';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { DispatchErrors } from '../../api/DispatchErrors';
import "./Checkout.scss";
import RenderHTML from '../../utils/RenderHtml';
import TopBanner from '../../components/TopBanner/TopBanner';
import Header from '../../components/Header/Header';
import SvgIcons from '../../components/SvgIcons';
import CardIcons from '../../components/CardIcons/CardIcons.js';
import PayWithPaypal from '../../components/PayWithPaypal/PayWithPaypal';
import SpinnerPaypal from '../../components/Spinner/SpinnerPaypal';
import SpinnerRegular from '../../components/Spinner/SpinnerRegular';
import { useGoondrookSiteData } from '../../utils/dataContext.js';
import { useAuth } from "../../components/Authentication/AuthContext"


/*
PayPal Error Format
{
  "name": "UNPROCESSABLE_ENTITY",
  "details": [
      {
          "field": "/purchase_units/@reference_id=='POAIPUFOCA39'/amount/value",
          "value": "5.1",
          "issue": "AMOUNT_MISMATCH",
          "description": "Should equal item_total + tax_total + shipping + handling + insurance - shipping_discount - discount."
      }
  ],
  "message": "The requested action could not be performed, semantically incorrect, or failed business validation.",
  "debug_id": "f4991754a6844",
  "links": [
      {
          "href": "https://developer.paypal.com/docs/api/orders/v2/#error-AMOUNT_MISMATCH",
          "rel": "information_link",
          "method": "GET"
      }
  ]
}
*/




const Checkout = (props) => {

  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState({ label: "Australia", value: "Australia" });
  const [selectedCity, setSelectedCity] = useState("Sydney");
  const [standardShippingCost, setStandardShippingCost] = useState(0);
  const [expressShippingCost, setExpressShippingCost] = useState(0);
  const [totalShippingCost, setTotalShippingCost] = useState(0);
  const [stdCheckboxVal, setStdCheckboxVal] = useState(true);
  const [paypalError, setPaypalError] = useState({ hasError: false, title: "", detail: "" });
  const [placeOrderBtnOn, setPlaceOrderBtnOn] = useState(true);

  const { userGroup } = useAuth();

  const { allCartItems, updateAllCartItems, goondrookData } = useGoondrookSiteData();
  const data = goondrookData;

  let validationError = false;



  useEffect(() => {
    calculateShipping(allCartItems);

    let totalAmount = 0;
    let allCI = allCartItems;
    allCI.forEach(item => {
      totalAmount = totalAmount + (item.price * item.total);
      item.price = item.price
    })
    updateAllCartItems(allCI)
    setTotalAmount(totalAmount)
    window.scroll(0, 0)

  }, [allCartItems]);




  const calculateShipping = (allCartItems) => {
    let standardShippingCost = 0;
    let expressShippingCost = 0;

    allCartItems.forEach((item, i) => {

      standardShippingCost = standardShippingCost + (item.shipping.standard.cost ? item.shipping.standard.cost : 0);
      expressShippingCost = expressShippingCost + (item.shipping.express.cost ? item.shipping.express.cost : 0);
    })
    standardShippingCost = standardShippingCost.toFixed(2);
    expressShippingCost = expressShippingCost.toFixed(2);

    const stdRadioInput = document.getElementById("standardShippingRadio");
    const expRadioInput = document.getElementById("expressShippingRadio");

    if (stdRadioInput && expRadioInput) {
      let totalShippingCost = 0;
      if (stdRadioInput.checked) {
        totalShippingCost = standardShippingCost;
      } else {
        totalShippingCost = expressShippingCost;
      }
      setTotalShippingCost(totalShippingCost)
      setStandardShippingCost(standardShippingCost)
      setExpressShippingCost(expressShippingCost)

    } else {
      setStandardShippingCost(standardShippingCost)
      setExpressShippingCost(expressShippingCost)
    }
  }


  const getTotalWithShipping = () => {
    const res = (+(totalShippingCost ? totalShippingCost : standardShippingCost) + totalAmount).toFixed(2);
    return res;
  }

  // NOT IN USE

  // const validateAndPlaceOrder = async (e) => {
  //   let vdata = { hostName: HOST_NAME, allCartItems: allCartItems };

  //   // if forex is defined than we know that the site is visited outside of australia hence using USD rate 
  //   if (data.forex) {
  //     const forex = data.forex;
  //     const usdRate = forex.filter(d => d.currency === 'usd')[0].sell;
  //     vdata = { hostName: HOST_NAME, allCartItems: allCartItems, usdRate: usdRate };
  //   }

  //   console.log(vdata)
  //   console.log(vdata)

  //   return fetch(VALIDATE_CART_ITEMS_URL, {
  //     method: 'post',
  //     body: JSON.stringify(vdata),
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //     .then((response) => response.json())

  //     .then((dt) => {
  //       const isDataTempered = dt.dataTempered;
  //       customLog(isDataTempered ? "Data has been tempered" : "Data good");
  //       return isDataTempered;
  //     })
  //     .catch((error) => {
  //       customLog(error);
  //     });


    // TO DO : Processing from google appscript NOT WORKING ---
    // const data = {"event_type":"VALIDATE_CART_ITEMS", "allCartItems":allCartItems};

    // const urlWithParams =  VALIDATE_CART_ITEMS_URL+"?data=fff";// +JSON.stringify(data);

    // fetch(urlWithParams, {
    //   headers: {
    //         'Content-Type': 'application/json'
    //       }
    // })
    //   .then((response) => response.json())
    //   .then((data) => {

    //     document.getElementById("po-spinner").style.display="none";
    //     document.getElementById("po-arrow").style.display="inline-block";

    //     const isDataTempered = data.dataTempered;

    //     customLog(isDataTempered ? "Data has been tempered" : "Data good")

    //     this.setState({ isDataTempered }, () => {

    //       this.setState({ placeOrderBtnOn: false })
    //       if (!isDataTempered) {
    //         setTimeout(() => {
    //           document.getElementById("spinner").style.display = "none";
    //           this.validating = false;
    //         }, 2400)
    //       }
    //     });
    //   })
    //   .catch((error) => {
    //     document.getElementById("po-spinner").display="none";
    //     document.getElementById("po-arrow").display="inline-block";
    //     this.validating = false;
    //     customLog(error);
    //   });
  //}


  const onShippingSelected = (val) => {
    if (val) {
      setTotalShippingCost(standardShippingCost);
      setStdCheckboxVal(val);
      setPaypalError({ ...paypalError, hasError: false })
    } else {
      setTotalShippingCost(expressShippingCost);
      setStdCheckboxVal(val);
      setPaypalError({ ...paypalError, hasError: false });
    }
  }

  /**  generates id from item it. This can also be the SKU
   *  Takes in first 2 chars of each word , uppercase it, and adds the first 2 digits of pric
  */
  const generateReferenceId = (item) => {
    let ref = "";
    if (item) {
      const arr = item.slug.split("-");
      arr.forEach((d) => {
        ref = ref + d.substring(0, 2)
      })
      ref = ref.toUpperCase();
      ref = ref + (("" + item.price).substring(0, 2));
    }
    return ref;
  }



  const getPaypalPurchaseUnits = () => {
    const currencyCode = data.config.currency;
    let paypalPurchaseUnits = [];

    allCartItems.forEach(item => {
      let totalAmount = 0;
      if (stdCheckboxVal) {
        totalAmount = (item.price * +item.total) + (item.shipping.standard.cost ? item.shipping.standard.cost : 0);
      } else {
        totalAmount = (item.price * +item.total) + (item.shipping.express.cost ? item.shipping.express.cost : 0);
      }

      totalAmount = formatToTwoDecimals(totalAmount);

      let eachShipping = 0;
      if (stdCheckboxVal) {
        eachShipping = item.shipping.standard.cost ? item.shipping.standard.cost : 0;
      } else {
        eachShipping = item.shipping.express.cost ? item.shipping.express.cost : 0;
      }

      eachShipping = formatToTwoDecimals(eachShipping);

      const variantName = item.selectedVariant ? " (" + item.selectedVariant.name + ")" : "";

      const eachItemTotal = formatToTwoDecimals(item.price * item.total)

      const obj =
      {
        "reference_id": generateReferenceId(item),
        "amount": {
          "currency_code": currencyCode,
          "value": totalAmount,
          "breakdown": {
            "item_total": { "currency_code": currencyCode, "value": eachItemTotal },
            "shipping": { "currency_code": currencyCode, "value": eachShipping },
            //"tax_total": { "currency_code":currencyCode, "value":"1.4"},
            // "discount": { "currency_code":currencyCode, "value":"0"}
          }
        },
        "items": [
          {
            "name": item.slug + variantName,
            "unit_amount": {
              "currency_code": currencyCode,
              "value": formatToTwoDecimals(item.price)
            },
            // "tax": {
            //   "currency_code": "AUD",
            //   "value": 1.4
            // },
            "quantity": item.total
          }
        ]
      }
      paypalPurchaseUnits.push(obj);
    })
    return paypalPurchaseUnits;
  }


  const onPaymentApproved = (pdata) => {
    const shippingCharge = totalShippingCost ? totalShippingCost : standardShippingCost;
    const totalAmount = getTotalWithShipping();
    props.history.push({ pathname: '/success', state: { transactionDetails: pdata, allCartItems: allCartItems, shippingCharge, totalAmount } });
  }


  const onPaymentFailed = async (err) => {
    console.log("paypal error ")

    const errStr = err.toString()
    console.log(errStr)

    // Do nothing if error is from closing the popup
    if (errStr.includes('Detected popup close')) return null;

    // Find the object syntax withing the errStr strings 
    const startIndex = errStr.indexOf('{');
    const endIndex = errStr.lastIndexOf('}') + 1;
    // Extract the JSON string from the input string
    const jsonString = errStr.slice(startIndex, endIndex);

    const errObject = JSON.parse(jsonString)

    let defaultDetail = "Apologies, but your payment was unsuccessful at the moment. For additional details, please contact your payment provider.";
    let defaultDetailB = "We apologize for the inconvenience. Currently, we're encountering an issue with our PayPal provider. Rest assured, we're actively working to resolve this as quickly as possible. We kindly ask for your patience and suggest checking back in a few minutes.";

    // detail = "Item price validation failed. Price for this item might have changed. Refresh your browser. Delete items in your cart and try again. If this issue continues, please inform us by clicking on the 'help' button at the top of this page and send us the form with your message."

    const title = userGroup !== 'Admin' ? `PayPal Error ${errObject.details[0].issue} occured. ` : 'Paypal Error Occured';

    const detail = userGroup !== 'Admin' ? `${JSON.stringify(errObject)}` : defaultDetailB;

    const ppErrObj = { ...paypalError, hasError: true, title: title, detail: detail }


    // Send the error as email to developer via aws lambda function
    DispatchErrors(jsonString, "paypal error");

    setPaypalError(ppErrObj)

    validationError = false;

  }


  if (!allCartItems) return (
    <Page id="checkout">
      <h3>Cart is empty</h3>
    </Page>
  )

  customLog(allCartItems);

  if (allCartItems.length === 0)
    return (
      <Page id="checkout">
        <div className="empty-cart">

          <h3>Cart is empty</h3>
          <Link className="cart-continue" to="/products">
            <button>
              <span >
                <svg className="delete-icon" width="24" height="24" aria-hidden="true" focusable="false">
                  <use xlinkHref="#icon-checkout-arrow" />
                </svg>
              </span>
              Back to store
            </button>
          </Link>
        </div>
      </Page>
    )



  const selectStyles = {
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      height: '20px'
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#9fd5ee'
        : isSelected
          ? '#9fd5ee'
          : undefined,
      borderRadius: 0
    })
  }


  const isFreeShipping = +standardShippingCost + +expressShippingCost > 0 ? false : totalShippingCost > 0 ? false : true;

  const paypalPurchaseUnits = getPaypalPurchaseUnits();
  customLog(paypalPurchaseUnits)


  initialProviderOptions.currency = data.config.currency;
  const currencySign = data.config.currencySign;


  return (
    // Paypal provider wrapper

    <PayPalScriptProvider options={initialProviderOptions} >

      <Page
        id="checkout"
      >
        <div className="checkout">
          <Link className="cart-continue" to="/">
            <span >
              <svg className="delete-icon" width="24" height="24" aria-hidden="true" focusable="false">
                <use xlinkHref="#icon-checkout-arrow" />
              </svg>
            </span>
            Continue shopping
          </Link>


          <div className="checkout-summary">

            <div className="shipping-fees">
              <div className="shipping-fees__item">
                <span className="subtotal"><h3>Shipping</h3></span>
              </div>

              <>
                <div className="shipping-fees__item-with-count" onClick={() => onShippingSelected(true)} style={{ backgroundColor: stdCheckboxVal ? "#fff6d9" : "none" }}>
                  <span id="shipping-checkbox">
                    {stdCheckboxVal &&
                      <span>
                        <svg width="24" height="24" aria-hidden="true" focusable="false">
                          <use xlinkHref="#icon-tick" />
                        </svg>
                      </span>

                    }
                  </span>
                  <div>Standard shipping
                    {/* <div className="sh-detail">Check product detail for estimate delivery date</div> */}
                  </div>
                  <span>{currencySign}{standardShippingCost}</span>
                </div>

                {expressShippingCost > 0 &&
                  <div className="shipping-fees__item-with-count" onClick={() => onShippingSelected(false)} style={{ backgroundColor: !stdCheckboxVal ? "#fff6d9" : "none" }}>
                    <span id="shipping-checkbox">
                      {!stdCheckboxVal &&
                        <span>
                          <svg width="24" height="24" aria-hidden="true" focusable="false">
                            <use xlinkHref="#icon-tick" />
                          </svg>
                        </span>

                      }
                    </span>

                    <div>Fast shipping
                      {/* <div className="sh-detail">Check product detail for estimate delivery date</div> */}
                    </div>
                    <span>{currencySign}{expressShippingCost}</span>
                  </div>
                }
              </>

              <p className="note">* Check out item's detail page for its estimated delivery date</p>
              {/* <p className="note">PayPal handles the shipping address collection and will direct the address to us once the payment is completed. We will than process your order.</p> */}

            </div>


            <div className="summary-fees">

              <div className="summary-fees__item">
                <span className="subtotal"><h3>Order summary</h3></span>

                <span className="subtotal edit">
                  <Link to="/cart" >
                    <button id="edit-cart-btn">
                      Edit
                      {/* <span>
                        <svg width="24" height="24" aria-hidden="true" focusable="false">
                          <use xlinkHref="#icon-edit" />
                        </svg>
                      </span> */}
                    </button>
                  </Link>
                </span>
              </div>

              <div className="summary-fees__item-with-count" style={{ fontWeight: '700' }}>
                <span>Item</span>
                <span>Count</span>
                <span>Amount</span>
              </div>

              <hr />

              {allCartItems &&
                allCartItems.map((item, i) => {
                  const variantType = item.selectedVariant ? " (" + item.selectedVariant.name + ")" : "";
                  return (
                    <div key={i} className="summary-fees__item-with-count">
                      <span>{item.name + variantType}</span>
                      <span>{item.total}</span>
                      <span>{currencySign}{(item.price * item.total).toFixed(2)}</span>
                    </div>
                  )
                })
              }

              <hr></hr>

              <div className="summary-fees__item">
                <span className="subtotal">Subtotal:</span>
                <span className="subtotal">{currencySign}{totalAmount.toFixed(2)}</span>
              </div>

              <div className="summary-fees__item">
                <span className="subtotal">Shipping:</span>
                <span className="subtotal">{currencySign}{totalShippingCost ? totalShippingCost : standardShippingCost}</span>
              </div>

              <div className="summary-fees__item">
                <span className="total">Total:</span>
                <span className="total">{currencySign}{getTotalWithShipping()}</span>
              </div>

            </div>

          </div>


          <div id="payments" className="checkout-summary">
            <div className="shipping-fees paypal-section">

              <div className="gateways" id="gateways" >
                <PayWithPaypal
                  data={goondrookData}
                  paypalPurchaseUnits={paypalPurchaseUnits}
                  onPaymentApproved={onPaymentApproved}
                  onPaymentFailed={onPaymentFailed}
                  currency={data.config.currency}
                // onValidationError={() => validationError = true} 
                />
              </div>

            </div>


            {/* Payment failure errors  */}

            {paypalError.hasError &&
              <div className="payment-error" >
                <div className="payment-error__item">
                  <span className="head"><h3>{paypalError.title}</h3></span>
                </div>

                <div className="payment-error__msg">
                  <p>{paypalError.detail}</p>
                </div>
              </div>
            }


            {!paypalError.hasError &&
              <div className="summary-fees paypal-summary" style={{ opacity: 0 }}> </div>
            }

          </div>


          {/* <div className="checkout-summary">
          <div className="summary-fees">

            <div className="security">
              <svg className="lock-icon" width="24" height="24" aria-hidden="true" focusable="false">
                <use xlinkHref="#icon-lock" />
              </svg>
              <span>
                <a href="#">Secured by Goondrook</a>
              </span>
            </div>
          </div>

          <CardIcons />
        </div> */}

        </div>

      </Page>
    </PayPalScriptProvider>
  )

}



export default withRouter(Checkout);
