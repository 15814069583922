import React from 'react';

const ImageWithPlaceHolder = ({ className, src, placeholderSrc, alt, ...rest }) => {
  const onErrorHandler = (e) => {
    e.target.src = placeholderSrc; // Replace the source with the placeholder image
  };

  return (
    <img
      className={className}
      src={src}
      alt={alt}
      onError={onErrorHandler}
      {...rest}
    />
  );
};

export default ImageWithPlaceHolder;
