import React, { Component } from 'react';
import { useGoondrookSiteData } from '../../utils/dataContext';
import { Link, Redirect } from "react-router-dom";
import "./CategoryGrid.scss";
import { BASE_URL, SITE_NAME } from '../../utils/constants';

const ANIMATION_SPEED = 4000;


const CategoryGrid=(props)=>{
    const {screenWidth} = useGoondrookSiteData();
    const { categories } = props.data;

        return (
            <div className="category-grid">

                <h1 className="title">Shop our popular categories </h1>

                <div className="grid-container">

                    {categories &&

                        categories.map((item, i) => {

                            let img = `${BASE_URL}/assets/images/category/${item.Image}`;

                            img = screenWidth < 600 && img.includes("-50") ? img.replace("-50", "") : img;
                            img = screenWidth < 600 && img.includes("-75") ? img.replace("-75", "") : img;
                            img = screenWidth < 600 && img.includes("-100") ? img.replace("-100", "") : img;

                            const gridCol = item.Width === "100" ? "1/5" : item.Width === "75" ? "1/4" : item.Width === "50" ? "1/3" : "inherit";
                            const gridStyle = { gridColumn: screenWidth < 600 ? 'inherit' : gridCol }

                            if (item.Show === 'no') return null;
                            return (

                                <Link key={i} to={`/category/${item.id}`} className="grid_item grid_item-1" style={gridStyle}>
                                    <img className="grid_image" src={img} alt={`${item.Name} | ${SITE_NAME}`} />
                                    <p>{item.Name}</p>
                                </Link>
                            )
                        })
                    }

                </div>

            </div>
        )
}

export default CategoryGrid;

