import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { useGoondrookSiteData } from '../../utils/dataContext';
import { BASE_URL, PLACEHOLDER_PRODUCT_IMAGE } from '../../utils/constants';
import { unslugify } from '../../utils/helpers';
import PriceRenderer from "../../components/PriceRenderer/PriceRenderer";
import ShowMoreButton from "../../components/ShowMoreButton/ShowMoreButton";
import "./ProductsList.scss";
import ImageWithPlaceHolder from '../../components/ImageWithPlaceHolder/ImageWithPlaceHolder';


const ProductsList = (props) => {
  const { data, products, category } = props;
  const { otherProductsReady, totalDisplayed } = useGoondrookSiteData();
  const { numberOfItemsInALot, total } = data.config;

  return (
    <div className="product-page">
      <div className="grid-head">
        <h1 className="title">{category ? unslugify(category) : "Trending"}</h1>
      </div>
      <div className="grid">

        {products &&
          products.map((item, i) => {

            if (i < totalDisplayed) {
              const img = `${BASE_URL}/assets/images/products/${item.images.small[0]}`;
              return (
                <div className="grid-item" key={i}  >
                  <Link className="card" to={`/products/${item.id}/${item.slug}`} key={i} >

                    <div className="thumb-loader">
                      <ImageWithPlaceHolder src={img} alt={item.seo.title} placeholderSrc={PLACEHOLDER_PRODUCT_IMAGE} />
                    </div>
                    <div className="content">
                      <p className="p-name">{item.name}</p>

                      <PriceRenderer item={item} type="list" />

                    </div>
                  </Link>
                </div>
              )
            }
          }
          )
        }
        {totalDisplayed < products.length &&
          <ShowMoreButton otherProductsReady={otherProductsReady} totalDisplayed={totalDisplayed} totalItems={total} products={products} />
        }
      </div>
    </div>
  )
}

export default withRouter(ProductsList);


