import React, { Component, useState, useEffect } from 'react';
import { BASE_URL } from "../../utils/constants";
import "./ThumbLoader.scss";

const MAX_IMAGES = 5;

class ThumbLoader extends Component {

  constructor(props: Props) {
    super(props);
    this.state = {
      imageLoaded: false
    }
  }



  handleImageLoaded = () => {
    if (!this.state.imageLoaded) {
      this.setState({ imageLoaded: true })
    }
  }



  render() {
    const { imageLoaded } = this.state;
    const { src, itemName, data } = this.props;
    return (
      <div className="thumb-loader">

        {!imageLoaded &&
          <div className="load-wraper">
            <img alt={itemName} src="./loader.gif" className="loader-img" />
          </div>
        }

        {/* preload and cache images  */}
        {/* {
          data.map((item, i) => {
            const img = `${BASE_URL}/assets/images/products/${item.images.small[i]}`;
            return (
              <img src={img} style={{ display: 'none' }} key={i} onLoad={this.handleImageLoaded} alt={itemName} />
            )
          })
        } */}

        <img src={src} style={{ display: !imageLoaded ? 'none' : 'block' }} onLoad={this.handleImageLoaded} alt={itemName} />

      </div>

    )
  }
}

export default ThumbLoader;
