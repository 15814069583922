import React from "react";
import "./Spinner.scss";

const SpinnerRegular = (props) => {

    const color = props.color ? 'ring-'+props.color : '';
    const ringStyle = props.size ? {width: props.size,height: props.size}: {};

    return (
        <div className={`lds-ring lds-ring-reg ${color}`} style={ringStyle}>
            <div style={ringStyle}></div>
            <div style={ringStyle}></div>
            <div style={ringStyle}></div>
            <div style={ringStyle}></div>
        </div>
    )
};

export default SpinnerRegular;
