import React, { createContext, useContext, useState, useEffect } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Link, useParams, useHistory } from "react-router-dom";
import awsExports from './aws-exports';
import { customLog } from '../../utils/helpers';

// Initialize Cognito user pool with aws pool configuration
const userPool = new CognitoUserPool({
    UserPoolId: awsExports.aws_user_pools_id,
    ClientId: awsExports.aws_user_pools_web_client_id
});

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userGroup, setUserGroup] = useState('');
    const [isAuthLoading, setIsAuthLoading] = useState(true);

    const history = useHistory();

    useEffect(() => {
        // Bypass cognito authentication when in localhost
        if(process.env.NODE_ENV === 'development'){
            setIsLoggedIn(true);
            setIsAuthLoading(false)
            setUserEmail('richardlama@gmail.com');
            setUserGroup('Admin')
        }else{
            checkUser(); // Check if the user is already authenticated when the component mounts
        }
    }, []);


    const checkUser = () => {
        const cognitoUser = userPool.getCurrentUser(); // Get the current authenticated user
        if (cognitoUser !== null) {
            cognitoUser.getSession((error, session) => {
                if (error) {
                    console.error('Error getting session:', error);
                    setIsLoggedIn(false);
                    setIsAuthLoading(false)
                } else {
                    getUserData(cognitoUser, session); // Fetch user data after getting the session
                }
            });
        } else {
            setIsLoggedIn(false);
            setIsAuthLoading(false)
        }
    };

    const getUserData = (cognitoUser, session, isFromLogin) => {
        cognitoUser.getUserAttributes((error, attributes) => {
            if (error) {
                console.error('Error getting user attributes:', error);
            } else {
                attributes.forEach((attribute) => {

                    if (attribute.getName() === 'email') {
                        setUserEmail(attribute.getValue());
                    }

                    const idTokenPayload = session.getIdToken().decodePayload();
                    // Check if the token contains 'cognito:groups' claim
                    if (idTokenPayload['cognito:groups']) {
                        idTokenPayload['cognito:groups'].forEach(group => {
                            customLog("group: ",group)
                            setUserGroup(group);
                        });
                    } else {
                        customLog('User is not in any groups.');
                        // history.push("/checkout")
                    }

                });

                setIsLoggedIn(true);
                setIsAuthLoading(false)
            }
        });
    };

    const login = (email, password) => {
        const authenticationData = {
            Username: email,
            Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        const userData = {
            Username: email,
            Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session) => {
                    // customLog('session', session);
                    // customLog('Id token:', session.getIdToken().payload.email);
                    // customLog('Sign in successful. Access token:', session.getAccessToken().getJwtToken());
                    // setIsLoggedIn(true);
                    // getUserData(cognitoUser, session, true);
                    checkUser();
                    resolve();
                },
                onFailure: (error) => {
                    console.error('Error signing in:', error);
                    reject(error);
                },
            });
        });
    };


    const signup = (email, password) => {
        // const attributeList = [
        //     new CognitoUserAttribute({ Email: 'email', Value: email })
        //   ];
        return new Promise((resolve, reject) => {
            userPool.signUp(email, password, [], null, (error, result) => {
                if (error) {
                    console.error('Error signing up:', error);
                    reject(error);
                } else {
                    setIsLoggedIn(true); // Automatically login the user after successful signup
                    resolve(result);
                }
            });
        });
    };


    const confirmCode = (email, code) => {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmRegistration(code, true, (error, result) => {
                if (error) {
                    console.error('Error confirming code:', error);
                    reject(error);
                } else {
                    setIsLoggedIn(true); // Automatically login the user after successful confirmation
                    resolve(result);
                }
            });
        });
    };


    const logout = () => {
        const cognitoUser = userPool.getCurrentUser(); // Get the current authenticated user
        if (cognitoUser !== null) {
            cognitoUser.signOut(); // Sign out the user
            setIsLoggedIn(false);
        }
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, userEmail, userGroup, login, isAuthLoading, logout, signup, confirmCode }}>
            {children}
        </AuthContext.Provider>
    );
};
