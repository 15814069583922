import React, { useState, useEffect, Suspense } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import LoaderImg from "./loader.gif"
import "./ImageLoader.scss";
import { BASE_URL } from "../../utils/constants";
import { SuspenseImg } from './SuspenseImg';
import Loader from "./loaderComp";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'


const MAX_IMAGES = 5;



const VariantImage = ({ id, name, variantImgObj }) => {
  const [isError, setIsError] = useState(false);


  useEffect(() => {

    if (variantImgObj.src !== "" && variantImgObj.src !== "init") {
      setIsError(false)
    }
    return () => {
      // do something while leaving this component.
    };

  }, [variantImgObj]);


  const handleImageError = () => {
    setIsError(true);
  };


  return (
    <div>
      {isError && variantImgObj.src !== 'init' ? (
        <span>none</span>
      ) : (

        <img id={id} style={{ display: variantImgObj.display }} alt={name} src={variantImgObj.src} onError={handleImageError} />

        // <img className="holder" alt={name} src={src} id={`vimg-${index}`} onMouseOver={(e) => mouseOver(e, index)} onMouseOut={(e) => mouseOut(e, index)} onClick={(e) => onClick(e.currentTarget, src, index)} onError={handleImageError} />
      )}
    </div>
  );
};





const ImageLoader = ({ bigImages, smallImages, itemName, showVideoPlayBtn, randy, variantImgObj }) => {

  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);


  const handleImageLoaded = () => {
    if (!imageLoaded) {
      setImageLoaded(true)
    }
  }


  const imageClicked = (e) => {
    setSelectedImgIndex(+e.target.id.replace("img-", ""))
    // const varintImgDiv = document.getElementById("variant-img");
    // varintImgDiv.setAttribute("src", "")
    // varintImgDiv.style.display = "none";
    //})
  }

  const playProductVideo = () => {
    let video = document.getElementById("product-video");
    let btn = document.getElementById("video-close-btn");
    let videoContainer = document.getElementById("video-container");
    let thumbsDivHeight = document.getElementById("thumbs").getBoundingClientRect().height;
    btn.style.display = 'block';
    video.style.display = "inline-block";

    if (window.innerWidth < 600) {
      setTimeout(() => {
        let mobileHeadHeight = document.getElementById("mobile-head").getBoundingClientRect().height;
        video.style.height = videoContainer.getBoundingClientRect().height + mobileHeadHeight + 50 + "px";
      }, 1000)
    }
    //videoContainer.style.height = document.body.scrollHeight + "px";

    video.currentTime = 0;
    video.onended = (e) => {
      video.pause()
      btn.style.display = 'none';
      video.style.display = "none";
    }
    video.play();
  }

  let imgContClass = randy ? "img-conts" : "g-img-conts";
  imgContClass = imageLoaded ? imgContClass + " img-conts-option-loaded" : imgContClass + " img-conts-option-unloaded"


  return (

    <div className="image-loader" >

      {!imageLoaded &&
        <div className="load-wraper">
          <img src={LoaderImg} className="loader-img" />
        </div>
      }


      <div className={imgContClass}>

        {smallImages.length > 0 &&

          smallImages.map((item, i) => {

            if (item) {
              const img = `${BASE_URL}/assets/images/products/${item}`;

              if (i < MAX_IMAGES) {
                return (
                  <div key={i}>

                    <img style={{ display: "none" }} alt={itemName} src={`${BASE_URL}/assets/images/products/${bigImages[i]}`} onLoad={handleImageLoaded} />

                    {imageLoaded &&
                      <div className={randy ? "hero" : "g-hero"} style={{ display: selectedImgIndex === i ? 'block' : 'none' }}  >
                        <InnerImageZoom
                          className="ddd"
                          zoomPreload={true}
                          hideHint={true}
                          zoomType="hover"
                          src={`${BASE_URL}/assets/images/products/${bigImages[i]}`}
                          zoomSrc={`${BASE_URL}/assets/images/products/${bigImages[i]}`}
                          imgAttributes={{
                            title: { itemName },
                            alt: { itemName }
                          }}
                        />
                      </div>
                    }

                  </div>

                )
              }
            }
          })
        }

        {variantImgObj.src !== 'init' &&
          <VariantImage id="variant-img" variantImgObj={variantImgObj} name={itemName} />
        }

        {selectedImgIndex === 0 && showVideoPlayBtn &&
          <div className="video-play-btn" onClick={playProductVideo}>
            <svg className="icon-video-play" width="24" height="24" aria-hidden="true" focusable="false">
              <use xlinkHref="#icon-play-btn-circle" />
            </svg>
          </div>
        }

      </div>



      <div className="thumbs" id="thumbs">

        {smallImages.length > 0 &&

          smallImages.map((item, i) => {

            if (item) {
              const img = `${BASE_URL}/assets/images/products/${item}`;

              if (i < MAX_IMAGES) {
                return (
                  <div className="img-cont" key={i} >
                    <img src={img} key={i} id={`img-${i}`} onMouseOver={imageClicked} alt={itemName} />
                  </div>
                )
              }
            }
          })
        }
      </div>

    </div>

  )

}

export default ImageLoader;
