//sandbox :richardlama-facilitator@hotmail.com
const CLIENT_ID_SANDBOX = "AXfQB01O2M3UzfN-bboEiw1Fp88hgvlOuIFkyvePr0GiTyyHzs9BKXwAvRK4ZfvNbASWimWpBJ4Xdlk4";
const CLIENT_ID_PROD = "AeiMPgM6aU676_ySxWHF5QiyITAEiBqdaw1GD4UeqLHGgSGCDlmB6Agt3VHyHokHTq_hsvj4eTcY0tap";

// This values are the props in the UI
export const AMOUNT = "2";
export const STYLE = { "layout": "vertical", width: '120px' };


export const initialProviderOptions = {
    "client-id": window.location.hostname !== 'www.goondrook.com' ? CLIENT_ID_SANDBOX : CLIENT_ID_PROD,
    currency: 'AUD',
    //intent: "capture",
    components: ["buttons","googlepay"]
    // "data-client-token": "abc123xyz==",
};


export const NegativeTestingData = [
    
        {
            reference_id: "PUHF",
            description: "Some description",

            custom_id: "Something7364",
            soft_descriptor: "Great description 1",
            amount: {
                currency_code: "AUD",
                value: "107.55",
                breakdown: {
                    item_total: {
                        currency_code: "AUD",
                        value: "107.55"
                    }
                }
            },
            items: [
                {
                    name: "Item 1",
                    description: "The best item ever",
                    sku: "xyz-2654",
                    unit_amount: {
                        currency_code: "AUD",
                        value: "107.55"
                    },
                    quantity: "1"
                }
            ],

        }
    
];


export default {
    AMOUNT,
    STYLE,
    initialProviderOptions,
    NegativeTestingData
}
