import React from "react";
import { Link } from "react-router-dom";
import CardIcons from "../CardIcons/CardIcons";
import {useGoondrookSiteData} from "../../utils/dataContext"
import "./Footer.scss";

// import {
//   HeaderStyled,
//   NavBar,
//   NavList,
//   NavItem,
//   NavLinkS,
// } from "./HeaderStyles";

const Footer = () => {
  const { goondrookData, isInitialDataLoaded } = useGoondrookSiteData();
  const year = new Date().getUTCFullYear();

  if(goondrookData.config.maintenanceMode) return null;

  return (

    <section>
    
      <div className="footer" id="footer">

        <div className="fc-a">
          <ul className="info">
            <li><Link to="/aboutus" className="nav_a">About us</Link></li>
            {/* <li><Link to="/rticles.html" className="nav_a">Articles</Link></li>
                    <li><Link to="/search.html" className="nav_a">Search</Link></li>
                    <li><Link to="/newsletters.html" className="nav_a">Newsletter</Link></li>  */}
            <li><Link to="/privacy" className="nav_a">Privacy Notice</Link></li>
            <li><Link to="/terms" className="nav_a">Terms &amp; conditions</Link></li>
          </ul>
        </div>

        <div className="fc-b">
          <div className="copy-right">
            © Copyright {year-1}-{year} Gooondrook.com. All Rights Reserved.
          </div>

          <CardIcons />
        </div>
      </div>

    </section >
  )
};

export default Footer;
