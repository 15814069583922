import React, { Component, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Select, { components } from "react-select";
import { Link, useParams } from "react-router-dom";
import Page from "../../../utils/Page";
import {HOST_NAME, PENDING_ORDERS_API, LOCAL_SERVER } from "../../../utils/constants";
import SpinnerRegular from "../../../components/Spinner/SpinnerRegular";
import { slugify, iDGenerator, isStaging, unslugify, formatTZDate, getElapsedTime, customLog } from "../../../utils/helpers";
import "./ProcessOrders.scss";
import RenderHTML from "../../../utils/RenderHtml";

const InitialValidData = { valid: false, msg: '' };


const dummyData = [
  {
    "dispatched": false,
    "dis_date_time": null,
    "delivered": false,
    "del_date_time": null,
    "date_time": "2023-04-07T05:07:09.000Z",
    "order_id": "73S83872JL590945R",
    "status": "COMPLETED",
    "item_ref_id": "POHAWICAVACL49",
    "item": "portable-handheld-wireless-car-vacuum-cleaner",
    "quantity": 1,
    "amount": 49.5,
    "shipping": 0,
    "total": 49.5,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  },
  {
    "dispatched": true,
    "dis_date_time": "2023-04-07T00:51:38.000Z",
    "delivered": false,
    "del_date_time": null,
    "date_time": "2023-04-07T01:31:19.000Z",
    "order_id": "03220031A6320715X",
    "status": "COMPLETED",
    "item_ref_id": "POHASEMAWISTKI43",
    "item": "portable-handheld-sewing-machine-with-stitch-kits",
    "quantity": 1,
    "amount": 43.15,
    "shipping": 0,
    "total": 43.15,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  },
  {
    "dispatched": true,
    "dis_date_time": null,
    "delivered": true,
    "del_date_time": null,
    "date_time": "2023-04-07T01:03:52.000Z",
    "order_id": "3P633745MT155350W",
    "status": "APPROVED",
    "item_ref_id": "MSMI20MALEWICPMOLEPOMALEMAFOSMPH36",
    "item": "814814668891889 (White)",
    "quantity": 1,
    "amount": 36.55,
    "shipping": 20,
    "total": 56.55,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  },
  {
    "dispatched": true,
    "dis_date_time": "2023-01-17T09:51:38.000Z",
    "delivered": false,
    "del_date_time": null,
    "date_time": "2023-01-17T06:51:38.000Z",
    "order_id": "8CX10461NX5027819",
    "status": "COMPLETED",
    "item_ref_id": "POHAWICAVACL49",
    "item": "portable-handheld-wireless-car-vacuum-cleaner",
    "quantity": 2,
    "amount": 49.5,
    "shipping": 0,
    "total": 99,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  },
  {
    "dispatched": true,
    "dis_date_time": "2023-01-17T09:51:38.000Z",
    "delivered": false,
    "del_date_time": null,
    "date_time": "2023-01-17T06:51:38.000Z",
    "order_id": "8CX10461NX5027819",
    "status": "COMPLETED",
    "item_ref_id": "10CO30BRSE23",
    "item": "100-cotton-300tc-breadspread-set",
    "quantity": 1,
    "amount": 235.5,
    "shipping": 0,
    "total": 235.5,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  },
  {
    "dispatched": false,
    "dis_date_time": null,
    "delivered": false,
    "del_date_time": null,
    "date_time": "2023-01-16T18:48:18.000Z",
    "order_id": "07F987472B6719400",
    "status": "COMPLETED",
    "item_ref_id": "POAIPUFOCA51",
    "item": "portable-air-purifier-for-cars",
    "quantity": 1,
    "amount": 51.38,
    "shipping": 0,
    "total": 51.38,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  },
  {
    "dispatched": true,
    "dis_date_time": null,
    "delivered": false,
    "del_date_time": null,
    "date_time": "2022-11-04T06:44:50.000Z",
    "order_id": "9RH99551KA235291R",
    "status": "APPROVED",
    "item_ref_id": "10MUCOPOANWAKI61",
    "item": "100w-multifunctional-cordless-polisher-and-waxer-kit",
    "quantity": 2,
    "amount": 61.07,
    "shipping": 10,
    "total": 132.14,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  },
  {
    "dispatched": true,
    "dis_date_time": null,
    "delivered": false,
    "del_date_time": null,
    "date_time": "2022-11-04T06:34:57.000Z",
    "order_id": "44W86696M02821035",
    "status": "APPROVED",
    "item_ref_id": "NESKPISTST10",
    "item": "necklace-skull-pirate-stainless-steel",
    "quantity": 2,
    "amount": 10.48,
    "shipping": 0,
    "total": 20.96,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  },
  {
    "dispatched": true,
    "dis_date_time": "2022-10-31T13:00:00.000Z",
    "delivered": true,
    "del_date_time": null,
    "date_time": "2022-11-02T12:00:16.000Z",
    "order_id": "5KP62820D4527341J",
    "status": "COMPLETED",
    "item_ref_id": "POHAWICAVACL53",
    "item": "portable-handheld-wireless-car-vacuum-cleaner",
    "quantity": 1,
    "amount": 53.37,
    "shipping": 0,
    "total": 53.37,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  },
  {
    "dispatched": true,
    "dis_date_time": null,
    "delivered": false,
    "del_date_time": null,
    "date_time": "2022-10-30T08:43:04.000Z",
    "order_id": "0K843371KA909612H",
    "status": null,
    "item_ref_id": "POHAWICAVACL53",
    "item": "portable-handheld-wireless-car-vacuum-cleaner",
    "quantity": 1,
    "amount": 53.37,
    "shipping": 0,
    "total": 53.37,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  },
  {
    "dispatched": false,
    "dis_date_time": null,
    "delivered": false,
    "del_date_time": null,
    "date_time": "2022-10-30T08:43:04.000Z",
    "order_id": "0K843371KA909612H",
    "status": null,
    "item_ref_id": "JICHCEVA17",
    "item": "jingdezhen-chinese-ceramic-vase",
    "quantity": 1,
    "amount": 176.75,
    "shipping": 0,
    "total": 176.75,
    "payer_given_name": "richardTest",
    "payer_surname": "lamaTest",
    "payer_email": "richardlama_test01@hotmail.com",
    "shipping_line_1": "1 Cheeseman Ave Brighton East",
    "shipping_area_1": "Victoria",
    "shipping_area_2": "Melbourne",
    "post_code": 3001,
    "shipping_country_code": "AU"
  }
]

const orderHeaders = { date_time: "Date", order_id: "Order Id", item: "Item", status: "Paypal Status", delivered: "Delivered", dispatched: "Dispatched" };


class ProcessOrdersForms extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      isValid: InitialValidData,
      submit: { status: 'submit', msg: 'Submit', type: '' },
      confirmItemIdText: '',
      selectedOrderIndex: -1,
      filterBy: 'all',
      searchStr: ""
    }
    this.captcha = "";
  }


  async componentDidMount() {
    window.scroll(0, 0);

    const orders = await this.getPendingOrdersFromGS();

    const ordersWithSourceUrls = await this.getItemSourceUrls(orders);

    this.setState({ orders: ordersWithSourceUrls })
  }

  /**
   * 
   * @param {*} orders 
   * @returns orders with item source url
   */
  async getItemSourceUrls(orders) {
    const allProductsUrl = 'https://' + HOST_NAME + '/data/rawproducts.json';
    const response = await fetch(allProductsUrl);

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      customLog('something went wrong publishing sites.  ' + message);
    }
    const res = await response.json();

    let ordersWithSourceUrl = orders;
    orders.forEach((order, k) => {
      res.data.forEach((d) => {
        if (order.item === d.slug) {

          res.sourceUrls.forEach((src) => {
            if (src.id === d.id) {

              ordersWithSourceUrl[k].url = src.url;
            }
          })

        }
      })
    })

    return ordersWithSourceUrl;
  }


  getPendingOrdersFromGS = async () => {

    let payload = { request_type: 'all_orders', env: isStaging || process.env.NODE_ENV === 'development' ? 'stage' : 'live' };
    const response = await fetch(PENDING_ORDERS_API, {
      method: 'post',
      body: JSON.stringify(payload),
      redirect: "follow",
      headers: {
        "Content-Type": "text/plain"
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      customLog('something went wrong publishing sites.  ' + message);
    }
    const res = await response.json();
    const json = JSON.parse(res.result);

    // filter items that has order id
    const orders = json.filter(d => d.order_id)
    return orders;

  }


  setDispatchDelivered = async (e, index, type) => {
    e.preventDefault();
    const { orders, submit } = this.state;

    if (submit.status === 'submitting') return;

    if (type === 'dispatched') orders[index].dispatched = true;
    if (type === 'delivered') orders[index].delivered = true;

    let ordersToUpdate = [orders[index]];  // array required here

    this.setState({ submit: { status: 'submitting', msg: 'Uploading form data ...', type } })

    // Update Data at server
    let payload = { request_type: 'update_orders', env: isStaging || process.env.NODE_ENV === 'development' ? 'stage' : 'live', data: ordersToUpdate };

    const response = await fetch(PENDING_ORDERS_API, {
      method: 'post',
      body: JSON.stringify(payload),
      redirect: "follow",
      headers: {
        "Content-Type": "text/plain"
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      //throw new Error(message);
      return 'not found';
    }
    const res = await response.json();

    this.setState({ submit: { status: 'submit', msg: 'Order updated', type: '' } })
    customLog('... Done processing ...');
    customLog(res)
  }




  onSearch = (e) => {
    const searchStr = e.target.value;
    this.setState({ searchStr })

  }




  getFilteredOrders = (orders, filterBy, searchStr) => {

    let filteredOrders = undefined;
    let newFilteredOrders = [];

    filteredOrders = orders.filter(d => !d[filterBy]);
    if (filterBy === 'all') filteredOrders = orders;

    if (searchStr.length > 2) {
      filteredOrders = filteredOrders.filter((d) => searchStr !== "" && (d.order_id.toLowerCase().includes(searchStr.toLowerCase()) || d.item.toLowerCase().includes(searchStr.toLowerCase())))
    }

    let lastOrderId = '';
    filteredOrders.forEach((dt, k) => {
      const orderID = dt.order_id;
      let subOrders = [];

      if (lastOrderId !== dt.order_id) {
        filteredOrders.forEach((st, s) => {
          if (orderID === st.order_id) {
            subOrders.push(st)
            lastOrderId = dt.order_id;
          }
        })

        newFilteredOrders.push(subOrders)
      }

    })
    return newFilteredOrders;
  }




  render() {
    const { orders, submit, confirmItemIdText, selectedOrderIndex, filterBy, searchStr } = this.state;

    let newFilteredOrders = undefined;

    if (orders) {
      newFilteredOrders = this.getFilteredOrders(orders, filterBy, searchStr);
    }

    return (
      <div className="process-orders">
        <div className="left-side">

          <h1>Process orders</h1>
          <div className="filter-block">
            <p> Click the table row to set dispatch or delivered status.</p>

            <div className="filter">
              <label>Filter by: </label>
              <select onChange={(e) => this.setState({ filterBy: e.target.value })} value={filterBy || ''}>
                <option value="all">All</option>
                <option value="dispatched">Not Dispatched </option>
                <option value="delivered">Not Delivered yet</option>
              </select>
            </div>

            <div className="search">
              <label>Search:</label>
              <input type="text" onChange={this.onSearch} />
            </div>
          </div>

          {!newFilteredOrders &&
            <div className="loader" >
              <SpinnerRegular />
            </div>
          }

          {newFilteredOrders &&
            <>
              {newFilteredOrders.map((order, i) => {

                const formattedDateTime = formatTZDate(order[0].date_time);
                const elapsedTime = getElapsedTime(formattedDateTime)

                return (
                  <div key={`g${i}`} className="form-group gr-row"  >

                    {i === 0 &&
                      <div className="form-item order-header">
                        <p>Date</p>
                        <p>Order Id</p>
                        <p>Paypal Status</p>

                        <p style={{ minWidth: '5%' }}>Dispatched</p>
                        <p style={{ minWidth: '5%' }}>Delivered</p>

                      </div>
                    }

                    <div className="form-item" onClick={() => this.setState({ selectedOrderIndex: selectedOrderIndex === i ? -1 : i })}>
                      <p><span className="time-elapsed">{elapsedTime}</span>{` `}{formattedDateTime}</p>
                      <p>{order[0].order_id}</p>
                      <p>{order[0].status}</p>
                      <div className="form-item" style={{ padding: 0 }}>
                        <input type="checkbox" style={{ margin: 0 }} checked={order[0].dispatched || false} onChange={(e) => customLog("d")} />
                      </div>

                      <div className="form-item" style={{ padding: 0 }}>
                        <input type="checkbox" style={{ margin: 0 }} checked={order[0].delivered || false} onChange={(e) => customLog("d")} />
                      </div>

                    </div>


                    {i == selectedOrderIndex &&

                      <div className="detail">

                        <div className="order-detail">

                          <div className="order-detail-table">

                            <div className="order-detail-table-loop" style={{ backgroundColor: '#e1e1e1' }}>
                              <div className="o-name">Name</div>
                              <div className="o-qty">Qty</div>
                              <div className="o-price">Price</div>
                            </div>

                            {
                              order.map((subOrder, k) => {
                                return (

                                  <div key={`d${k}`} className="order-detail-table-loop" >

                                    <div className="o-name">
                                      <a href={subOrder.url} target="_blank"><span>{unslugify(subOrder.item)}</span></a>
                                    </div>
                                    <div className="o-qty">
                                      <p><span>{subOrder.quantity || ""}</span></p>
                                    </div>

                                    <div className="o-price">
                                      <p><span>{subOrder.amount || ""}</span></p>
                                    </div>

                                  </div>
                                )
                              })
                            }


                            <div className="order-detail-table-loop" >
                              <div className="o-name">
                                <p><span>Shipping</span></p>
                              </div>
                              <div className="o-qty">
                                <p><span>{``}</span></p>
                              </div>

                              <div className="o-price">
                                <p><span>${order[0].shipping}</span></p>
                              </div>
                            </div>

                          </div>

                          <div className="order-buyer">
                            <p>Buyer: <span>{order[0].payer_given_name}{" "}{order[0].payer_surname}</span></p>

                            <p>Email: <span>{order[0].payer_email}</span></p>

                            <div className="address">
                              <p>Address:</p>

                              <p>{`${order[0].shipping_line_1}`}</p>
                              <p>{`${order[0].shipping_area_1} ${order[0].shipping_area_2}`}</p>
                              <p>{`${order[0].post_code}, ${order[0].shipping_country_code}`}</p>

                            </div>
                          </div>


                          <div className="action-btns">
                            {!order[0].dispatched &&
                              <button onClick={(e) => this.setDispatchDelivered(e, i, 'dispatched')}>Dispatch</button>
                            }
                            {!order[0].delivered &&
                              <button onClick={(e) => this.setDispatchDelivered(e, i, 'delivered')}>Delivered</button>
                            }


                            <div>
                              <div className="form-item">
                                {submit.status == 'submitting' &&
                                  <div> <><span>{submit.msg}</span> <SpinnerRegular /></></div>
                                }
                                {submit.status === 'submit' && submit.msg !== "Submit" &&
                                  <div>Order updated succesfully</div>
                                }
                              </div>
                            </div>

                          </div>


                        </div>


                      </div>

                    }

                  </div>
                )

              })}
            </>
          }
        </div>

      </div>
    )
  }
}


const ProcessOrders = (props) => {
  return (
    // <Page
    //   id="processOrders"
    // >
      <ProcessOrdersForms />
    // </Page>
  );
};

export default ProcessOrders;



